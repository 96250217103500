import React from 'react';
import {connect} from 'react-redux';
import {toggleErrorWindow} from '../../Actions/VisualDataAction';
import {withRouter} from "react-router-dom";
class Error extends React.Component{
    render(){
        const {Dictionary} = this.props;
        const {toggleErrorWindow} = this.props;
        return(
            <div className="ErrorShow">

                <div className="toolbar card-padding box-shadow bg-white text-center">
                    <div className="section-vertical-padding">
                        <div className="image text-center">
                            <span className="icon-xlarge itours-icon error-icon"/>
                        </div>
                        <div className="margin-top margin-bottom">{this.props.children}</div>
                        <button type="button" className="button blue-btn" onClick={()=>{toggleErrorWindow({visibility:false,content:""});this.props.history.push("/")}}>{Dictionary.close} </button>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
const router=withRouter(Error)
const mapAction=(dispatch)=>({toggleErrorWindow:(params)=>{dispatch(toggleErrorWindow(params))}});
export default connect(mapStateToProps,mapAction)(router)