import React from 'react';
import validator from 'validator';
import {Map} from "../../Enum/Common";

export default class Form extends React.Component{
    state={
        ValidatorList:{},
        ValidatorSummary:[],
        Loading:false,
        DataInput:{},
    }
    componentDidMount(formDOM){
        let FormData={};
        if(formDOM!==null){
            for(let element of formDOM){
                if(element.name.length>0){
                    FormData[element.name]="";
                }
            }
        }

        this.setState({
            ValidatorList:{...FormData}
        })
    }
    validateForm(event,callback){
        event.preventDefault();
        let DataFeild={};
        let ValidatorArr={};
        let FormStatus=true;
        const {Dictionary} = this.props;
        this.setState({ValidatorSummary:[]});
        for(let item of event.target){
            if(item.name.length>0){
                    if(item.getAttribute("data-validator")==="true"){
                        if(validator.isEmpty(item.value)){
                            ValidatorArr[item.name]=Dictionary.thisFieldIsRequired;
                            FormStatus=false;
                        }
                        else if(item.type==="checkbox"){
                            if(!item.checked){
                                ValidatorArr[item.name]=Dictionary.youShouldAcceptThis;
                                FormStatus=false;
                            }
                        }
                        else {
                            if (item.type === "password") {
                                if (!validator.isLength(item.value, {min: 6, max: undefined})) {
                                    ValidatorArr[item.name] = Dictionary.thePasswordMustBeAtLeast6Characters;
                                    FormStatus = false;
                                } else {
                                    Map(DataFeild, item.name, item.value);
                                }
                            }//password
                            else if(item.type==="tel"){
                                if (!(validator.isDecimal(item.value) && validator.isLength(item.value,{min:6,max:14}))) {
                                    ValidatorArr[item.name] = Dictionary.enteredPhoneNumberIsNotValid;
                                    FormStatus = false;
                                } else {
                                    Map(DataFeild, item.name, item.value);
                                }
                            }
                            else {
                                if (item.value.length === 0) {
                                    ValidatorArr[item.name] = Dictionary.fillTheText;
                                    FormStatus = false;
                                } else {
                                    Map(DataFeild, item.name, item.value);
                                }
                            }
                        }
                    }else{
                        if (item.type === "email") {
                            if (item.value&&!validator.isEmail(item.value)) {
                                ValidatorArr[item.name] = Dictionary.enteredEmailAddressIsNotValid;
                                
                                FormStatus = false;
                            } else {
                                Map(DataFeild, item.name, item.value);
                            }
                        }//email
                        else if(item.type==="radio"){
                            if(item.checked){
                                Map(DataFeild, item.name, item.value);

                            }
                        }else{
                            Map(DataFeild, item.name, item.value);
                        }
                    }
                }



        }
        if(FormStatus){
            this.setState({Loading:FormStatus,DataInput:{...DataFeild},ValidatorList:ValidatorArr},()=>{
                callback();
             })
        }else{
            this.setState({Loading:FormStatus,ValidatorList:ValidatorArr})
        }


    }

}
