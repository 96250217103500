import Currency from '../Enum/Currency';
export const Header={
    "Content-Type":"application/json",
    "Accept-Language":"en-US",
    "bs.currency": Currency.getCurrency(),
    //"bs.terminal": "93F344D3-2AAD-4C13-9A32-E8AF90AA0206",
    "bs.terminal": "ACE01BF4-AAEE-45D6-ABE7-F3FF519052DB",
    "Abp.TenantId":"6"
};
export const ServerAddress={
    Type:process.env.REACT_APP_SERVER_TYPE,
    User:process.env.REACT_APP_SERVER_USER,
    Hotel:process.env.REACT_APP_SERVER_HOTEL,
    Payment:process.env.REACT_APP_SERVER_PAYMENT,
    localServer:process.env.REACT_APP_SERVER_LOCALSERVER,
    localIP:process.env.REACT_APP_SERVER_LOCALIP,
    Flight:process.env.REACT_APP_SERVER_FLIGHT
};
export const User={
    Register:'/api/services/app/Account/Register',//for register new user
    Login:'/api/TokenAuth/Login',//for login user
    AuthUser:'/api/services/app/Account/GetUser',
    VarficationUser:'/api/services/app/Account/GetUser',
    Forgot:'/api/services/app/Account/ForgotPassword',
    Confrim:'/api/services/app/Account/ConfirmEmail',
    ResetPassword:'/api/services/app/Account/ResetPasswordByCode',
    Update:'/api/services/app/Account/Update',
    ChangePassword:'/api/services/app/Account/ChangePassword'
};
export const Flight={
    GetLocation: '/api/services/app/BookingFlight/GetAirport',
    GetAirline:'/api/services/app/BookingFlight/GetAirline',
    GetFlights:'/api/services/app/BookingFlight/LowFareSearch',
    ValidateFlight:'/api/services/app/BookingFlight/Validate',
    GetPNR:'/api/services/app/BookingFlight/ReservePNR',
    GetRules:'/api/services/app/BookingFlight/GetFlightRules',
    GetReserveByID:'/api/services/app/BookingFlight/GetFlightReserveById',
    GetDirectTicketByID:'/api/services/app/BookingFlight/GetDirectTicketById'
};
export const Hotel={
    GetLocation:'/api/services/app/BookingHotel/GetLocation',
    GetAccomadtion:'/api/services/app/BookingHotel/GetSearch',
    GetAccomadtionDetails:'/api/services/app/BookingHotel/GetHotelById',
    GetPreReserve:'/api/services/app/BookingHotel/PreReserve',
    GetPreReserveID:'/api/services/app/BookingHotel/GetPreReserveByKey',
    GetReserveByID:'/api/services/app/BookingHotel/GetReserveById',
    PostReserver:'/api/services/app/BookingHotel/Reserve',
    GetVoucherDetails:'/api/services/app/BookingHotel/GetReserveById',
    BookHotel:'/api/services/app/BookingHotel/Book',
    GetRoom:'/api/services/app/BookingHotel/GetRoom',
    GETType:'/api/services/app/Reserve/GetReserveById'
};
export const Reserve={
    hotel:'/api/services/app/Reserve/GetReserves'
};
export const Payment={
    GetBanksInformation:'/api/v1/Reserves/Gateway/GetGateways',
    MakeToken:'/api/v1/Reserves/Gateway/MakeToken',
    GatewayReport:'/api/v1/Payment/GatewayReport'

};

export const ServerStatus={
    Success:1,Error:2,SummaryError:3
};
