import React from 'react';
import Rate from "../Common/Rate";
import Price from "../Common/Price";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
/* import {gregorian_to_jalali} from '../../Enum/Common'; */
const HotelInformation=(props)=>{
    const {model}=props;
    const {Dictionary} = props;
    return(
        <>
        <div className="full-width">
            <h3 className="hotel-title en-font">{model.name}</h3>
            {/* <div><b className="hotel-city">{model.country.name} - {model.city.name}</b></div> */}
            <div className="hotel-address en-font">
                <span className="itours-icon location-dark margin-right-light icon-small"/>

                {model.address}                                        
            </div>
            <div className="hotelRange">
                <Rate number={model.rating}/>
            </div>            
        </div>
        <div className="text-right">   
        {(model.segments && model.segments.length>0)?
            <>
                <div>
                    <b dir="ltr">
                        <Price currency={props.currencyCode}>{props.salePrice}</Price>
                    </b> 
                    <div className="font-10">{Dictionary.startingPrice}</div>  
                </div>         
                <a href="#roomSelect" type="button" className="button red-btn nowrap">{Dictionary.selectRoom}</a>
            </>
        :
            null
        }         
        </div>
        </>
    )
};
HotelInformation.propTypes={
    model:PropTypes.any.isRequired,
    salePrice:PropTypes.number.isRequired,
    currencyCode:PropTypes.any.isRequired
};

const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(HotelInformation);