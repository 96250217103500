import {Reducers} from '../Enum/Models';
import axios from "axios";
import '../Dictionary'
export const toggleAccountWindow=()=>({type:'ToggleAccountWindow'});
export const toggleErrorWindow=(params)=>({type:'ToggleErrorWindow',params});
export const SwitchAccountWindow=(mode)=>({type:'SwitchAccountWindow',mode});
export const toggleVarification=(mode)=>({type:'VERIFICATION',mode});

//new  version
export const showError=(config)=>({type:Reducers.HANDLE_ERROR,visibility:config.visibility,content:config.content});
export const editMessage=(data)=>({type:Reducers.MESSAGE,responded:data});
export const setHomeCSS=(data)=>({type:'SETHOMEPAGECSS',status:data});
export const setLanguage=(data,lan)=>({type:'SETLAN',data:{Dictionary:data,Lan:lan}});
export const loadLanguage=(lan)=>{
  return(dispatch)=>{
      axios({
          type:'get',
          url:'../Dictionary.json'
      }).then((response)=>{
          dispatch(setLanguage(response.data.dictionary[lan],lan))
      })
  }
};
