import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from "moment";
import ChangePassword from "../../components/Admin/ChangePassword";
import 'react-day-picker/lib/style.css';
import {createUser,updateUser} from "../../Actions/UserAction";
import {getHotelReserve} from '../../Actions/HotelAction';
import queryString from "query-string";
import {editMessage} from "../../Actions/VisualDataAction";
import numeral from "numeral";
import {Payment} from '../../Enum/Models';
import {ServerAddress} from '../../Enum/Urls';
import DatePickerDrop from '../../components/Common/datePickerDrop';
import Price from '../../components/Common/Price'
 class Index extends React.Component{
    ref=null;
    state={
        userInformation:this.props.userData,
        selectedDay:undefined,
        selectedItem:0,
        lockItem:true,
        MessageContainerShow:false,
        MessageContainerContent:null,
        showError:false,


     };
    componentDidMount() {
        window.scrollTo(0,0);
        let parameters=queryString.parse(this.props.location.search);
        if(parameters.mode){
                this.setState({selectedItem:parseInt(parameters.mode)})
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.selectedItem!==prevState.selectedItem){
            if(this.state.selectedItem===1){
                //call hotel service
                this.props.editMessage(false);
                this.props.getHotelReserve("HotelGlobal");
            }
            if(this.state.selectedItem===2){
                //call hotel service
                this.props.editMessage(false);
                this.props.getHotelReserve("FlightGlobal");
            }
            this.props.editMessage(false);
        }

    }
    bindNode=(node)=>{
        if(node){
            this.ref=node.state
        }
    };
    handleDayChange=(value)=>{
         this.setState({
            userInformation:{...this.state.userInformation,birthDay:moment(value._d).format("YYYY-MM-DD")}
        })
    };
    changeFeild(e){
        let value=e.target.value;
        let name=e.target.name;
        let input=this.state.userInformation;
        input[name]=value;
        this.setState({
            userInformation:{...input}
        })
    }
    saveChange(){
        this.setState({
            showError:true
        });
        if(this.state.userInformation.firstName && this.state.userInformation.lastName){
            this.props.updateUser(this.state.userInformation);
            this.setState({
                lockItem:true
            })
        }
    }
    render() {
        
        const {Dictionary} = this.props;
        const {userInformation} =this.state;
        const {userData}=this.props;
        return(
            <div className="section-vertical-padding">
                <div className="profileContainer">
                    <div className="page-container">
                        <h2 className="page-title"> {Dictionary.userProfile} </h2>                    
                        <div className="bg-white box-shadow">
                            <div className="clearfix tab-holder text-center">
                                <button type="button" onClick={()=>this.setState({selectedItem:0})}  className={(this.state.selectedItem===0)?"tab-s active-tab-s":"tab-s"}>
                                    {Dictionary.editInformation}
                                </button>
                                <button type="button" onClick={()=>this.setState({selectedItem:1})} className={(this.state.selectedItem===1)?"tab-s active-tab-s":"tab-s"}>
                                    {Dictionary.reservedHotels}
                                </button>
                                <button type="button" onClick={()=>this.setState({selectedItem:2})} className={(this.state.selectedItem===2)?"tab-s active-tab-s":"tab-s"}>
                                    {Dictionary.reservedFlights}                                    
                                </button>
                                <button type="button" onClick={()=>this.setState({selectedItem:3})} className={(this.state.selectedItem===3)?"tab-s active-tab-s":"tab-s"}>
                                    {Dictionary.changePassword}
                                </button>
                            </div>
                            {(this.state.selectedItem === 0) &&
                            <div className="card-padding">
                                <div>
                                    <h3 className="normal-title">
                                        <span className="itours-icon icon-large user-profile margin-end" />
                                        <span className="inline-block">
                                            {Dictionary.userInformations}                                           
                                        </span>
                                    </h3>
                                    
                                    <div>
                                        <label className="form-label">{Dictionary.email} : </label>
                                        <b className="inline-block margin-start">
                                            {userInformation.emailAddress}
                                        </b>
                                    </div>
                                    <div className="varified margin-bottom">
                                        {(!userInformation.isEmailConfirmed) ?
                                            <span className="purple">
                                                {userInformation.yourEmailAddressIsNotConfirmedPleaseCheckEmailrecivedFromItours}
                                            </span>
                                            : <span className="green">
                                                {userInformation.YourEmailAddressIsConfirmed}
                                            </span>
                                        }
                                    </div>
                                    {(this.state.lockItem) ?
                                        <>
                                            <div>
                                                <label className="form-label">{Dictionary.firstName} : </label>
                                                <b className="inline-block margin-start">
                                                    {(userInformation.firstName.length === 0) ? Dictionary.notRegistered : userInformation.firstName}
                                                </b>
                                            </div>
                                            <div>
                                                <label className="form-label">{Dictionary.lastName} : </label>
                                                <b className="inline-block margin-start">
                                                    {(userInformation.lastName.length === 0) ? Dictionary.notRegistered : userInformation.lastName}
                                                </b>
                                            </div>
                                            <div>
                                                <label className="form-label">
                                                    {Dictionary.gender}
                                                    : </label>
                                                <b className="inline-block margin-start">
                                                    {(userInformation.gender) ? Dictionary.male : Dictionary.female}
                                                </b>
                                            </div>
                                            <div>
                                                <label className="form-label">
                                                    {Dictionary.birthday}
                                                    : </label>
                                                <b className="inline-block margin-start">
                                                    {(userInformation.birthDay == null) ? Dictionary.notRegistered : userInformation.birthDay.split("T")[0]}
                                                </b>
                                            </div>
                                            <div>
                                                <label className="form-label">{Dictionary.nationality} : </label>
                                                <b className="inline-block margin-start">
                                                    {(userInformation.nationalityId == null) ? Dictionary.notRegistered : userInformation.nationalityId}
                                                </b>
                                            </div>
                                            <button className="button blue-btn margin-top" type="button"
                                                    onClick={() => this.setState({lockItem: false})}>
                                                {Dictionary.editInformation}
                                            </button>
                                        </>
                                        :
                                        <>
                                            <div className="float-row">
                                                <div className="col-xsmall-12 col-small-4 col-large-2">
                                                    <div className="form-group">
                                                        <label className="form-label">{Dictionary.gender}</label>
                                                        <select className="form-select"
                                                                onChange={this.changeFeild.bind(this)}
                                                                defaultValue={userInformation.gender} name="gender">
                                                            <option value={true}>{Dictionary.male}</option>
                                                            <option value={false}>{Dictionary.female}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xsmall-12 col-small-4 col-large-5">
                                                    <div className="form-group">
                                                        <label className="form-label">{Dictionary.firstName}</label>
                                                        <input className="form-input" type="text"  defaultValue={userInformation.firstName} name="firstName"  onChange={this.changeFeild.bind(this)}/>
                                                        {this.state.showError && !this.state.userInformation.firstName && <span className={"purple"}>{Dictionary.thisFieldIsRequired}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-xsmall-12 col-small-4 col-large-5">
                                                    <div className="form-group">
                                                        <label className="form-label">{Dictionary.lastName} </label>
                                                        <input className="form-input" type="text"
                                                               defaultValue={userInformation.lastName} name="lastName"
                                                               onChange={this.changeFeild.bind(this)}/>
                                                        {this.state.showError && !this.state.userInformation.lastName && <span className={"purple"}>{Dictionary.thisFieldIsRequired}</span>}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="float-row">
                                                <div className="col-small-12 col-large-6">
                                                    <div className="form-group">
                                                        <label className="form-label">{Dictionary.birthday} </label>
                                                        {/*<Datepicker isPersian={true}*/}
                                                        {/*            onChange={(value) => this.handleDayChange(value)}*/}
                                                        {/*            placeholder={this.state.userInformation.birthDay.split("T")[0]}/>*/}
                                                        <DatePickerDrop ref={this.bindNode}
                                                        defaultValue={null} format={"YYYY-MM-DD"} 
                                                        name={`birthDay`} 
                                                        min={"1900-01-01"}
                                                        max={moment().format("YYYY-MM-DD")}
                                                        change={(e)=>{this.setState({userInformation:{...this.state.userInformation,birthDay:moment(e._d).format("YYYY-MM-DD")}})}}
                                                         />

                                                    </div>
                                                </div>
                                                <div className="col-small-12 col-large-6">
                                                    <div className="form-group">
                                                        <label className="form-label">{Dictionary.nationality} </label>
                                                        <select className="form-select"
                                                                defaultValue={userInformation.nationalityId}
                                                                onChange={this.changeFeild.bind(this)}
                                                                name="nationalityId">
                                                            <option value={null}></option>
                                                            <option value="AF">Afghanistan</option>
                                                            <option value="AL">Albania</option>
                                                            <option value="DZ">Algeria</option>
                                                            <option value="AS">American Samoa</option>
                                                            <option value="AD">Andorra</option>
                                                            <option value="AO">Angola</option>
                                                            <option value="AI">Anguilla</option>
                                                            <option value="AQ">Antarctica</option>
                                                            <option value="AG">Antigua and Barbuda</option>
                                                            <option value="AR">Argentina</option>
                                                            <option value="AM">Armenia</option>
                                                            <option value="AW">Aruba</option>
                                                            <option value="AU">Australia</option>
                                                            <option value="AT">Austria</option>
                                                            <option value="AZ">Azerbaijan</option>
                                                            <option value="BS">Bahamas</option>
                                                            <option value="BH">Bahrain</option>
                                                            <option value="BD">Bangladesh</option>
                                                            <option value="BB">Barbados</option>
                                                            <option value="BY">Belarus</option>
                                                            <option value="BE">Belgium</option>
                                                            <option value="BZ">Belize</option>
                                                            <option value="BJ">Benin</option>
                                                            <option value="BM">Bermuda</option>
                                                            <option value="BT">Bhutan</option>
                                                            <option value="BO">Bolivia (Plurinational State of)</option>
                                                            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                            <option value="BA">Bosnia and Herzegovina</option>
                                                            <option value="BW">Botswana</option>
                                                            <option value="BV">Bouvet Island</option>
                                                            <option value="BR">Brazil</option>
                                                            <option value="IO">British Indian Ocean Territory</option>
                                                            <option value="BN">Brunei Darussalam</option>
                                                            <option value="BG">Bulgaria</option>
                                                            <option value="BF">Burkina Faso</option>
                                                            <option value="BI">Burundi</option>
                                                            <option value="CV">Cabo Verde</option>
                                                            <option value="KH">Cambodia</option>
                                                            <option value="CM">Cameroon</option>
                                                            <option value="CA">Canada</option>
                                                            <option value="KY">Cayman Islands</option>
                                                            <option value="CF">Central African Republic</option>
                                                            <option value="TD">Chad</option>
                                                            <option value="CL">Chile</option>
                                                            <option value="CN">China</option>
                                                            <option value="CX">Christmas Island</option>
                                                            <option value="CC">Cocos (Keeling) Islands</option>
                                                            <option value="CO">Colombia</option>
                                                            <option value="KM">Comoros</option>
                                                            <option value="CG">Congo</option>
                                                            <option value="CD">Congo (Democratic Republic of the)
                                                            </option>
                                                            <option value="CK">Cook Islands</option>
                                                            <option value="CR">Costa Rica</option>
                                                            <option value="CI">Cote d'Ivoire</option>
                                                            <option value="HR">Croatia</option>
                                                            <option value="CU">Cuba</option>
                                                            <option value="CW">Curacao</option>
                                                            <option value="CY">Cyprus</option>
                                                            <option value="CZ">Czech Republic</option>
                                                            <option value="DK">Denmark</option>
                                                            <option value="DJ">Djibouti</option>
                                                            <option value="DM">Dominica</option>
                                                            <option value="DO">Dominican Republic</option>
                                                            <option value="EC">Ecuador</option>
                                                            <option value="EG">Egypt</option>
                                                            <option value="SV">El Salvador</option>
                                                            <option value="GQ">Equatorial Guinea</option>
                                                            <option value="ER">Eritrea</option>
                                                            <option value="EE">Estonia</option>
                                                            <option value="ET">Ethiopia</option>
                                                            <option value="FK">Falkland Islands (Malvinas)</option>
                                                            <option value="FO">Faroe Islands</option>
                                                            <option value="FJ">Fiji</option>
                                                            <option value="FI">Finland</option>
                                                            <option value="FR">France</option>
                                                            <option value="GF">French Guiana</option>
                                                            <option value="PF">French Polynesia</option>
                                                            <option value="TF">French Southern Territories</option>
                                                            <option value="GA">Gabon</option>
                                                            <option value="GM">Gambia</option>
                                                            <option value="GE">Georgia</option>
                                                            <option value="DE">Germany</option>
                                                            <option value="GH">Ghana</option>
                                                            <option value="GI">Gibraltar</option>
                                                            <option value="GR">Greece</option>
                                                            <option value="GL">Greenland</option>
                                                            <option value="GD">Grenada</option>
                                                            <option value="GP">Guadeloupe</option>
                                                            <option value="GU">Guam</option>
                                                            <option value="GT">Guatemala</option>
                                                            <option value="GG">Guernsey</option>
                                                            <option value="GN">Guinea</option>
                                                            <option value="GW">Guinea-Bissau</option>
                                                            <option value="GY">Guyana</option>
                                                            <option value="HT">Haiti</option>
                                                            <option value="HM">Heard Island and McDonald Islands
                                                            </option>
                                                            <option value="VA">Holy See</option>
                                                            <option value="HN">Honduras</option>
                                                            <option value="HK">Hong Kong</option>
                                                            <option value="HU">Hungary</option>
                                                            <option value="IS">Iceland</option>
                                                            <option value="IN">India</option>
                                                            <option value="ID">Indonesia</option>
                                                            <option value="IR">Iran</option>
                                                            <option value="IQ">Iraq</option>
                                                            <option value="IE">Ireland</option>
                                                            <option value="IM">Isle of Man</option>
                                                            <option value="IL">Israel</option>
                                                            <option value="IT">Italy</option>
                                                            <option value="JM">Jamaica</option>
                                                            <option value="JP">Japan</option>
                                                            <option value="JE">Jersey</option>
                                                            <option value="JO">Jordan</option>
                                                            <option value="KZ">Kazakhstan</option>
                                                            <option value="KE">Kenya</option>
                                                            <option value="KI">Kiribati</option>
                                                            <option value="KP">Korea (Democratic People's Republic of)
                                                            </option>
                                                            <option value="KR">Korea (Republic of)</option>
                                                            <option value="KW">Kuwait</option>
                                                            <option value="KG">Kyrgyzstan</option>
                                                            <option value="LA">Lao People's Democratic Republic</option>
                                                            <option value="LV">Latvia</option>
                                                            <option value="LB">Lebanon</option>
                                                            <option value="LS">Lesotho</option>
                                                            <option value="LR">Liberia</option>
                                                            <option value="LY">Libya</option>
                                                            <option value="LI">Liechtenstein</option>
                                                            <option value="LU">Luxembourg</option>
                                                            <option value="MO">Macao</option>
                                                            <option value="MK">Macedonia (the former Yugoslav Republic
                                                                of)
                                                            </option>
                                                            <option value="MG">Madagascar</option>
                                                            <option value="MW">Malawi</option>
                                                            <option value="MY">Malaysia</option>
                                                            <option value="MV">Maldives</option>
                                                            <option value="ML">Mali</option>
                                                            <option value="MT">Malta</option>
                                                            <option value="MH">Marshall Islands</option>
                                                            <option value="MQ">Martinique</option>
                                                            <option value="MR">Mauritania</option>
                                                            <option value="MU">Mauritius</option>
                                                            <option value="YT">Mayotte</option>
                                                            <option value="MX">Mexico</option>
                                                            <option value="FM">Micronesia (Federated States of)</option>
                                                            <option value="MD">Moldova (Republic of)</option>
                                                            <option value="MC">Monaco</option>
                                                            <option value="MN">Mongolia</option>
                                                            <option value="ME">Montenegro</option>
                                                            <option value="MS">Montserrat</option>
                                                            <option value="MA">Morocco</option>
                                                            <option value="MZ">Mozambique</option>
                                                            <option value="MM">Myanmar</option>
                                                            <option value="NA">Namibia</option>
                                                            <option value="NR">Nauru</option>
                                                            <option value="NP">Nepal</option>
                                                            <option value="NL">Netherlands</option>
                                                            <option value="NC">New Caledonia</option>
                                                            <option value="NZ">New Zealand</option>
                                                            <option value="NI">Nicaragua</option>
                                                            <option value="NE">Niger</option>
                                                            <option value="NG">Nigeria</option>
                                                            <option value="NU">Niue</option>
                                                            <option value="NF">Norfolk Island</option>
                                                            <option value="MP">Northern Mariana Islands</option>
                                                            <option value="NO">Norway</option>
                                                            <option value="OM">Oman</option>
                                                            <option value="PK">Pakistan</option>
                                                            <option value="PW">Palau</option>
                                                            <option value="PS">Palestine, State of</option>
                                                            <option value="PA">Panama</option>
                                                            <option value="PG">Papua New Guinea</option>
                                                            <option value="PY">Paraguay</option>
                                                            <option value="PE">Peru</option>
                                                            <option value="PH">Philippines</option>
                                                            <option value="PN">Pitcairn</option>
                                                            <option value="PL">Poland</option>
                                                            <option value="PT">Portugal</option>
                                                            <option value="PR">Puerto Rico</option>
                                                            <option value="QA">Qatar</option>
                                                            <option value="RO">Romania</option>
                                                            <option value="RU">Russian Federation</option>
                                                            <option value="RW">Rwanda</option>
                                                            <option value="RE">Réunion</option>
                                                            <option value="BL">Saint Barthelemy</option>
                                                            <option value="SH">Saint Helena, Ascension and Tristan da
                                                                Cunha
                                                            </option>
                                                            <option value="KN">Saint Kitts and Nevis</option>
                                                            <option value="LC">Saint Lucia</option>
                                                            <option value="MF">Saint Martin (French part)</option>
                                                            <option value="PM">Saint Pierre and Miquelon</option>
                                                            <option value="VC">Saint Vincent and the Grenadines</option>
                                                            <option value="WS">San Marino</option>
                                                            <option value="ST">Sao Tome and Principe</option>
                                                            <option value="SA">Saudi Arabia</option>
                                                            <option value="SN">Senegal</option>
                                                            <option value="RS">Serbia</option>
                                                            <option value="SC">Seychelles</option>
                                                            <option value="SL">Sierra Leone</option>
                                                            <option value="SG">Singapore</option>
                                                            <option value="SX">Sint Maarten (Dutch part)</option>
                                                            <option value="SK">Slovakia</option>
                                                            <option value="SI">Slovenia</option>
                                                            <option value="SB">Solomon Islands</option>
                                                            <option value="SO">Somalia</option>
                                                            <option value="ZA">South Africa</option>
                                                            <option value="GS">South Georgia and the South Sandwich
                                                                Islands
                                                            </option>
                                                            <option value="SS">South Sudan</option>
                                                            <option value="ES">Spain</option>
                                                            <option value="LK">Sri Lanka</option>
                                                            <option value="SD">Sudan</option>
                                                            <option value="SR">Suriname</option>
                                                            <option value="SJ">Svalbard and Jan Mayen</option>
                                                            <option value="SZ">Swaziland</option>
                                                            <option value="SE">Sweden</option>
                                                            <option value="CH">Switzerland</option>
                                                            <option value="SY">Syrian Arab Republic</option>
                                                            <option value="TW">Taiwan, Province of China[a]</option>
                                                            <option value="TJ">Tajikistan</option>
                                                            <option value="TZ">Tanzania, United Republic of</option>
                                                            <option value="TH">Thailand</option>
                                                            <option value="TL">Timor-Leste</option>
                                                            <option value="TG">Togo</option>
                                                            <option value="TK">Tokelau</option>
                                                            <option value="TO">Tonga</option>
                                                            <option value="TT">Trinidad and Tobago</option>
                                                            <option value="TN">Tunisia</option>
                                                            <option value="TR">Turkey</option>
                                                            <option value="TM">Turkmenistan</option>
                                                            <option value="TC">Turks and Caicos Islands</option>
                                                            <option value="TV">Tuvalu</option>
                                                            <option value="UG">Uganda</option>
                                                            <option value="UA">Ukraine</option>
                                                            <option value="AE">United Arab Emirates</option>
                                                            <option value="GB">United Kingdom of Great Britain and
                                                                Northern Ireland
                                                            </option>
                                                            <option value="UM">United States Minor Outlying Islands
                                                            </option>
                                                            <option value="US">United States of America</option>
                                                            <option value="UY">Uruguay</option>
                                                            <option value="UZ">Uzbekistan</option>
                                                            <option value="VU">Vanuatu</option>
                                                            <option value="VE">Venezuela (Bolivarian Republic of)
                                                            </option>
                                                            <option value="VN">Viet Nam</option>
                                                            <option value="VG">Virgin Islands (British)</option>
                                                            <option value="VI">Virgin Islands (U.S.)</option>
                                                            <option value="WF">Wallis and Futuna</option>
                                                            <option value="EH">Western Sahara</option>
                                                            <option value="YE">Yemen</option>
                                                            <option value="ZM">Zambia</option>
                                                            <option value="ZW">Zimbabwe</option>
                                                            <option value="AX">land Islands</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="button red-btn margin-top" type="button"
                                                    onClick={this.saveChange.bind(this)}>
                                                {Dictionary.saveChanges}
                                            </button>
                                        </>
                                    }

                                </div>
                                {(this.state.MessageContainerShow) ?
                                    <div className="errorShow">
                                        {this.state.MessageContainerContent}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            }
                            {(this.state.selectedItem === 3) &&
                                <div className="card-padding">
                                    <div className="information">
                                        <h3 className="normal-title">
                                            <span className="itours-icon icon-large change-password margin-end" />
                                            <span className="inline-block">
                                                {Dictionary.changePassword}
                                            </span>
                                        </h3>
                                        <ChangePassword/>
                                    </div>
                                    {(this.state.MessageContainerShow) ?
                                        <div className="errorShow">
                                            {this.state.MessageContainerContent}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            }
                            {(this.state.selectedItem===1)&&
                                <div className="card-padding">
                                    <h3 className="normal-title">
                                        <span className="itours-icon icon-large hotel-ticket margin-end" />
                                        <span className="inline-block"> 
                                            {Dictionary.reservedHotels}
                                        </span>
                                    </h3> 
                                                                               
                                    {(!this.props.responded)?
                                        <div> 
                                            {Dictionary.loadingData}
                                                ...
                                        </div>
                                        :
                                        <div className="itours-table-holder responsive-table no-scroll-responsive-table">
                                            <table className="itours-table">
                                                <thead>
                                                    <tr>
                                                        <th>{Dictionary.id}</th>
                                                        <th> {Dictionary.checkIn} </th>
                                                        <th>{Dictionary.fullName}</th>
                                                        <th>{Dictionary.hotelName}</th>
                                                        <th>{Dictionary.price}</th>
                                                        <th>{Dictionary.status}</th>
                                                        <th />
                                                        <th />
                                                        <th />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.props.hotelVocher.map((item,index)=>
                                                    <tr key={index}>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.id} </span>
                                                            <span> {item.id} </span>    
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.checkIn} </span>
                                                            <span className="text-end"> {item.date} </span> 
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.fullName} </span>
                                                            <span className="text-end"> {(item.gender)?'Mr':'Mrs'} {item.firstName}  {item.lastName} </span>                                                             
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.hotelName} </span>
                                                            <span className="text-end"> {item.hotel} </span>                                                             
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.price} </span>
                                                            <span className="nowrap price-holder text-end" dir="ltr"> {numeral(item.salePrice).format('0,0')} {item.currency} </span>                                                             
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.status} </span>
                                                            {
                                                            (item.status==="Issued")?
                                                            <span className="green nowrap text-end">
                                                                {Dictionary.Issued}
                                                            </span>
                                                            :(item.status==="Pending")?
                                                            <span className="nowrap text-end">
                                                                {Dictionary.paymentPending}
                                                            </span>
                                                            :(item.status==="Canceled")?
                                                            <span className="nowrap text-end">
                                                                {Dictionary.canceled}
                                                            </span>
                                                            :(item.status==="Refunded")?
                                                            <span className="nowrap text-end">
                                                                {Dictionary.Refunded}
                                                            </span>
                                                            :(item.status==="Failed")?
                                                            <span className="purple nowrap text-end">
                                                                {Dictionary.WebServiceUnsuccessful}
                                                            </span>
                                                            :(item.status==="PaymentSuccessful")?
                                                            <span className="green nowrap text-end">
                                                                {Dictionary.successfulPayment}
                                                            </span>
                                                            :(item.status==="WebServiceUnsuccessful")?
                                                            <span className="purple nowrap text-end">
                                                                {Dictionary.WebServiceUnsuccessful}
                                                            </span>
                                                            :(item.status==="Registered")?
                                                            <span className="nowrap text-end">
                                                                {Dictionary.registered}
                                                            </span>
                                                            :(item.status==="Undefined")?
                                                            <span className="nowrap text-end">
                                                                {Dictionary.undefined}
                                                            </span>
                                                            :<span className="nowrap text-end">
                                                                {Dictionary.undefined}
                                                            </span>
                                                            }
                                                        </td>

                                                        {(item.status===Payment.Registered || item.status===Payment.Pending)?
                                                            <td>
                                                                <a className="payment button red-btn nowrap" href={`${ServerAddress.Type}${ServerAddress.localServer}/payment/reserve?reserveId=${item.id}`}>
                                                                    {Dictionary.payment}
                                                                </a>
                                                            </td>
                                                            :
                                                            <td className="nowrap">---</td>
                                                        }
                                                        {(item.status===Payment.Registered || item.status===Payment.Issued)?
                                                            <td>
                                                                <a target="_blank" className=" button blue-btn nowrap" rel="noopener noreferrer" href={`http://92.222.136.211:8080/en/Hotel/Voucher?reserveId=${item.id}&username=${userData.userName}`}>
                                                                    {Dictionary.GetVoucher}
                                                                </a>
                                                            </td>
                                                            :
                                                            <td className="nowrap">---</td>

                                                        }
                                                        <td>
                                                            <Link className="nowrap content-link has-left-arrow" to={`/profile/details?userName=${userData.userName}&reserveId=${item.id}`}>
                                                                {Dictionary.reservationDetails}
                                                            </Link>
                                                        </td>

                                                    </tr>
                                                )}
                                                </tbody>                                                    

                                            </table>
                                        </div>
                                    }                                        

                                </div>
                            }
                            {(this.state.selectedItem===2)&&
                                <div className="card-padding">
                                    <h3 className="normal-title">
                                        <span className="itours-icon icon-large flight-ticket margin-end" />
                                        <span className="inline-block">
                                            {Dictionary.reservedFlights}
                                        </span>
                                    </h3>
                                    
                                    {(!this.props.responded)?
                                        <div>
                                            {Dictionary.loadingData}
                                            ...
                                        </div>
                                        :
                                        <div className="itours-table-holder responsive-table no-scroll-responsive-table">
                                            <table className="itours-table">
                                                <thead>
                                                <tr>
                                                    <th>{Dictionary.id}</th>
                                                    <th>{Dictionary.departure} </th>
                                                    <th>{Dictionary.return} </th>
                                                    <th>{Dictionary.fullName} </th>
                                                    <th>{Dictionary.email} </th>
                                                    <th>{Dictionary.phoneNumber} </th>
                                                    <th>{Dictionary.price} </th>
                                                    <th>{Dictionary.status} </th>
                                                    <th></th>
                                                    
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.props.flightVoucher.map((item,index)=>
                                                    <tr key={index}>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.id} </span>
                                                            <span> {item.id} </span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.departureDate} </span>
                                                            <span className="text-end"> {moment(item.departureDate,"YYYY-MM-DDTHH:mm").format("YYYY/MM/DD HH:mm")} </span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.returnDate} </span>
                                                            <span className="text-end"> {moment(item.returnDate,"YYYY-MM-DDTHH:mm").format("YYYY/MM/DD HH:mm")} </span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.fullName} </span>
                                                            <span className="en-font text-end"> {(item.gender)?'Mr':'Mrs'} {item.firstName}  {item.lastName} </span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.email} </span>
                                                            <span className="en-font text-end"> {item.email} </span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.phoneNumber} </span>
                                                            <span> {item.phoneNumber} </span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.price} </span>
                                                            <span className="nowrap price-holder text-end" dir="ltr"><Price currency={item.currency.code}>{item.salePrice}</Price></span>
                                                        </td>
                                                        <td>
                                                            <span className="mobile-label"> {Dictionary.status} </span>
                                                            {
                                                            (item.status==="Issued")?
                                                            <span className="green nowrap text-end">
                                                                {Dictionary.Issued}
                                                            </span>
                                                            :(item.status==="Pending")?
                                                            <span className="nowrap text-end">
                                                                {Dictionary.paymentPending}
                                                            </span>
                                                            :(item.status==="Canceled")?
                                                            <span className="nowrap text-end">
                                                                {Dictionary.canceled}
                                                            </span>
                                                            :(item.status==="Refunded")?
                                                            <span className="nowrap text-end">
                                                                {Dictionary.Refunded}
                                                            </span>
                                                            :(item.status==="Failed")?
                                                            <span className="purple nowrap text-end">
                                                                {Dictionary.WebServiceUnsuccessful}
                                                            </span>
                                                            :(item.status==="PaymentSuccessful")?
                                                            <span className="green nowrap text-end">
                                                                {Dictionary.successfulPayment}
                                                            </span>
                                                            :(item.status==="WebServiceUnsuccessful")?
                                                            <span className="purple nowrap text-end">
                                                                {Dictionary.WebServiceUnsuccessful}
                                                            </span>
                                                            :(item.status==="Registered")?
                                                            <span className="nowrap text-end">
                                                                {Dictionary.registered}
                                                            </span>
                                                            :(item.status==="Undefined")?
                                                            <span className="nowrap text-end">
                                                                {Dictionary.undefined}
                                                            </span>
                                                            :<span className="nowrap text-end">
                                                                {Dictionary.undefined}
                                                            </span>
                                                            }
                                                        </td> 
                                                        <td>
                                                            <Link className="nowrap content-link has-left-arrow" to={`/profile/details?userName=${userData.userName}&reserveId=${item.id}`}>
                                                                {Dictionary.reservationDetails}
                                                            </Link>
                                                        </td>                                                       


                                                    </tr>
                                                )}
                                                </tbody>

                                            </table>
                                        </div>
                                    }

                                </div>

                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
const mapStateToProps=(state)=>({
    userData:state.UserData.userData,
    hotelVocher:state.HotelSearch.reserveHotel,
    responded:state.VisualData.Responded,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN,
    flightVoucher:state.FlightSearch.reserveFlight
});
const mapActionToDispatch=(dispatch)=>({
    createUser:(userInfo)=>dispatch(createUser(userInfo)),
    updateUser:(userInfo)=>dispatch(updateUser(userInfo)),
    getHotelReserve:(type)=>dispatch(getHotelReserve(type)),
    editMessage:(param)=>{dispatch(editMessage(param))},
});
export default connect(mapStateToProps,mapActionToDispatch)(Index)