import React,{useState} from 'react';
import Price from '../Common/Price';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import axios from "axios";
import {Header, Hotel as HotelURL, ServerAddress} from "../../Enum/Urls";
import {connect} from "react-redux";
const Room=(props)=>{
        const {model}=props;
    const {Dictionary,LAN} = props;
        const [cancelationInfo,setCancelation]=useState(null);
        const showCancelation=(bookingID)=>{
            //create params for creating request
            setCancelation('waiting');
            let parameters=queryString.parse(props.urlParam);
            let params=new URLSearchParams();
            parameters.Adults.split(",").forEach((item)=> params.append("Adults",item));
            parameters.Children.split(",").forEach((item)=>params.append("Children",item));
            if(parameters.Ages.length!==0){
                parameters.Ages.split(",").forEach((item)=>params.append("Ages",item));
            }
            params.append("Checkin",parameters.Checkin);
            params.append("Checkout",parameters.Checkout);
            params.append("BookingToken",bookingID);
            axios({
                method:'get',
                url:`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetRoom}`,
                params,
                headers:{...Header}
            }).then((responsive)=>{
                //let result={cancelationInfo:responsive.data.result.result.cancellations,bookingID:params.get("BookingToken")};
                setCancelation(responsive.data.result.result.cancellations);
            }).catch((error)=>{
                //error handling
            });
        };
        return(

        <div className="room-item">
            <div className="room-title" dir="ltr">
                <b>{model.name}</b>
            </div>
            <div className="room-detail">

                <div className="detail-table">
                    <div className="detail-table-tbody">
                        <div className="detail-table-row hidden-xsmall hidden-small">
                            <div className="detail-table-cell">
                                <b className="room-table-title">
                                    {Dictionary.whatsIncluded}
                                </b>
                            </div>
                            <div className="detail-table-cell">
                                <b className="room-table-title">
                                    {Dictionary.guests}
                                </b>
                            </div>
                            <div className="detail-table-cell">
                                <b className="room-table-title">
                                    {Dictionary.totalForStay }
                                </b>
                            </div>
                            <div className="detail-table-cell">

                            </div>

                        </div>
                        <div className="detail-table-row bordered-row">
                            <div className="detail-table-cell">
                                <b className="font-14 semi-bold">{model.board.name}</b>
                            </div>
                            <div className="detail-table-cell">
                                <span className="inline-block body-direction font-12 semi-bold">
                                    <span className="itours-icon guest-dark margin-end-light icon-small"/>
                                    {model.adults}
                                    <span>  {( model.adults > 1 )?Dictionary.adults:Dictionary.adult} </span>
                                    {(model.children>0) && <span> <span> {Dictionary.and} </span> {model.children} <span> {( model.children > 1 )?Dictionary.children:Dictionary.child} </span></span> }
                                </span>
                            </div>
                            <div className="detail-table-cell">
                                <b className="font-18 room-price margin-bottom"> <Price currency={props.currency}>{model.salePrice}</Price> </b>
                            </div>
                            <div className="detail-table-cell">
                                <button type="button" className="button red-btn large-font" onClick={()=>{props.selectRoom(props.number,model.name,model.salePrice,model.bookingToken,model.supplier)}}> 
                                    {Dictionary.bookNow}
                                </button>
                                <div>
                                    {(cancelationInfo===null)&&<button type={'button'} className="show-rules button link-style-btn" onClick={()=>{showCancelation(props.model.bookingToken)}}>{Dictionary.cancellationRules}</button>}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                {(cancelationInfo===null) ||
                <>
                    {(cancelationInfo==="waiting")?
                        <div className="cancelationContainer body-direction">
                            {Dictionary.loadingInformationPleaseWait}
                            <span className="dots-loading"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span>
                        </div>
                        :
                        <div className="cancelationContainer body-direction">
                            <h4 className="normal-title">{Dictionary.cancellationRules}</h4>
                            {(LAN === "IR")?
                            <div className="margin-bottom">
                                امکان کنسل کردن فقط در تاریخ های زیر میسر است
                                ،
                                در صورت درخواست 
                                <b> 2,000,000 ریال </b>
                                بابت کارمزد خدمات از مبلغ کل رزرو کسر میگردد 
                            </div>
                            :
                            null
                            }
                            <ul className="bullet-list">
                                {cancelationInfo.map((cancelItem,cancelIndex)=>{
                                    let fromDate=new Date(cancelItem.fromDate);
                                    let toDate=new Date(cancelItem.toDate);
                                    let fromTime=fromDate.toLocaleTimeString().split(" ")[0];
                                    fromDate=fromDate.getFullYear() +"-"+ (fromDate.getMonth()+1) +"-" + fromDate.getDate();
                                    let toTime=toDate.toLocaleTimeString().split(" ")[0];
                                    toDate=toDate.getFullYear() +"-"+ (toDate.getMonth()+1) +"-" + toDate.getDate();
                                    return <li key={cancelIndex}>
                                        <span className="inline-block margin-end-light">{Dictionary.from} </span>
                                        <span className="inline-block margin-end-light" dir="ltr"> {fromDate}</span>
                                        <span className="inline-block margin-end-light">,</span>
                                        <span className="inline-block margin-end-light"> {fromTime} </span>
                                        <span className="inline-block margin-end-light">{Dictionary.until} </span>
                                        <span className="inline-block margin-end-light" dir="ltr"> {toDate}</span>
                                        <span className="inline-block margin-end-light">,</span>
                                        <span className="inline-block margin-end-light"> {toTime} </span>
                                        {(cancelItem.amount===0) && <span className="inline-block margin-end-light">({Dictionary.cancellationWillBeWithoutPenalty})</span>}
                                        {(cancelItem.amount>0) &&
                                        <>
                                            {
                                            (LAN === "IR")?
                                            <>
                                            <span className="inline-block margin-end-light">(جریمه کنسل کردن</span>
                                            <span className="inline-block margin-end-light"><Price currency={props.currency}>{cancelItem.amount}</Price></span>
                                            <span className="inline-block">خواهد بود)</span>                                            
                                            </>
                                            :
                                            <>
                                            <span className="inline-block margin-end-light">({Dictionary.thePenaltyForCancellationWillBe}</span>
                                            <span className="inline-block margin-end-light"><Price currency={props.currency}>{cancelItem.amount}</Price></span>
                                            <span className="inline-block">)</span>                                            
                                            </>                                            
                                            }
                                            
                                        </>
                                        }

                                    </li>;
                                })}                                
                            </ul>
                            {(cancelationInfo.length===0)&&<div>{Dictionary.irretrievable}</div>}
                        </div>
                    }
                </>
                } 
            </div>               
        </div>
        )
};
Room.propTypes={
    model:PropTypes.any.isRequired,
    currency:PropTypes.string.isRequired,
    selectRoom:PropTypes.any.isRequired,
    number:PropTypes.number.isRequired,
    urlParam:PropTypes.any.isRequired
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(Room);