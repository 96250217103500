import React  from 'react';
import {connect} from 'react-redux';
import queryString from "query-string";
import Price from '../../components/Common/Price';
import Form from "../../components/Common/Form";
import {editMessage,SwitchAccountWindow,toggleAccountWindow} from '../../Actions/VisualDataAction';
import {saveParameters,getPreReserveID,postReserve} from "../../Actions/HotelAction";
import Validation from "../../components/Common/Validation";
import {Currency} from "../../Enum/Models";
import {goToAnchor} from 'react-scrollable-anchor';
import { Steps } from 'antd';
import SummaryHotel from '../../components/Accomodation/SummaryHotel';
import siteLogo from '../../image/logo.svg';
import moment from "moment-jalaali";
import {CountryCode,CountryPostalCode} from '../../Enum/Models';
import renderHtml from "react-render-html";
/* import {gregorian_to_jalali} from '../../Enum/Common'; */
import 'react-phone-number-input/style.css'

class GetPassengers extends Form{
    //init
    state={
        ...this.state,
        username:this.props.userData.emailAddress,
        isSendRequest:false,
        phoneNumber:null
    };
    FormDOM=[];
    init=()=>{
        this.props.editMessage(false);
        let parameters=queryString.parse(this.props.location.search);
        this.props.saveParameters(parameters);
        let params=new URLSearchParams();
        params.append("preReserveKey",parameters.preReserveKey);
        this.props.getPreReserveID(params);
    };
    componentDidMount() {
        window.scrollTo(0,0);
        this.props.editMessage(false);
        this.init();
        super.componentDidMount(this.FormDOM);

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.userData!==this.props.userData){
            if(this.props.userData!==null){
                this.setState({
                    username:this.props.userName
                })
            }
            this.render()
        }
        if((this.props.respond===true) && (this.state.isSendRequest===true)){
            this.props.history.push(`/payment/reserve?reserveId=${this.props.reserveId}`);

        }
    }
    handleSubmit(event){
        event.preventDefault();
        super.validateForm(event,()=>{
            this.props.editMessage(false);
            //exception code for adding phone number
            let inputData=JSON.stringify(this.state.DataInput);
            inputData=JSON.parse(inputData);
            inputData.reserver.phoneNumber=inputData.reserver.postalcode+""+inputData.reserver.phoneNumber;
            inputData.reserver.username=(inputData.reserver.username)?inputData.reserver.username:this.state.DataInput.reserver.email;
            this.setState({
                isSendRequest:true,
                DataInput:inputData
            },()=>{
                

                this.props.postReserve(this.state.DataInput);

            });
        })
    }
    render() {
        const {Dictionary,LAN} = this.props;
        let night=0;
        let totalPrice=0;
        if(this.props.currencyCode!==null){
            let currency=this.props.currencyCode.toString();
            this.currecySign=Currency[currency];
        }else{
            this.currecySign="???"
        }
        let RoomRender=[];
        const {respond,hotelInfo,preReserveKey,userData}=this.props;
        const { Step } = Steps;
        let userInfoStatus=((userData.id===undefined) || (userData.id===0));
        if(respond){
            if(hotelInfo!=null){
                night=hotelInfo.date.duration;
                //let cashDateIN=new Date(hotelInfo.date.checkIn);
                //let checkIn=cashDateIN.getFullYear()+"/"+(cashDateIN.getMonth()+1)+"/"+cashDateIN.getDate();
                //let cashDateOUT=new Date(hotelInfo.date.checkOut);
                //let checkOut=cashDateOUT.getFullYear()+"/"+(cashDateOUT.getMonth()+1)+"/"+cashDateOUT.getDate();
                if(hotelInfo.accommodation){
                    hotelInfo.accommodation.rooms.forEach((item)=>{
                        totalPrice+=parseFloat(item.salePrice);
                        let passenger={adults:[],children:[]};
                        for(let itr=1;itr<=item.adults;itr++){
                            passenger.adults.push(itr);
                        }
                        for(let itr=1;itr<=item.children;itr++){
                            passenger.children.push(itr);
                        }
                        RoomRender.push(passenger);
                    });
                }
            }
        }
        let passengerCounter=-1;
        return(
            (!respond)?
                <div className="loading-outer-container">
                    <div>
                        <img src={siteLogo} className="loading-logo" alt="itours"/>
                        <div className="loading-inner-container">
                            <div className="item-1"></div>
                            <div className="item-2"></div>
                            <div className="item-3"></div>
                            <div className="item-4"></div>
                            <div className="item-5"></div>
                        </div>
                        <div className="text-center">
                           {Dictionary.GettingPassengerInformation}
                            ...
                        </div>
                    </div>                                            
                </div>                                  
                :
                <form id={'reserveForm'} onSubmit={this.handleSubmit.bind(this)} ref={(node) => this.FormDOM = node}  noValidate>

                <div className="page-padding">


                    <div className="page-container hotel-get-passenger">
                        
                        <Steps current={1}>
                            <Step title={Dictionary.chooseFlightsOrHotels} />
                            <Step title={Dictionary.enterThePassengersInformation} />
                            <Step title={Dictionary.paymentTypeOptions} />
                        </Steps>
                        {/* <h2 className="page-title">Hotellreservasjon</h2> */}
                        
                        {userInfoStatus &&
                        <>
                            <div className="card margin-bottom card-padding bg-white box-border clearfix">
                                <span className="inline-block align-btns"> {Dictionary.LoginToYourAccountOrGoThroughReservationsWithoutRegistering}.</span>
                                <div className="inline-block pull-end">
                                    <button type={'button'} onClick={()=>{goToAnchor('reserveForm')}}  className="button red-btn margin-right mobile-full-width-btn">{Dictionary.ReservationWithoutRegistration}</button>
                                    <button type="button" onClick={() => { this.props.switchLogin(false); this.props.check()}} className="button blue-btn margin-right mobile-full-width-btn">{Dictionary.login}</button>
                                </div>
                            </div>
                        </>
                        }
                        <div className="getPassengers">
                        {(hotelInfo.accommodation !== undefined) &&
                            <div className="sides-outer-holder">
                                <div className="sides-holder">
                                    <div className="large-side">
                                        <div className="side-item full-width">
                                            <div className="side-item-inner">
                                                <div className="box-border bg-white get-passenger-form-holder margin-bottom">
                                                    <div className="hr_padding">
                                                        <h5 className="title">
                                                            {Dictionary.PassengersInformation}
                                                        </h5>
                                                    </div>
                                                    <div className={(RoomRender.length > 1 )? "multiple-room" : " "} >
                                                    {
                                                    RoomRender.map((item, index) => {
                                                        return (
                                                            <div className="RoomItem padding-20" key={index}>
                                                                <h5 className="normal-title">
                                                                <span className="inline-block margin-end">
                                                                    {Dictionary.room}  {index + 1}
                                                                </span>
                                                                    <span className="inline-block en-font" dir="ltr"> ({hotelInfo.accommodation.rooms[index].name}) </span>
                                                                </h5>
                                                                {

                                                                    <div className="float-row">
                                                                        <div>
                                                                            <div className="col-small-12">
                                                                                {Dictionary.informationOfAdultTravelers}
                                                                            </div>
                                                                            {
                                                                                item.adults.map((item,indexIN)=>{
                                                                                    passengerCounter++;
                                                                                    return (
                                                                                        <div key={indexIN}>
                                                                                            <div className="col-small-12">
                                                                                                <div className="inline-radio">
                                                                                                    <div className="radio-item">
                                                                                                        <input className="custom-radio" type="radio" name={`passengers[${passengerCounter}].gender`} value={true} defaultChecked={true} />
                                                                                                        <label className="radio-label"><span className="radio-mark"></span>{Dictionary.male}</label>
                                                                                                    </div>
                                                                                                    <div className="radio-item">
                                                                                                        <input className="custom-radio" type="radio" name={`passengers[${passengerCounter}].gender`} value={false} />
                                                                                                        <label className="radio-label"><span className="radio-mark"></span>{Dictionary.female}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xsmall-12 col-small-4">
                                                                                                <div className="form-group relative required">
                                                                                                    <label className="form-label">{Dictionary.firstName}</label>
                                                                                                    <input className="form-input" data-validator={true} placeholder={Dictionary.firstName} name={`passengers[${passengerCounter}].firstName`} type="text" />
                                                                                                    <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].firstName`} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xsmall-12 col-small-4">
                                                                                                <div className="form-group relative required">
                                                                                                    <label className="form-label">{Dictionary.lastName}</label>
                                                                                                    <input className="form-input" data-validator={true} placeholder={Dictionary.lastName} name={`passengers[${passengerCounter}].lastName`} type="text" />
                                                                                                    <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].lastName`} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xsmall-12 col-small-4">
                                                                                                <div className="form-group relative required">
                                                                                                    <label className="form-label">{Dictionary.nationality}</label>
                                                                                                    <select className="form-select" name={`passengers[${passengerCounter}].nationalityCode`} data-validator={false} defaultValue={(LAN==="IR")?"IR":"NO"}>
                                                                                                        {CountryCode.map(item=><option value={item.value} key={item.value}>{item.code}</option>)}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                            <input  data-validator={false} value={index+1}  name={`passengers[${passengerCounter}].roomNumber`} type="hidden" />
                                                                                            <input  data-validator={false} value={'Adult'}  name={`passengers[${passengerCounter}].ageCategory`} type="hidden" />
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            <div className="col-small-12">
                                                                                {(item.children.length>0)&&<span>{Dictionary.informationOfChildrenTravelers}</span>}

                                                                            </div>
                                                                            {
                                                                                item.children.map((item,indexIN)=>{
                                                                                    passengerCounter++;
                                                                                    return (
                                                                                        <>
                                                                                            <div className="col-small-12">
                                                                                                <div className="inline-radio">
                                                                                                    <div className="radio-item">
                                                                                                        <input className="custom-radio" type="radio" name={`passengers[${passengerCounter}].gender`} value={true} defaultChecked={true} />
                                                                                                        <label className="radio-label"><span className="radio-mark"></span>{Dictionary.male}</label>
                                                                                                    </div>
                                                                                                    <div className="radio-item">
                                                                                                        <input className="custom-radio" type="radio" name={`passengers[${passengerCounter}].gender`} value={false} />
                                                                                                        <label className="radio-label"><span className="radio-mark"></span>{Dictionary.female}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-small-12 col-medium-6 col-large-4">
                                                                                                <div className="form-group relative required">
                                                                                                    <label className="form-label">{Dictionary.firstName}</label>
                                                                                                    <input className="form-input" data-validator={true} placeholder={Dictionary.firstName} name={`passengers[${passengerCounter}].firstName`} type="text" />
                                                                                                    <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].firstName`} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-small-12 col-medium-6 col-large-4">
                                                                                                <div className="form-group relative required">
                                                                                                    <label className="form-label">{Dictionary.lastName}</label>
                                                                                                    <input className="form-input" data-validator={true} placeholder={Dictionary.lastName} name={`passengers[${passengerCounter}].lastName`} type="text" />
                                                                                                    <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].lastName`} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-small-12 col-medium-12 col-large-4">
                                                                                                <div className="form-group relative required">
                                                                                                    <label className="form-label">{Dictionary.nationality}</label>
                                                                                                    <select className="form-select" name={`passengers[${passengerCounter}].nationalityCode`} data-validator={false} defaultValue={"NO"}>
                                                                                                        <option
                                                                                                            value="AF">Afghanistan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AX">land
                                                                                                            Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AL">Albania
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="DZ">Algeria
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AS">American
                                                                                                            Samoa
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AD">Andorra
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AO">Angola
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AI">Anguilla
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AQ">Antarctica
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AG">Antigua
                                                                                                            and Barbuda
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AR">Argentina
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AM">Armenia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AW">Aruba
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AU">Australia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AT">Austria
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AZ">Azerbaijan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BS">Bahamas
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BH">Bahrain
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BD">Bangladesh
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BB">Barbados
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BY">Belarus
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BE">Belgium
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BZ">Belize
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BJ">Benin
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BM">Bermuda
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BT">Bhutan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BO">Bolivia
                                                                                                            (Plurinational
                                                                                                            State of)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BQ">Bonaire,
                                                                                                            Sint Eustatius
                                                                                                            and Saba
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BA">Bosnia
                                                                                                            and Herzegovina
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BW">Botswana
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BV">Bouvet
                                                                                                            Island
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BR">Brazil
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="IO">British
                                                                                                            Indian Ocean
                                                                                                            Territory
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BN">Brunei
                                                                                                            Darussalam
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BG">Bulgaria
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BF">Burkina
                                                                                                            Faso
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BI">Burundi
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CV">Cabo
                                                                                                            Verde
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="KH">Cambodia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CM">Cameroon
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CA">Canada
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="KY">Cayman
                                                                                                            Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CF">Central
                                                                                                            African Republic
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TD">Chad
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CL">Chile
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CN">China
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CX">Christmas
                                                                                                            Island
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CC">Cocos
                                                                                                            (Keeling)
                                                                                                            Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CO">Colombia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="KM">Comoros
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CG">Congo
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CD">Congo
                                                                                                            (Democratic
                                                                                                            Republic of the)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CK">Cook
                                                                                                            Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CR">Costa
                                                                                                            Rica
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CI">Cote
                                                                                                            d'Ivoire
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="HR">Croatia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CU">Cuba
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CW">Curacao
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CY">Cyprus
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CZ">Czech
                                                                                                            Republic
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="DK">Denmark
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="DJ">Djibouti
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="DM">Dominica
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="DO">Dominican
                                                                                                            Republic
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="EC">Ecuador
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="EG">Egypt
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SV">El
                                                                                                            Salvador
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GQ">Equatorial
                                                                                                            Guinea
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="ER">Eritrea
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="EE">Estonia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="ET">Ethiopia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="FK">Falkland
                                                                                                            Islands
                                                                                                            (Malvinas)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="FO">Faroe
                                                                                                            Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="FJ">Fiji
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="FI">Finland
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="FR">France
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GF">French
                                                                                                            Guiana
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PF">French
                                                                                                            Polynesia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TF">French
                                                                                                            Southern
                                                                                                            Territories
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GA">Gabon
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GM">Gambia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GE">Georgia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="DE">Germany
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GH">Ghana
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GI">Gibraltar
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GR">Greece
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GL">Greenland
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GD">Grenada
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GP">Guadeloupe
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GU">Guam
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GT">Guatemala
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GG">Guernsey
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GN">Guinea
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GW">Guinea-Bissau
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GY">Guyana
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="HT">Haiti
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="HM">Heard
                                                                                                            Island and
                                                                                                            McDonald Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="VA">Holy
                                                                                                            See
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="HN">Honduras
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="HK">Hong
                                                                                                            Kong
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="HU">Hungary
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="IS">Iceland
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="IN">India
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="ID">Indonesia
                                                                                                        </option>
                                                                                                        <option value="IR">Iran
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="IQ">Iraq
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="IE">Ireland
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="IM">Isle
                                                                                                            of Man
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="IL">Israel
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="IT">Italy
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="JM">Jamaica
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="JP">Japan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="JE">Jersey
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="JO">Jordan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="KZ">Kazakhstan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="KE">Kenya
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="KI">Kiribati
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="KP">Korea
                                                                                                            (Democratic
                                                                                                            People's
                                                                                                            Republic of)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="KR">Korea
                                                                                                            (Republic of)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="KW">Kuwait
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="KG">Kyrgyzstan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="LA">Lao
                                                                                                            People's
                                                                                                            Democratic
                                                                                                            Republic
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="LV">Latvia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="LB">Lebanon
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="LS">Lesotho
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="LR">Liberia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="LY">Libya
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="LI">Liechtenstein
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="LT">Lithuania
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="LU">Luxembourg
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MO">Macao
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MK">Macedonia
                                                                                                            (the former
                                                                                                            Yugoslav
                                                                                                            Republic of)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MG">Madagascar
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MW">Malawi
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MY">Malaysia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MV">Maldives
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="ML">Mali
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MT">Malta
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MH">Marshall
                                                                                                            Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MQ">Martinique
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MR">Mauritania
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MU">Mauritius
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="YT">Mayotte
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MX">Mexico
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="FM">Micronesia
                                                                                                            (Federated
                                                                                                            States of)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MD">Moldova
                                                                                                            (Republic of)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MC">Monaco
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MN">Mongolia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="ME">Montenegro
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MS">Montserrat
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MA">Morocco
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MZ">Mozambique
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MM">Myanmar
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NA">Namibia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NR">Nauru
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NP">Nepal
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NL">Netherlands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NC">New
                                                                                                            Caledonia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NZ">New
                                                                                                            Zealand
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NI">Nicaragua
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NE">Niger
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NG">Nigeria
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NU">Niue
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NF">Norfolk
                                                                                                            Island
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MP">Northern
                                                                                                            Mariana Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="NO">Norway
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="OM">Oman
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PK">Pakistan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PW">Palau
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PS">Palestine,
                                                                                                            State of
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PA">Panama
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PG">Papua
                                                                                                            New Guinea
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PY">Paraguay
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PE">Peru
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PH">Philippines
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PN">Pitcairn
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PL">Poland
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PT">Portugal
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PR">Puerto
                                                                                                            Rico
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="QA">Qatar
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="RE">Réunion
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="RO">Romania
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="RU">Russian
                                                                                                            Federation
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="RW">Rwanda
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="BL">Saint
                                                                                                            Barthelemy
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SH">Saint
                                                                                                            Helena,
                                                                                                            Ascension and
                                                                                                            Tristan da Cunha
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="KN">Saint
                                                                                                            Kitts and Nevis
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="LC">Saint
                                                                                                            Lucia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="MF">Saint
                                                                                                            Martin (French
                                                                                                            part)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="PM">Saint
                                                                                                            Pierre and
                                                                                                            Miquelon
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="VC">Saint
                                                                                                            Vincent and the
                                                                                                            Grenadines
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="WS">Samoa
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SM">San
                                                                                                            Marino
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="ST">Sao
                                                                                                            Tome and
                                                                                                            Principe
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SA">Saudi
                                                                                                            Arabia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SN">Senegal
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="RS">Serbia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SC">Seychelles
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SL">Sierra
                                                                                                            Leone
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SG">Singapore
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SX">Sint
                                                                                                            Maarten (Dutch
                                                                                                            part)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SK">Slovakia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SI">Slovenia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SB">Solomon
                                                                                                            Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SO">Somalia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="ZA">South
                                                                                                            Africa
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GS">South
                                                                                                            Georgia and the
                                                                                                            South Sandwich
                                                                                                            Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SS">South
                                                                                                            Sudan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="ES">Spain
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="LK">Sri
                                                                                                            Lanka
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SD">Sudan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SR">Suriname
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SJ">Svalbard
                                                                                                            and Jan Mayen
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SZ">Swaziland
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SE">Sweden
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="CH">Switzerland
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="SY">Syrian
                                                                                                            Arab Republic
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TW">Taiwan,
                                                                                                            Province of
                                                                                                            China[a]
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TJ">Tajikistan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TZ">Tanzania,
                                                                                                            United Republic
                                                                                                            of
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TH">Thailand
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TL">Timor-Leste
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TG">Togo
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TK">Tokelau
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TO">Tonga
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TT">Trinidad
                                                                                                            and Tobago
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TN">Tunisia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TR">Turkey
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TM">Turkmenistan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TC">Turks
                                                                                                            and Caicos
                                                                                                            Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="TV">Tuvalu
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="UG">Uganda
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="UA">Ukraine
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="AE">United
                                                                                                            Arab Emirates
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="GB">United
                                                                                                            Kingdom of Great
                                                                                                            Britain and
                                                                                                            Northern Ireland
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="US">United
                                                                                                            States of
                                                                                                            America
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="UM">United
                                                                                                            States Minor
                                                                                                            Outlying Islands
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="UY">Uruguay
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="UZ">Uzbekistan
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="VU">Vanuatu
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="VE">Venezuela
                                                                                                            (Bolivarian
                                                                                                            Republic of)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="VN">Viet
                                                                                                            Nam
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="VG">Virgin
                                                                                                            Islands
                                                                                                            (British)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="VI">Virgin
                                                                                                            Islands (U.S.)
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="WF">Wallis
                                                                                                            and Futuna
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="EH">Western
                                                                                                            Sahara
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="YE">Yemen
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="ZM">Zambia
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="ZW">Zimbabwe
                                                                                                        </option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                            <input  data-validator={false} value={index+1}  name={`passengers[${passengerCounter}].roomNumber`} type="hidden" />
                                                                                            <input  data-validator={false} value={'Child '}  name={`passengers[${passengerCounter}].ageCategory`} type="hidden" />
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        <input type="hidden" name="preReserveKey" value={preReserveKey} />
                                                                    </div>
                                                                }
                                                                {/*cancelation policy*/}
                                                                <div  className={"cancelationContainer margin-top"}>
                                                                    {(!hotelInfo.accommodation.rooms[index])?
                                                                        <div>{Dictionary.noCancelationRuleFound}</div>
                                                                        :
                                                                        <div>
                                                                            <h4 className="normal-title">{Dictionary.cancellationRules}</h4>
                                                                            {(LAN === "IR")?
                                                                            <div className="margin-bottom">
                                                                                امکان کنسل کردن فقط در تاریخ های زیر میسر است
                                                                                ،
                                                                                در صورت درخواست 
                                                                                <b> 2,000,000 ریال </b>
                                                                                بابت کارمزد خدمات از مبلغ کل رزرو کسر میگردد 
                                                                            </div>
                                                                            :
                                                                            null
                                                                            }
                                                                            <ul className="bullet-list">
                                                                                {hotelInfo.accommodation.rooms[index].cancellations.map((cancelItem,cancelIndex)=>{
                                                                                    let fromDate=new Date(cancelItem.fromDate);
                                                                                    let toDate=new Date(cancelItem.toDate);
                                                                                    let fromTime=fromDate.toLocaleTimeString().split(" ")[0];
                                                                                    fromDate=fromDate.getFullYear() +"-"+ (fromDate.getMonth()+1) +"-" + fromDate.getDate();
                                                                                    let toTime=toDate.toLocaleTimeString().split(" ")[0];
                                                                                    toDate=toDate.getFullYear() +"-"+ (toDate.getMonth()+1) +"-" + toDate.getDate();
                                                                                    return <li key={cancelIndex}>
                                                                                        <span className="inline-block margin-end-light">{Dictionary.from} </span>
                                                                                        <span className="inline-block margin-end-light" dir="ltr">{fromDate}</span>
                                                                                        {(LAN==="IR")&&<span className="font-12">({moment(fromDate).format("jYYYY-jMM-jDD")})</span>}
                                                                                        <span className="inline-block margin-end-light">,</span>
                                                                                        <span className="inline-block margin-end-light"> {fromTime} </span>
                                                                                        <span className="inline-block margin-end-light">{Dictionary.until} </span>
                                                                                        <span className="inline-block margin-end-light" dir="ltr"> {toDate}</span>
                                                                                        {(LAN==="IR")&&<span className="font-12">({moment(toDate).format("jYYYY-jMM-jDD")})</span>}
                                                                                        <span className="inline-block margin-end-light">,</span>
                                                                                        <span className="inline-block margin-end-light"> {toTime} </span>

                                                                                        {(cancelItem.amount===0) && <span className="inline-block margin-end-light">
                                                                                            ({Dictionary.cancellationWillBeWithoutPenalty})
                                                                                        </span>}
                                                                                        {(cancelItem.amount>0) &&
                                                                                        <>

                                                                                            {
                                                                                            (LAN === "IR")?
                                                                                                <>
                                                                                                    <span className="inline-block margin-end-light">(جریمه کنسل کردن</span>
                                                                                                    <span className="inline-block margin-end-light"><Price currency={this.props.currencyCode}>{cancelItem.amount}</Price></span>
                                                                                                    <span className="inline-block">خواهد بود)</span>                                            
                                                                                                </>
                                                                                            :
                                                                                                <>
                                                                                                    <span className="inline-block margin-end-light">({Dictionary.thePenaltyForCancellationWillBe}</span>
                                                                                                    <span className="inline-block margin-end-light"><Price currency={this.props.currencyCode}>{cancelItem.amount}</Price></span>
                                                                                                    <span className="inline-block">)</span>                                            
                                                                                                </>                                            
                                                                                            }

                                                                                        </>
                                                                                        }

                                                                                    </li>;
                                                                                })}
                                                                            </ul>
                                                                            {(hotelInfo.accommodation.rooms[index].cancellations.length===0)&&<div>{Dictionary.irretrievable}</div>}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                    </div>

                                                </div>
                                                <h5 className="normal-title">
                                                    {/* <span className="counter-circle"></span> */}
                                                    {Dictionary.reserverContactInformation}
                                                    ({Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail})
                                                </h5>
                                                <div className="margin-bottom bg-white box-border card-padding">
                                                    <div className="inline-radio">
                                                        <div className="radio-item">
                                                            <input className="custom-radio" type="radio" name={"reserver.gender"} value={true} defaultChecked={true} />
                                                            <label className="radio-label"><span className="radio-mark"></span>{Dictionary.male}</label>
                                                        </div>
                                                        <div className="radio-item">
                                                            <input className="custom-radio" type="radio" name={"reserver.gender"} value={false} />
                                                            <label className="radio-label"><span className="radio-mark"></span>{Dictionary.female}</label>
                                                        </div>
                                                    </div>
                                                    <div className="float-row margin-bottom">
                                                        <div className="col-small-12 col-medium-6">

                                                            <div className="form-group relative required">
                                                                <label className="form-label">
                                                                    {Dictionary.firstName}
                                                                </label>
                                                                <input className="form-input placeholder-right en-input-text" data-validator={true} placeholder={Dictionary.firstName} name="reserver.firstName" type="text" defaultValue={(userInfoStatus) ? "" : userData.firstName} />
                                                                <Validation model={this.state} feild="ValidatorList" name='reserver.firstName' />
                                                            </div>

                                                        </div>
                                                        <div className="col-small-12 col-medium-6">
                                                            <div className="form-group relative required">
                                                                <label className="form-label">
                                                                    {Dictionary.lastName}
                                                                </label>
                                                                <input className="form-input placeholder-right en-input-text" data-validator={true} placeholder={Dictionary.lastName} name="reserver.lastName" type="text" defaultValue={(userInfoStatus) ? "" : userData.lastName} />
                                                                <Validation model={this.state} feild="ValidatorList" name='reserver.lastName' />
                                                            </div>
                                                        </div>
                                                        <div className="col-small-12 col-medium-6">
                                                            <div className="form-group relative required">
                                                                <label className="form-label">
                                                                    {Dictionary.email}
                                                                </label>
                                                                <input  className="form-input placeholder-right en-input-text" data-validator={true}  placeholder={Dictionary.enterEmail} name="reserver.email" type="email" />
                                                                <Validation model={this.state} feild="ValidatorList" name='reserver.email' />

                                                            </div>
                                                        </div>
                                                            <div className="col-small-12 col-medium-6">
                                                                <div className="form-group">
                                                                <label className="form-label">
                                                                    {Dictionary.phoneNumber}
                                                                </label>
                                                                <div className="select-input-pair-holder" dir="ltr">
                                                                    <select className="form-select" defaultValue={(LAN==="IR")?"0098":"0047"} name={'reserver.postalcode'} dir="ltr">
                                                                        {CountryPostalCode.map((item=><option value={item.code} key={item.code}>{item.code}</option>))}
                                                                    </select>
                                                                    <input className="form-input placeholder-right en-input-text" type={"text"} data-validator={true} placeholder={'9XXXXXXXXX'} name={'reserver.phoneNumber'}/>
                                                                </div>
                                                                <Validation model={this.state} feild="ValidatorList" name='reserver.phoneNumber' />
                                                                </div>
                                                            </div>
                                                    </div>

                                                    {(userInfoStatus) ? null : <input type="hidden" name="reserver.userID" value={userData.id} />}
                                                    <input type="hidden" name="reserver.username" defaultValue={this.state.username} />
                                                </div>                                                    
                                                <div className="card bg-white card-padding box-border">
                                                    <div className="float-row">
                                                        <div className="col-small-12 col-large-9">
                                                            <div className="form-group">
                                                                <h5 className="normal-title">
                                                                    {Dictionary.description}
                                                                </h5>
                                                                <p>
                                                                    {Dictionary.EnterYourRequestsHereAndWeWillShareThemWithTheHotel}.
                                                                    {Dictionary.SpecialRequestsAreNotGuaranteedButTheHotelWillDoEverythingToMeetYourNeeds}
                                                                </p>
                                                                <textarea className="form-textarea" name="specialRequest"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {hotelInfo.accommodation.rooms[0].remarks &&
                                                <div className="card box-border bg-white card-padding margin-top">
                                                    <div className=" margin-bottom" >
                                                        <h5 className="normal-title">
                                                            {Dictionary.remarks}:
                                                        </h5>
                                                        <div className="justified en-font" dir="ltr">
                                                            {renderHtml(hotelInfo.accommodation.rooms[0].remarks)}
                                                        </div>
                                                    </div>

                                                </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="small-side">
                                        <div className="side-item full-width">
                                            <div className="side-item-inner">

                                                <SummaryHotel model={hotelInfo} currencyCode={this.props.currencyCode} showButton={true} totalPrice={totalPrice} night={night} />
                                                <div className="box-border top-color-border orange margin-top bg-white">
                                                    <div className="colored">{Dictionary.pricesToRiseSoon}!</div>
                                                    <div className="font-12"> {Dictionary.bookNowToGetTheBestRateForYourCheckinDate}. </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                        </div>
                    </div>
                </div>
                </form>
        )
    }
}
const dispatchMapProp=(dispatch)=>({
    editMessage:(param)=>{dispatch(editMessage(param))},
    saveParameters:(param)=>{dispatch(saveParameters(param))},
    getPreReserveID:(param)=>{dispatch(getPreReserveID(param))},
    switchLogin:(mode)=>{dispatch(SwitchAccountWindow(mode))},
    check:()=>dispatch(toggleAccountWindow()),
    postReserve:(params)=>dispatch(postReserve(params)),


});
const stateMapProp=(state)=>({
    respond:state.VisualData.Responded,
    hotelInfo:state.HotelSearch.selectedHotel,
    preReserveKey:state.HotelSearch.parameters.preReserveKey,
    userData:state.UserData.userData,
    reserveId:state.HotelSearch.reserveId,
    currencyCode:state.UserData.Currency,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN

});
export default connect(stateMapProp,dispatchMapProp)(GetPassengers)