import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Price from '../Common/Price';
import axios from "axios";
import {withRouter} from 'react-router-dom';
import {Flight as FlightURL, Header, ServerAddress} from "../../Enum/Urls";
import {showError} from "../../Actions/VisualDataAction";
import FlightItinerary from './FlightItinery';
import Route from './Route';
import airplane from "../../image/airplane.svg";
import {Tabs,Collapse} from 'antd';
const {TabPane} =Tabs;
class FlightItem extends React.Component{
    static propTypes={
      model:PropTypes.any.isRequired
    };
    state={
        showDetails:false,
        showRules:false,
        rulesContent:null,
    };
    getRules=(key)=>{

        axios({
            method:"get",
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetRules}`,
            params:{key},
            headers:{...Header}
        }).then((response)=>{
            this.setState({
                rulesContent:response.data.result
            });
        }).catch((error)=>{
            try{
                this.props.showError({visibility:true,content:error.response.data.error.message})
            }catch (e) {
                this.props.showError({visibility:true,content:"Internal Error,Please refresh the page"})
            }
        })
    };
    toggleDetails=()=>{
        this.setState((prevstate)=>{
            return {
                showDetails:!prevstate.showDetails
            }
        })
    };
    toggleRules=(value)=>{
        if(value){
            this.getRules(this.props.model.key)
        }
        this.setState({
            showRules:value
        })
    };
    onClickTab=(tab)=>{
        if(tab==="4"){
            this.toggleRules(true);

        }

    };
    render() {
        const { Panel } = Collapse;
        const {Dictionary}=this.props;
        let baggageAvailable=true;
        let airportChange = false;
        this.props.model.airItinerary.forEach(item=>{
            item.flightSegments.forEach((segmentItem, index)=>{
                segmentItem.baggageInformation.forEach(baggageItem=>{
                    if(baggageItem.baggageAllowance<1){
                        baggageAvailable=false;
                    }
                })
                if (index < item.flightSegments.length - 1) {
                    if(!(segmentItem.arrivalAirport.locationCode === item.flightSegments[index + 1].departureAirport.locationCode)){
                        airportChange=true
                    }
                }
            })
        });
        return(
            <>
                <div className="result-item">
                    <div className="item-main">
                        <div className={"content"}>
                        {this.props.model.airItinerary.map((item,index)=>(
                            <Route key={index} model={item}/>
                        ))}
                        { airportChange && 
                        <div className="has-airport-change">
                            <span className="itours-icon error2-icon icon-small inline-block-middle margin-end-light"/>
                            <span className="purple inline-block-middle font-12 semi-bold">{Dictionary.StopForAirportChange}</span>
                        </div>
                        }
                        </div>
                        <div className="detail-btn-holder">
                            <button type="button" onClick={()=>this.toggleDetails()} className="link-style-btn flight-detail-btn">{Dictionary.flightDetails} <span className={(this.state.showDetails)?"rot":null} /> </button>
                            {baggageAvailable && <div className="included-baggage-mark"><span className="itours-icon check-green icon-xsmall" /><span> {Dictionary.BaggageIncluded} </span></div>}
                        </div>
                    </div>
                    <div className="item-footer">
                        <div className="flight-price-block">
                            <b dir="ltr"><Price currency={this.props.currency}>{this.props.model.totalPrice}</Price></b>
                        </div>
                        <button type="button"  className="button red-btn select-flight-btn has-arrow" onClick={()=>{this.props.history.push(`/flight/getpassenger?key=${this.props.model.key}`)}}>{Dictionary.book}</button>
                    </div>
                </div>
                {this.state.showDetails &&
                    <div className="flightDetails margin-bottom" dir="ltr">
                        <div className="flight-detail-main">
                            <Tabs defaultActiveKey={"1"} onTabClick={this.onClickTab}>
                                <TabPane tab={Dictionary.flightItinery} key={"1"}>
                                    <div className="detail-flight body-direction">
                                        <FlightItinerary model={this.props.model.airItinerary}/>
                                    </div>
                                </TabPane>
                                {
                                    baggageAvailable &&
                                    <TabPane tab={Dictionary.baggageInfo} key={"2"}>
                                        <div className="flight-baggage-detail body-direction">
                                            {
                                                this.props.model.airItinerary.map((item)=>{
                                                    return(
                                                        <div className="detail-flight-item "  key={item.key}>
                                                            <div className="detail-flight-content">
                                                                {
                                                                    item.flightSegments.map((item,index)=> {
                                                                        return (
                                                                            <div key={index}>
                                                                                <div className="baggegesInformation" dir="ltr">
                                                                                    <div className="text-left">
                                                                                        <div><b>{item.departureAirport.cityName}</b> - <small>{item.departureAirport.countryName}</small></div>
                                                                                    </div>
                                                                                    <div className={"airplaneIcon"}><img src={airplane} alt={"airplane"} title={"airplane"} /></div>
                                                                                    <div className="text-left">
                                                                                        <div><b>{item.arrivalAirport.cityName}</b> - <small>{item.arrivalAirport.countryName}</small></div>
                                                                                    </div>
                                                                                    <div>
                                                                                        {item.baggageInformation.map((item,index)=>
                                                                                            <div dir="ltr" key={index}>
                                                                                                {item.baggageAllowance} {item.unitType} <span> {Dictionary.for}  </span>
                                                                                                {(item.passengerType==="ADT")&&  <span>{Dictionary.adult}</span>}
                                                                                                {(item.passengerType==="CHD")&&<span>{Dictionary.child}</span>} 
                                                                                                {(item.passengerType==="INF")&&<span>{Dictionary.infant}</span>}
                                                                                            </div>)
                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    })
                                                                }


                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </TabPane>
                                }
                                <TabPane tab={Dictionary.flightFare} key={"3"}>
                                    <div className={"fareContainer body-direction"}>
                                        <div className="title">{Dictionary.fareTotal}</div>
                                        <div className="baseFare">
                                            <strong>{Dictionary.baseFare}: </strong><Price currency={this.props.currency}>{this.props.model.priceInfo.baseFare}</Price>
                                        </div>
                                        {this.props.model.priceInfo.fess &&
                                        <div className="fees">
                                            <strong>{Dictionary.fees}: </strong><Price currency={this.props.currency}>{this.props.model.priceInfo.fess}</Price>
                                        </div>
                                        }
                                        <div className="taxes">
                                            <strong>{Dictionary.taxes}: </strong><Price currency={this.props.currency}>{this.props.model.priceInfo.taxes[0].amount}</Price>
                                        </div>
                                        <hr/>
                                        <div className="totalPrice">
                                            <strong>{Dictionary.totalPrice}: </strong><Price currency={this.props.currency}>{this.props.model.priceInfo.totalFare}</Price>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab={Dictionary.flightRules} key={"4"}>
                                    <div className="rules-container">
                                        {this.state.rulesContent ?
                                            <>
                                                {(this.state.rulesContent.flightRules.length === 0) ?
                                                    <div className="body-direction"> 
                                                        {Dictionary.informationNotFound}
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            this.state.rulesContent.flightRules.map((item,index)=>{
                                                                return(
                                                                    <div className="rules-item en-font" key={index}>
                                                                        <div className="rule-title">
                                                                            FareBase: {item.fareBase}
                                                                        </div>
                                                                        <Collapse>                                                               
                                                                            {item.flightRule.map((itemIn,indexIn)=>{
                                                                                return(
                                                                                    <Panel header={itemIn.ruleTitle} key={indexIn}>
                                                                                        <p>
                                                                                            {itemIn.ruleDescription}
                                                                                        </p>
                                                                                    </Panel>
                                                                                )
                                                                            })}
                                                                        </Collapse>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                            </>
                                            :
                                            <div className="body-direction">
                                                {Dictionary.loadingData}
                                                <span className="dots-loading">
                                                        <span className="dot">.</span>
                                                        <span className="dot">.</span>
                                                        <span className="dot">.</span>
                                                    </span>
                                            </div>
                                        }
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                        <div className="flight-detail-footer body-direction">
                            <button  onClick={()=>this.toggleDetails()}  className="button border-btn">{Dictionary.hideDetails}</button>
                            <button type="button"  className="button red-btn select-flight-btn has-arrow" onClick={()=>{this.props.history.push(`/flight/getpassenger?key=${this.props.model.key}`)}}>{Dictionary.book}</button>
                        </div>
                    </div>
                }
            </>
        )
}}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN,
    currency:state.UserData.Currency
});
const mapDispatchToProps=(dispatch)=>({
    showError:(param)=>dispatch(showError(param))
});
const routerBind=withRouter(FlightItem);
export default connect(mapStateToProps,mapDispatchToProps)(routerBind);