import {getCookie,setCookie} from '../Enum/Common';
import dotenv from 'dotenv';
dotenv.config();
//read from ENV files
const readENV=()=>{
    switch(process.env.REACT_APP_SERVER_CULTURE){
        case 'NO':
            return "NOK";
        case 'DK':
            return "DKK";
        case 'SE':
            return "SEK";
        case 'IR':
            return "IRR";
        case 'US':
            return "USD";
        default:
            return "EUR";
    }
};
class Currency{
    getCurrency=()=>{
        if(getCookie("itoursCurrency")){
            return getCookie("itoursCurrency");
        }else{
            return readENV();
        }
    };
    setCurrency=(cvalue)=>{
        setCookie("itoursCurrency",cvalue,2000);
    };
    getNationality=()=>{
        return process.env.REACT_APP_SERVER_CULTURE
    }
}
export default  new Currency();