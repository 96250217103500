import React from 'react';
import queryString from "query-string";
import {replaceAll} from '../../Enum/Common';
import {showError} from '../../Actions/VisualDataAction';
import {confrimEmail} from '../../Actions/UserAction';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
class Confrim extends React.Component{
    state={
        code:null,
        userId:null,
        varficationLoading:true,
        message:null,
        varficationSuccess:false
    };
    componentDidMount() {
        window.scrollTo(0,0);
        let parameters=queryString.parse(this.props.hotelNoImage.search);
        this.setState({
            code:replaceAll(parameters.code,' ','+') ,
            userId:parameters.userId
        },()=>{
            this.props.confrimEmail({code:this.state.code,userId:this.state.userId});
        });

    }
    render(){
        const {Dictionary} = this.props;
        let {error,respond}=this.props;
        return(
            <div>
                {(error.visibility)?
                    <div className="showMessage">
                        <div className="content">
                            "{Dictionary.EmailValidationFailedPleaseTryAgain} پ"
                        </div>
                        <div className="buttonContainer">
                            <Link to={"/"}>{Dictionary.ok}</Link>
                        </div>
                    </div>
                    :
                    (respond)?
                        <div className="showMessage">
                            <div className="content">
                                "{Dictionary.SuccessfulEmailValidation}"
                            </div>
                            <div className="buttonContainer">
                                <Link to={"/"}>{Dictionary.ok}</Link>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="MessageLoading">
                                <div className="text">                                    
                                    {Dictionary.yourRequestIsProcessing},
                                    {Dictionary.pleaseWait}
                                    ...
                                </div>
                            </div>
                        </div>
                    // (this.state.varficationLoading)?
                    //     <div className="MessageLoading">
                    //         <div className="text">Your request is processing,please wait...</div>
                    //
                    //     </div>
                    //     :
                    //     <div className={(this.state.varficationSuccess)?'MessageShow Success':'MessageShow Fail'} >
                    //         <div className="text">
                    //             <div className="title">{(this.state.varficationSuccess)? "Confrimation Successful" : "Confrimation failed"}</div>
                    //             <div className="content">{this.state.message}</div>
                    //             <Link to={"/"}>Ok</Link>
                    //         </div>
                    //     </div>
                }
            </div>


        )
    }
}
const mapStateToProps=(state)=>({
    error:state.VisualData.error,
    respond:state.VisualData.Responded,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
})
const mapDispatchToProps=(dispatch)=>({
    hideError:(param)=>{dispatch(showError(param))},
    confrimEmail:(param)=>{dispatch(confrimEmail(param))}
})
export default connect(mapStateToProps,mapDispatchToProps)(Confrim)