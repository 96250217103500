import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types'
export default class Price extends React.PureComponent{
    static propTypes={
        currency:PropTypes.string.isRequired
    }
    render() {
        // let lessPointCurrency=['NOK','SEK','DKK','USD'];
        // let output=new Intl.NumberFormat('en',{style:'currency',currency:this.props.currency}).format(this.props.children);
        // let chararcter = new RegExp(String.fromCharCode(160), "g");
        // output=output.split(chararcter);
        // ;
        // if(lessPointCurrency.indexOf(this.props.currency)!==-1){
        //     output[1]=output[1].split(".")[0];
        // }
        if(this.props.children)
        {
            return(
                <>
                   <span className="price"><span className="value">{numeral(this.props.children).format('0,0')}</span> <span className="currency">{this.props.currency}</span></span>
                </>
            )
        }else{
            return null;
        }

    }
}