import React from 'react';
import {connect} from 'react-redux';
import {CountryPostalCode} from '../../Enum/Models'
class PaymentList extends React.Component{
    state={
        showSegment:null,
        formStatus:false,
        formFeilds:[],
        postalCode:"0047"
    };
    handlePaymentChoice(gatewaysItem){
        let form=[];
        if(gatewaysItem.form.elements.length===0){
            this.props.selectedBankthis(gatewaysItem.id);
        }else{
            gatewaysItem.form.elements.forEach(item=>{
                form[item.key]=this.state.formFeilds[item.key];
            });
            this.setState({
                formFeilds:form,
                formStatus:true
            },()=>{
                let formStatus=true;
                for(let itemFeild in this.state.formFeilds){
                    if(!this.state.formFeilds[itemFeild]){
                        formStatus=false;
                    }
                }
                if(formStatus){
                    let formData=this.state.formFeilds;
                    formData["PhoneNumber"]=this.state.postalCode+formData["PhoneNumber"];
                    this.props.selectedBankthis(gatewaysItem.id,this.state.formFeilds);
                }
            })
        }

    }
    bindData(filedName,value){
        let formData=this.state.formFeilds;
        formData[filedName]=value;
        this.setState({
            formFeilds:formData
        })
    }
    componentDidMount(){
        this.setState({
            showSegment:this.props.banks[0].keyword
        })
    }
    render() {
        const {Dictionary} = this.props;
        return(
            <div className="card-padding bg-white box-border">
                <div className="payment-radio form-group inline-radio">
                {
                    this.props.banks.map((item,key) => {
                        return (
                            <div className="radio-item" key={key}>
                                <input className="custom-radio"  type="radio" name="triptype" 
                                        checked={this.state.showSegment === item.keyword} 
                                        onClick={() => {this.setState({showSegment: item.keyword})}} 
                                />
                                <label className="radio-label">
                                    <span className="radio-mark" />
                                        {item.title}
                                </label>
                            </div>
                        )
                    })
                }
                </div>
                {
                    this.props.banks.map((item,key) => {
                        return (
                            <div className={"payment-method-item"} key={key}>
                                <div className={"paymentItem"}>
                                    <div
                                        className={(this.state.showSegment === item.keyword) ? "paymentDetails" : "hide paymentDetails"}>

                                        <div>
                                            <strong>{item.title}</strong>
                                            <div>{item.name}</div>
                                            <p>{item.description}</p>
                                        </div>
                                        <div className="box-border card-padding">
                                            {item.gateways.map(gatewaysItem => {
                                                return (
                                                    <div className={(item.keyword==="ResursBankPartPayment")?"resurs-form":"gateway-item"} key={gatewaysItem.name}>
                                                        <b>{gatewaysItem.name}</b>
                                                        <form>
                                                            {gatewaysItem.form.elements.map((formItem,key) => {
                                                                let fieldLabel;
                                                                switch(formItem.key) {
                                                                    case "Firstname":
                                                                        fieldLabel = Dictionary.firstName;
                                                                        break;
                                                                    case "Lastname":
                                                                        fieldLabel = Dictionary.lastName;
                                                                        break;
                                                                    case "Fullname":
                                                                        fieldLabel = Dictionary.fullName;
                                                                        break;
                                                                    case "Email":
                                                                        fieldLabel = Dictionary.email;
                                                                        break;
                                                                    case "PhoneNumber":
                                                                        fieldLabel = Dictionary.phoneNumber;
                                                                        break;
                                                                    case "CountryCode":
                                                                        fieldLabel = Dictionary.CountryCode;
                                                                        break;
                                                                    case "PostalArea":
                                                                        fieldLabel = Dictionary.PostalArea;
                                                                        break;
                                                                    case "PostalCode":
                                                                        fieldLabel = Dictionary.PostalCode;
                                                                        break;
                                                                    case "Address":
                                                                        fieldLabel = Dictionary.Address;
                                                                        break;
                                                                    default:
                                                                        fieldLabel = formItem.key;
                                                                    }
                                                                if (formItem.type !== "select") {
                                                                    return (<div key={key} className="form-group">
                                                                        <label htmlFor={formItem.key} className="form-label">{fieldLabel}</label>
                                                                        {
                                                                            formItem.key==="PhoneNumber" &&
                                                                            <select className="form-select country-code" onChange={(e)=>this.setState({postalCode:e.target.value})} value={this.state.postalCode}>
                                                                                {CountryPostalCode.map((item,index)=><option key={index}  value={item.value}>{item.code}</option>)}
                                                                            </select>
                                                                        }
                                                                        <input id={formItem.key} className="form-input"
                                                                                    type={formItem.type}
                                                                                    onChange={(e)=>this.bindData(formItem.key,e.target.value)}
                                                                                    name={formItem.key} defaultValue={formItem.defaultValue}/>
                                                                                    {(this.state.formStatus&&!this.state.formFeilds[formItem.key])&&<div style={{color:'red'}}>{Dictionary.thisFieldIsRequired}</div>}
                                                                    </div>)
                                                                } else {
                                                                    return (
                                                                        <div key={key} className="form-group">
                                                                            <label
                                                                                htmlFor={formItem.key} className="form-label">{fieldLabel}</label>
                                                                            <select className="form-select" onChange={(e)=>this.bindData(formItem.key,e.target.value)} id={formItem.key} name={formItem.key}
                                                                                    defaultValue={formItem.defaultValue}>
                                                                                <option value={null}></option>
                                                                                {formItem.values.map((optItem,optIndex) => <option key={optIndex} value={optItem.value}>{optItem.label}</option>)}
                                                                            </select>
                                                                            {(this.state.formStatus&&!this.state.formFeilds[formItem.key])&&<div style={{color:'red'}}>{Dictionary.thisFieldIsRequired}</div>}

                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                        </form>
                                                        <button className="button green-btn" type="button" onClick={this.handlePaymentChoice.bind(this,gatewaysItem)}>{(item.keyword==="ResursBank")?Dictionary.send:Dictionary.select}</button>

                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )

    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(PaymentList);