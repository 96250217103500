import axios from "axios";
import {Header, Hotel as HotelURL, ServerAddress,Reserve} from "../Enum/Urls";
import {Filter} from "../Enum/Models";
import {showError,editMessage} from "./VisualDataAction";

import _ from "lodash";
import {getFlightReserveAsync} from '../Actions/FlightAction';
export const searchHotelAsync=(data)=>({type:'HotelSearch',result:data});
export const searchHotelFilterAsync=(data)=>({type:'HotelFilterAsync',result:data});
export const selectedHotel=(data)=>({type:'HotelDetails',result:data});
export const getHotelDetailsAsync=(data)=>({type:'HotelDetails',result: data});
export const setReserveID=(data)=>({type:'SetReserveID',reserveId: data});
export const filterSelect=(data)=>{
    return {type:'FilterSelect',input:data}
};
export const applyFilter=()=>({type:'ApplyFilter'});
export const getHotelReserveAsync=(data)=>{
    let voucherList = [];
    if(data) {
        data.forEach((item) => {
            const {departureDate, currency, firstName, gender, lastName, origin, salePrice, id, status} = item;
            let date = new Date(departureDate);
            let itemVoucher = {
                date: date.toLocaleDateString(),
                currency: currency.code,
                firstName,
                gender,
                lastName,
                hotel: origin.name,
                salePrice,
                id,
                status
            };
            voucherList.push(itemVoucher);
        })
    }
    return {type:'editVoucher',data:voucherList}
};

export const fillHotelSearchURL=(url)=>{
    return {type:'fillHotelSearchURL',url}
};
export const sortHotelSearch=(input)=>({type:'sortHotelSearch',input:{...input}});
export const getReserveByIDAsync=(data)=>({type:'setVoucherDetails',result:{...data}});
export const setCancelationRoom=(data)=>({type:'setCancelationRoom',result:{...data}});

//Sync methods
export const searchHotel=(params)=>{

    return (dispatch)=>{
        axios({
            method:'get',
            url:`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetAccomadtion}`,
            params:params,
            headers:{...Header}
        })
            .then((response)=>{
                dispatch(searchHotelAsync(response.data.result));
                var filter=new Filter();
                //calcaute filter values
                if(response.data.result.hotels!==null){
                    response.data.result.hotels.forEach((hotelItem)=>{
                        //get all rate
                        if(filter.rating.value.indexOf(hotelItem.rating)===-1){
                            filter.rating.value.push(hotelItem.rating)
                        }
                        //get all facilities
                        if(hotelItem.features!==null){
                            hotelItem.features.forEach((featureItem)=>{
                                featureItem.value=null;
                                if(!(_.some(filter.features.value,featureItem))){
                                    filter.features.value.push(featureItem)
                                }

                            })
                        }
                        //get all Board
                        if(hotelItem.boards!==null){
                            hotelItem.boards.forEach((boardItem)=>{
                                if(!(_.some(filter.boards.value,boardItem))){
                                    filter.boards.value.push(boardItem)
                                }
                            })
                        }
                        //get all location
                        if(hotelItem.regions!==null){
                            hotelItem.regions.forEach(regionsitem=>{
                                if(!(_.some(filter.regions.value,regionsitem))){
                                    filter.regions.value.push(regionsitem)
                                }
                            })
                        }

                    });
                    filter.rating.value.sort();
                }

                dispatch(searchHotelFilterAsync(filter));
                dispatch(editMessage(true));

            })
            .catch((error)=>{
                try{
                    dispatch(showError({visibility:true,content:error.response.data.error.message}))
                }catch (e) {
                    dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
                }
            });
    }
};
export const saveParameters=(params)=>({type:'SaveParameter',params:params});
export const getHotelDetails=(params)=>{
    return (dispatch)=>{
        axios({
            method:'get',
            url:`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetAccomadtionDetails}`,
            params:params,
            headers:{...Header}
        })
            .then((response)=>{
                dispatch(getHotelDetailsAsync(response.data.result));
                dispatch(editMessage(true));
            })
            .catch((error)=>{
                try{
                    dispatch(showError({visibility:true,content:error.response.data.error.message}))
                }catch (e) {
                    dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
                }
            });
    }
};
export const getPreReserve=(params)=>{
    return (dispatch)=>{
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetPreReserve}`,
            data:{...params},
            headers:{...Header}
        }).then((response)=>{
            dispatch(saveParameters(response.data.result));
            dispatch(editMessage(true));
        })
            .catch((error)=>{
                try{
                    dispatch(showError({visibility:true,content:error.response.data.error.message}))
                }catch (e) {
                    dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
                }
        })
    }
};
export const getPreReserveID=(params)=>{
    return(dispatch)=>{
        axios({
            method:'get',
            url:`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetPreReserveID}`,
            params:params,
            headers:{...Header}
        })
            .then((response)=>{
                dispatch(getHotelDetailsAsync(response.data.result));
                dispatch(editMessage(true));

            })
            .catch((error)=>{
                try{
                    dispatch(showError({visibility:true,content:error.response.data.error.message}))
                }catch (e) {
                    dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
                }
            });
    }
};
export const getReserveByID=(params)=>{
    return(dispatch)=>{
        axios({
            method:'get',
            url:`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetReserveByID}`,
            params:params,
            headers:{...Header}
        })
        .then((response)=>{
            dispatch(selectedHotel(response.data.result));
            dispatch(editMessage(true));
        })
        .catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
    }
};
export const postReserve=(params)=>{
    return (dispatch)=>{
        //handle header
        let token=localStorage.getItem("userToken");
        let HeaderAthu=null;
        if(token){
            HeaderAthu={Authorization:`Bearer ${token}`,...Header};
        }else{
            HeaderAthu={...Header};
        }
        //
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.PostReserver}`,
            data:{...params},
            headers:HeaderAthu
        }).then((response)=>{
            dispatch(setReserveID(response.data.result.reserveId));
            dispatch(editMessage(true));
        })
        .catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    }
};
export const getHotelReserve=(type)=>{
    return (dispatch)=>{
        //handle header
        let token=localStorage.getItem("userToken");
        let HeaderAthu=null;
        if(token){
            HeaderAthu={Authorization:`Bearer ${token}`,...Header};
        }else{
            HeaderAthu={...Header};
        }
        //
        axios({
            method:'get',
            url:`${ServerAddress.Type}${ServerAddress.Hotel}${Reserve.hotel}`,
            params:{ReserveTypes:type,PageNumber:1,PageSize:100000},
            headers:HeaderAthu
        }).then((response)=>{
            if(type==="FlightGlobal"){
                dispatch(getFlightReserveAsync(response.data.result));
                dispatch(editMessage(true));
            }else{
                dispatch(getHotelReserveAsync(response.data.result));
                dispatch(editMessage(true));
            }

        })
        .catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    }
};
export const getHotelVoucherDetails=(params)=>{
    return (dispatch)=>{
        axios({
            method:'get',
            url:`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetVoucherDetails}`,
            params,
            headers:{...Header}
        })
        .then((response)=>{
            dispatch(getReserveByIDAsync(response.data.result))

        })
        .catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
    }
};
export const bookhotel=(params)=>{
    return (dispatch)=>{
        //handle header
        let token=localStorage.getItem("userToken");
        let HeaderAthu=null;
        if(token){
            HeaderAthu={Authorization:`Bearer ${token}`,...Header};
        }else{
            HeaderAthu={...Header};
        }
        //
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.BookHotel}`,
            data:{...params},
            headers:HeaderAthu
        }).then((responsive)=>{
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });

    }
};
export const getRoom=(params)=>{
    return (dispatch)=>{
        //handle header
        let token=localStorage.getItem("userToken");
        let HeaderAthu=null;
        if(token){
            HeaderAthu={Authorization:`Bearer ${token}`,...Header};
        }else{
            HeaderAthu={...Header};
        }
        //
        axios({
            method:'get',
            url:`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetRoom}`,
            params,
            headers:HeaderAthu
        }).then((responsive)=>{
            let result={cancelationInfo:responsive.data.result.result.cancellations,bookingID:params.get("BookingToken")};
            dispatch(setCancelationRoom(result));
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });

    }
};






