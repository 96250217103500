//import libraries
import React from 'react';
import {connect} from 'react-redux';
import {Link,withRouter} from "react-router-dom";
import {setCurrency} from "../../Actions/UserAction";
//import redux actions
import {toggleAccountWindow} from '../../Actions/VisualDataAction';
import {SwitchAccountWindow} from '../../Actions/VisualDataAction';
//load UI components
import ProfileBallon from '../Admin/ProfileBallon';
//load Assets files

import logo from '../../image/logo.svg';

//responsive menu
class Menu extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            menu_class: ''
        }
    }


    setToggleTopMenuClass = () => {
        if (this.state.menu_class === '') {
            this.setState({
                menu_class: ' expanded '
            })
        } else {
            this.setState({
                menu_class: ''
            })
        }
    }



    render(){
        const {Dictionary} = this.props;
        return(
            <div>
                <button type="button" className="menu-toggle-btn" onClick={this.setToggleTopMenuClass}>
                <span className="menu-btn-bar icon-top-bar"></span><span className="menu-btn-bar icon-middle-bar"></span><span className="menu-btn-bar icon-bottom-bar"></span>
                </button>
                <div className="main-menu-holder">
                    <div className={this.state.menu_class + " main-menu-holder "}>
                        <button type="button" className="opened menu-toggle-btn" onClick={this.setToggleTopMenuClass}>
                            <span className="menu-btn-bar icon-top-bar"></span><span className="menu-btn-bar icon-middle-bar"></span><span className="menu-btn-bar icon-bottom-bar"></span>
                        </button>               
                        <ul className="main-menu">
                            <li className={(this.props.activeLink==="" || this.props.activeLink==="flight")?"active menu-item":"menu-item"}>
                                <Link to={"/flight"} className="menu-link" onClick={this.setToggleTopMenuClass} >
                                    {Dictionary.flight}
                                    <div className="hidden-medium hidden-large"><span className="itours-icon top-plane-gray" /></div>
                                </Link>
                            </li>
                            <li className={(this.props.activeLink==="hotel")?"active menu-item":"menu-item"}>
                                <Link to={"/hotel"} className="menu-link" onClick={this.setToggleTopMenuClass} >
                                    {Dictionary.hotel}
                                    <div className="hidden-medium hidden-large"><span className="itours-icon hotel-gray" /></div>
                                </Link>
                            </li>
                            <li className={(this.props.activeLink==="terms")?"active menu-item":"menu-item"}>
                                <Link to={"/terms"} className="menu-link" onClick={this.setToggleTopMenuClass} >
                                    {Dictionary.termsAndConditions}
                                    <div className="hidden-medium hidden-large"><span className="itours-icon terms" /></div>
                                </Link>
                            </li>
                            <li className={(this.props.activeLink==="about")?"active menu-item":"menu-item"}>
                                <Link to={"/about"} className="menu-link" onClick={this.setToggleTopMenuClass} >
                                    {Dictionary.aboutUs}
                                    <div className="hidden-medium hidden-large"><span className="itours-icon about-us" /></div>
                                </Link>
                            </li>
                            <li className={(this.props.activeLink==="contact")?"active menu-item":"menu-item"}>
                                <Link to={"/contact"} className="menu-link" onClick={this.setToggleTopMenuClass} >
                                    {Dictionary.contactUs}
                                    <div className="hidden-medium hidden-large"><span className="itours-icon contact-us" /></div>
                                </Link>
                            </li>
                        </ul>                
                    </div>
                </div>                
            </div>
        );
    }
}

//declaration Class Component
class Header extends React.Component{
    state={
        routerInfo:null,
        disableLink:false,
        trackEmail:null,
        trackReserveID:null,
        showTrack:false
    };
    componentDidMount() {
        this.setState({
            routerInfo:this.props.location.pathname.split("/")[1]
        })
        let path=this.props.history.location.pathname.split("/");
        if(path[1]==="payment"){
            this.setState({
                disableLink:true
            })
        }else{
            this.setState({
                disableLink:false
            })
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location!==prevProps.location){
            this.setState({
                routerInfo:this.props.location.pathname.split("/")[1]
            })
        }
        if(this.props.currency!==prevProps.currency){
            window.location.reload();
        }
        if(this.props!==prevProps){
            let path=this.props.history.location.pathname.split("/");
            if(path[1]==="payment"){
                this.setState({
                    disableLink:true
                })
            }else{
                this.setState({
                    disableLink:false
                })
            }
        }


    }
    changeCurrency=(currency)=>{
        this.props.getCurrency(currency);
    };
    render(){
        const culture=window.location.origin;
        const {Dictionary,LAN} = this.props;
        const {SwitchAccountWindow,Verification,toggleAccountWindow,UserData} = this.props;
        return(
            <div className="homeNavbar navbar box-shadow">
                <div className="page-container">
                    <div position="static" color="transparent">
                        <div className="relative">
                            <div className="account-links">
                                {(!Verification)?
                                    <div className="account">
                                        <div className="link inline-block"  onClick={()=>{SwitchAccountWindow(false);toggleAccountWindow()}}>{Dictionary.login}</div>
                                        <div className="link margin-start inline-block"  onClick={()=>{SwitchAccountWindow(true);toggleAccountWindow()}}> {Dictionary.registration}</div>
                                    </div>
                                    :
                                    <ProfileBallon model={UserData} />
                                }
                            </div>
                            <div className="lang-currency">
                                <div className="link margin-end inline-block">
                                    <select disabled={this.state.disableLink} defaultValue={this.props.userData.Currency} className="itours-select" onChange={(e)=>{this.props.setCurrency(e.target.value)}}>
                                        {LAN==="IR"&&<option value="IRR"> IRR </option>}
                                        <option value="DKK"> DKK </option>
                                        <option value="SEK"> SEK </option>
                                        <option value="EUR">EUR </option>
                                        <option value="USD"> USD </option>
                                        <option value="NOK"> NOK </option>
                                    </select>
                                </div>
                                <div className="link margin-end inline-block">
                                    <select className="itours-select" defaultValue={culture} onChange={e=>{debugger;window.location=e.target.value}}>
                                        {culture!=="https://itours.no" && <option value={"https://itours.no"}>Norsk</option>}
                                        {culture!=="https://itours.se" && <option value={"https://itours.se"}>Sverige</option>}
                                        {/*{culture!=="https://itours.ir" && <option value={"https://itours.ir"}>فارسی</option>}*/}
                                        {culture!=="https://itours.dk" && <option value={"https://itours.dk"}>Dansk</option>}
                                    </select>
                                </div>
                            </div>
                            <div className="logo-holder clearfix">
                                <Link className="logo" to={"/"}><img src={logo} alt="itours"/></Link>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix">
                        <div  className="pull-end tracking-order-holder">
                                <button type="button" className="button blue-btn small-btn" onClick={()=>{this.setState((prevState)=>({showTrack:!prevState.showTrack}))}}>{Dictionary.trackOrder}</button>
                                {this.state.showTrack &&
                                <div className={"tracking"}>
                                    <div className={"trackingContainer"}>
                                        <div className={"username form-group"}>
                                            <label htmlFor="trackingUsername">*{Dictionary.email}</label>
                                            <input className="form-input placeholder-right" id={"trackingUsername"} value={this.state.trackEmail}
                                                onChange={(e) => {
                                                    this.setState({trackEmail: e.target.value})
                                                }} type={"text"} placeholder={Dictionary.enterYourEmail}/>
                                        </div>
                                        <div className={"reserveNumber form-group"}>
                                            <label htmlFor="trackingUsername">*{Dictionary.reservationCode} </label>
                                            <input className="form-input placeholder-right" id={"trackingUsername"} value={this.state.trackReserveID}
                                                onChange={(e) => {
                                                    this.setState({trackReserveID: e.target.value})
                                                }} type={"text"}
                                                placeholder={Dictionary.enterTheReservationCodeYouReceived} />
                                        </div>
                                        <div className="clearfix">
                                            <button type={"button"} className="button blue-btn"
                                                disabled={!(this.state.trackEmail && this.state.trackReserveID)}
                                                onClick={() => {
                                                    this.setState({showTrack:false});
                                                    this.props.history.push(`/profile/details?userName=${this.state.trackEmail}&reserveId=${this.state.trackReserveID}`)
                                                }}>
                                                    {Dictionary.send}
                                                </button>
                                            <button type={"button"} className="button red-btn pull-end"
                                                onClick={() => {
                                                    this.setState({showTrack:false});
                                                }}>
                                                    {Dictionary.close}
                                                </button>
                                        </div>
                                    </div>
                                </div>
                                }
                        </div>
                        <div className="pull-start">
                            <Menu Dictionary={Dictionary} LAN={LAN} activeLink={this.state.routerInfo}/>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
//map state to Props
const MapStateToProps=(state)=>({
    Verification:state.VisualData.Verification,
    userData:state.UserData,
    HomeStyle:state.VisualData.HomeStyle,
    currency:state.UserData.Currency,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
//map action to Props
const MapAction=(dispatch)=>({
    toggleAccountWindow:()=>dispatch(toggleAccountWindow()),
    SwitchAccountWindow:(mode)=>{dispatch(SwitchAccountWindow(mode))},
    setCurrency:(value)=>{dispatch(setCurrency(value))}
});
const routerInfo=withRouter(Header);
export default connect(MapStateToProps,MapAction)(routerInfo)