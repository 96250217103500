import axios from "axios";
import {showError,editMessage} from "./VisualDataAction";
import {Header,Flight as FlightURL,ServerAddress} from "../Enum/Urls";

//async method
export const setError=(data)=>({type:'FLIGHTERROR',result:data})
export const searchFlightAsync=(data)=>({type:'FLIGHTSEARCH',result:data});
export const ApplyFilterFlight=()=>({type:'ApplyFilterFlight'});
export const filterSelect=(data)=>{
    return {type:'FlightFilterSelect',input:data}
};
export const sortFlightSearch=(input)=>({type:'sortFlightSearch',input:{...input}});
export const setFlightSelected=(data)=>({
    type:'setFlightData',
    result: data
});
export const setReserveID=(id)=>({type:'setReserveID',id});
//sync method
export const searchFlight=(params)=>{
  return (dispatch)=>{
      axios({
          method:"post",
          url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetFlights}`,
          data:{...params},
          headers:{...Header}
      }).then((response)=>{

          dispatch(searchFlightAsync(response.data.result));
          dispatch(editMessage(true));


      }).catch((error)=>{
          try{
              dispatch(showError({visibility:true,content:error.response.data.error.message}))
          }catch (e) {
              dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
          }
      })
  }
};
export const validateFlight=(key)=>{
    return(dispatch)=>{
        axios({
            method:"post",
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.ValidateFlight}?key=${key.key}`,
            headers:{...Header}
        }).then((response)=>{
            dispatch(setFlightSelected(response.data.result));
            dispatch(editMessage(true));
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    }
};
export const getPassengerFlight=(params)=>{
    return(dispatch)=>{
        axios({
            method:"post",
            data:{...params},
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetPNR}`,
            headers:{...Header}
        }).then((response)=>{
            if(response.data.success){
                if(!response.data.result){
                    dispatch(showError({visibility:true,content:"Flight selection failed,please select another one"}))
                }else{
                    dispatch(setReserveID(response.data.result));

                }
            }
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    }
};
export const getReserveByIdFlight=(params)=>{
    return(dispatch)=>{
        axios({
            method:"get",
            params,
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetReserveByID}`,
            headers:{...Header}
        })
        .then((response)=>{
            dispatch(setFlightSelected(response.data.result))
            dispatch(editMessage(true));
        })
        .catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
    }
};
export const getDirectTicketByID=(params)=>{
    return(dispatch)=>{
        axios({
            method:"get",
            params,
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetDirectTicketByID}`,
            headers:{...Header}
        })
        .then((response)=>{
            dispatch(setError(response.data.result))
        })
        .catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
    }

};
export const getFlightReserveAsync=(data)=>({type:'FLIGHTRESERVE',result:data});
