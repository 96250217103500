import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';
import {connect} from 'react-redux';
import '../../scss/gallery.scss';

class Gallery extends React.Component{
    static propTypes={
           images:PropTypes.any.isRequired
    };
    state={
        currentImage:0,
        lightboxIsOpen:false
    };
    openLightbox=(param)=> {
        this.setState({
            currentImage: param,
            lightboxIsOpen: true,
        });
    };
    closeLightbox=()=> {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    };
    gotoPrevious=()=> {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    };
    gotoNext=()=> {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    };
    render() {
        const {Dictionary}=this.props;
        return(
            <>
            <div className="hotel-gallery">
                <div className="gallery-row">
                    {((this.props.images[0]==null))?
                        null
                        :
                        <div className="large-item">
                            <div className="image" style={(this.props.images[0]==null)?null:{backgroundImage:`url(${this.props.images[0].src})`}} onClick={this.openLightbox.bind(this,0)} />
                        </div>
                    }
                    {((this.props.images[1]==null))?
                        null
                        :  
                        <div className="gallery-column">
                            <div>
                                <div className="image" style={(this.props.images[1]==null)?null:{backgroundImage:`url(${this.props.images[1].src})`}} onClick={this.openLightbox.bind(this,1)} />                                                            
                            </div>
                            {((this.props.images[2]==null))?
                                null
                                :
                                <div className="">
                                    <div className="image" style={(this.props.images[2]==null)?null:{backgroundImage:`url(${this.props.images[2].src})`}} onClick={this.openLightbox.bind(this,2)} />
                                </div>             
                            }   
                            {((this.props.images[3]==null))?
                                null
                                :
                                <div className="">
                                    <div className="image" style={(this.props.images[3]==null)?null:{backgroundImage:`url(${this.props.images[3].src})`}} onClick={this.openLightbox.bind(this,3)} />
                                </div>             
                            }  
                            {((this.props.images[4]==null))?
                                null
                                :
                                <div className="">
                                    <div className="image" style={(this.props.images[4]==null)?null:{backgroundImage:`url(${this.props.images[4].src})`}} onClick={this.openLightbox.bind(this,4)} />
                                </div>             
                            }                                                                                   

                        </div>               
                    }
                    {((this.props.images[5]==null))?
                        null
                        :
                        <div className="gallery-column">
                            <div className="">
                                <div className="image" style={(this.props.images[5]==null)?null:{backgroundImage:`url(${this.props.images[5].src})`}} onClick={this.openLightbox.bind(this,5)} />
                            </div> 
                            {((this.props.images[6]==null))?
                                null
                                :
                                <div className="">
                                    <div className="image" style={(this.props.images[6]==null)?null:{backgroundImage:`url(${this.props.images[6].src})`}} onClick={this.openLightbox.bind(this,6)} />
                                </div>    
                            } 
                            {((this.props.images[7]==null))?
                                null
                                :
                                <div>
                                    <div className="image" style={(this.props.images[7]==null)?null:{backgroundImage:`url(${this.props.images[7].src})`}} onClick={this.openLightbox.bind(this,7)}/>
                                </div>             
                            }  
                            {((this.props.images[8]==null))?
                                null
                                :
                                <div className="">
                                    <div className="image" style={(this.props.images[8]==null)?null:{backgroundImage:`url(${this.props.images[8].src})`}}>
                                        <div className="hover" onClick={this.openLightbox.bind(this,8)} ><span className="more-images-text">{Dictionary.moreImages}</span></div>
                                    </div>
                                </div>             
                            } 
                        </div>
                    }

                </div>
            </div>
            <Lightbox images={this.props.images} onClose={this.closeLightbox} onClickPrev={this.gotoPrevious} onClickNext={this.gotoNext} currentImage={this.state.currentImage} isOpen={this.state.lightboxIsOpen} />            
            </>
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
})
export default connect(mapStateToProps)(Gallery);