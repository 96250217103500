import React from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux'
import ListItem from '../../components/Common/ListItem';
import Page from '../../components/Common/Page';
import {searchHotel,saveParameters,searchHotelAsync,fillHotelSearchURL,sortHotelSearch} from "../../Actions/HotelAction"
import {showError,editMessage} from "../../Actions/VisualDataAction";
import HotelForm from "../../components/Accomodation/SearchForm";
import {Hotel,Travel,Sort} from "../../Enum/Models";
import Currency from "../../Enum/Currency";
import HotelFilter from "../../components/Accomodation/FilterForm";
import danger from '../../image/danger.svg';
import siteLogo from '../../image/logo.svg';
import ShowMap from '../../components/Accomodation/showMap';
class HotelResult extends React.Component{
    state={
        result:null,
        error:'',
        loadingShow:true,
        first:0,
        last:9,
        showfilter:false,
        showsearch:false,
        sortPrice:1,
        sortName:0,
        sortRate:0,
        collapseSearchMenu:false,
        showMapContainer:false
    };
    handleSort(type){
        if(type===Sort.price){
            this.setState(prevState=>{
                return {
                    sortPrice:(prevState.sortPrice===2)?1:prevState.sortPrice+1,
                    sortName:0,
                    sortRate: 0
                }
            },()=>{
                this.props.sortHotelSearch({'typeSort':type,'isReverse':(this.state.sortPrice===2)})
            })
        }
        if(type===Sort.name){
            this.setState(prevState=>{
                return {
                    sortPrice:0,
                    sortName:(prevState.sortName===2)?1:prevState.sortName+1,
                    sortRate: 0
                }
            },()=>{
                this.props.sortHotelSearch({'typeSort':type,'isReverse':(this.state.sortName===2)})
            })
        }
        if(type===Sort.rate){
            this.setState(prevState=>{
                return {
                    sortPrice:0,
                    sortName:0,
                    sortRate: (prevState.sortRate===2)?1:prevState.sortRate+1,
                }
            },()=>{
                this.props.sortHotelSearch({'typeSort':type,'isReverse':(this.state.sortRate===2)})
            })
        }
    }
    handleChangeValue(first,last){
        this.setState({first:first,last:last});
    };
    handleMapToggle=()=>{
        this.setState((prevState)=>{
            return{
                showMapContainer:!prevState.showMapContainer
            }
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                loadingShow:false,
                result:null
            },()=>{
                this.props.searchHotelAsync(Hotel);
                this.props.editMessage(false);
                this.getHotels();
            });

        }
        if(this.props.respond!==prevProps.respond){
            this.setState({
                loadingShow:!this.props.respond,
                result:this.props.hotels
            })
        }
        //for filter data
        if(this.props.hotels!==prevProps.hotels){
            this.setState({
                result:this.props.hotels
            })
        }
        if(this.state.result!==prevState.result){
           this.setState({
               first:0,
               last:9
           })
        }


    }
    componentDidMount() {
        window.scrollTo(0,0);
        this.props.searchHotelAsync(Hotel);
        this.props.editMessage(false);
        this.getHotels();
        this.props.fillHotelSearchURL(window.location.href);
    }
    getHotels=()=>{
        let parameters=queryString.parse(this.props.location.search);
        this.props.saveParameters(parameters);
        let params=new URLSearchParams();
        parameters.adults.split(",").forEach((item)=> params.append("adults",item));
        parameters.children.split(",").forEach((item)=>params.append("children",item));
        if(parameters.ages.length!==0){
            parameters.ages.split(",").forEach((item)=>params.append("ages",item));
        }
        params.append("LocationId",parameters.locationId);
        params.append("checkin",parameters.checkin);
        params.append("checkout",parameters.checkout);
        params.append("NationalityCode",Currency.getNationality());
        //fill check-in and check-out
        this.props.searchHotel(params);

    }
    render() {
        const {Dictionary} = this.props;
        return (
            <div className="section-vertical-padding">
                <div className="hotelResult relative">
                    {(this.state.loadingShow)?
                        <div className="page-container">
                            <div className="loading-outer-container">
                                <div>
                                    <img src={siteLogo} className="loading-logo" alt="itours"/>
                                    <div className="loading-inner-container">
                                        <div className="item-1"></div>
                                        <div className="item-2"></div>
                                        <div className="item-3"></div>
                                        <div className="item-4"></div>
                                        <div className="item-5"></div>
                                    </div>
                                    <div className="text-center">
                                        {Dictionary.SearchingBestPrices} ...
                                    </div>
                                </div>                                            
                            </div> 
                        </div>                       
                    :(this.state.result===null)?
                        <div className="page-container">
                            <div className="section-vertical-padding">
                                <h2 className="page-title">                            
                                    {Dictionary.NoHotelsFoundForYourRequest}.
                                    <div className="page-subtitle">
                                        {Dictionary.pleaseTryAgain}.
                                    </div>
                                </h2>   
                                <div className="clearfix alert-cart card">
                                    <img src={danger} className="alert-icon" alt="danger-icon"/>
                                    <div className="alert-content">
                                        <h6 className="alert-title">
                                            {Dictionary.NoHotelsFoundForYourRequest}.
                                        </h6>
                                        <p className="no-margin">
                                            {Dictionary.pleaseTryAgain}.
                                        </p>
                                    </div>
                                </div>
                                </div>
                            </div>
                    :
                        <>
                        <div className="page-container">
                            <h2 className="page-title">
                                {Dictionary.selectHotel}
                            </h2>
                        </div>
                        <div className="page-container no-padding-mobile">
                            <div className="avails-row float-row">
                                <button type="button" className="filter-responsive-btn" onClick={()=>{this.setState((prevState)=>({showfilter:!prevState.showfilter}))}}>
                                    {Dictionary.filters}
                                </button>
                                <div className="col-small-12 col-medium-3">                               
                                    <div className="sidebar">
                                        <div className="map-btn-holder">
                                        {
                                            this.state.result && this.state.result.length>0 && 
                                                <button className="map-view-btn" type={"button"} onClick={this.handleMapToggle}>
                                                {
                                                    (this.state.showMapContainer) ?
                                                    Dictionary.listView
                                                    :
                                                    Dictionary.viewMap
                                                }                                                                                        
                                                </button>
                                            
                                        }    
                                        </div>                                 
                                        {/* <div className="sidebar-item clearfix">
                                            <span className="results-number-holder">
                                                <b className="inline-block margin-end-light"> {this.props.hotels.length} </b><span className="inline-block"> hoteller funnet i </span> <b className="inline-block en-font"> {this.props.city.name} </b>
                                            </span>
                                            <button type="button" className="button grey-btn pull-end filter-responsive-btn" onClick={()=>{this.setState((prevState)=>({showfilter:!prevState.showfilter}))}}>
                                            Filtre
                                            </button>
                                        </div> */}                                    
                                        <div className={(this.state.showfilter)?"showfilter filters-holder":"filters-holder"}>
                                            <HotelFilter/>
                                            <button type="button" className="button red-btn filter-close-btn" onClick={()=>{this.setState((prevState)=>({showfilter:!prevState.showfilter}))}}>{Dictionary.close}</button>
                                        </div> 
                                    </div>
                                </div>
                                <div className="col-small-12 col-medium-9">
                                    <div className="result-page-search-bar hidden-xsmall hidden-small">
                                        <HotelForm collapseMode={true} fixedSearchHolder={true}/>                                
                                    </div>
                                    {
                                        (this.state.result.length > 0)?
                                        <div className="avails-holder">

                                            <div className="results-sorting-block">
                                                <div className="clearfix">
                                                    <div className="pull-start-small">
                                                        {/* <img className={"sortPoint"} src={returnway} alt="sort" title={"sort"}/>  */}
                                                        {Dictionary.sortBy}
                                                        :
                                                    </div>
                                                    <div className="pull-end-small">
                                                        <button type="button"  className={(!this.state.sortPrice)?'button results-sort-btn disable':(this.state.sortPrice===2)?'button results-sort-btn':'button results-sort-btn low'}  onClick={()=>{this.handleSort(Sort.price)}}>
                                                            {Dictionary.price}
                                                        </button>
                                                        <button type="button"  className={(!this.state.sortName)?'button results-sort-btn disable':(this.state.sortName===2)?'button results-sort-btn':'button results-sort-btn low'} onClick={()=>{this.handleSort(Sort.name)}}>
                                                            {Dictionary.hotelName}
                                                        </button>
                                                        <button type="button" className={(!this.state.sortRate)?'button results-sort-btn disable':(this.state.sortRate===2)?'button results-sort-btn':'button results-sort-btn low'} onClick={()=>{this.handleSort(Sort.rate)}}>
                                                            {Dictionary.starRating} 
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                            (this.state.showMapContainer)?
                                                <div className="showMap">
                                                    {
                                                        this.state.result.length!==0 &&
                                                        <div>
                                                            <ShowMap currency={this.props.currency} data={this.state.result} height={700}/>
                                                        </div>
                                                    }
                                                </div>
                                            :
                                                <div className="showResult">
                                                    <ListItem last={this.state.last} type={Travel.hotel} data={this.state.result} start={this.state.first} />
                                                    {(this.state.result.length<10)?
                                                        null
                                                        :
                                                        <Page length={this.state.result.length} itemCurrent={this.state.first} itemPerPage={10}  onChangeValue={this.handleChangeValue.bind(this)}  />
                                                    }                                      
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div className="section-vertical-padding text-center">
                                            <div>
                                                <span className="itours-icon icon-xlarge error-icon" />
                                            </div>  
                                            <h5 className="normal-title">                            
                                                {Dictionary.NoHotelsFoundForYourRequest}.
                                                
                                                <div className="page-subtitle">
                                                    {Dictionary.pleaseTryAgain}.
                                                </div>
                                            </h5> 
                                        </div>                               
                                    }
                                </div>
                            </div>
                        </div>
                        </>
                    }
                </div>
            </div>

        )
    }
}
const mapStateToProps=(state)=>({
    error:state.VisualData.error,
    respond:state.VisualData.Responded,
    hotels: state.HotelSearch.hotels,
    city:state.HotelSearch.city,
    currency:state.UserData.Currency,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
const mapDispatchToProps=(dispatch)=>({
    hideError:(param)=>{dispatch(showError(param))},
    searchHotel:(param)=>{dispatch(searchHotel(param))},
    saveParameters:(param)=>{dispatch(saveParameters(param))},
    searchHotelAsync:(param)=>{dispatch(searchHotelAsync(param))},
    editMessage:(param)=>{dispatch(editMessage(param))},
    fillHotelSearchURL:(param)=>{dispatch(fillHotelSearchURL(param))},
    sortHotelSearch:(param)=>{dispatch(sortHotelSearch(param))}
});
export default connect(mapStateToProps,mapDispatchToProps)(HotelResult)