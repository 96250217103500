import React from 'react';
import {connect} from 'react-redux';
import {logoutUser} from '../../Actions/UserAction';
import {Link} from 'react-router-dom';
import {toggleVarification} from "../../Actions/VisualDataAction";
class ProfileBallon extends React.Component{
    state={
        visibileBallon:false
    }
    toggleBallon=()=>{
        this.setState((prevState)=>{
            return {
                visibileBallon:!prevState.visibileBallon
            }
        })
    }
    logout=()=>{
        this.props.logout();
        this.props.toggleVarification(false);
    }
    render(){
        const {Dictionary} = this.props;
        return(
            <div className="ballonContainer">
                
        <Link to={"/profile/"} className="link-style-btn"> {Dictionary.userProfile}</Link>
        <button onClick={this.logout} type="button" className="link-style-btn margin-start">{Dictionary.signOut} </button>

{/*             <div className="profileHeader" onClick={this.toggleBallon}>سلام  {userData.firstName}</div>
                
                {(this.state.visibileBallon)?
                    <div className="profileBallon">
                        <div className="avatarContainer hide">
                            {(userData.avatar==null)?
                                <img src={profile} alt="profile" />
                                :
                                <img src={userData.avatar} alt={userData.firstName} /> 
                            }
                        </div>
                        <div className="nameContainer">
                            {userData.firstName} {userData.lastName}
                        </div>
                        <div>
                            <Link to={"/profile/"}><div className="link">My Profile</div></Link>
                            <div className="link" onClick={this.logout}><span>Logout</span></div>
                        </div>
                    </div>:
                    null
                } */}

            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    model:state.UserData,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
const MapActionToProps=(dispatch)=>({
    logout:()=>{dispatch(logoutUser())},
    toggleVarification:(data)=>dispatch(toggleVarification(data))

})
export default connect(mapStateToProps,MapActionToProps)(ProfileBallon)