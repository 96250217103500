import {Reducers} from '../Enum/Models'
const DefaultData={
    showAccountWindow:false,
    error:{visibility:false,content:''},
    registerMode:false,
    Verification:null,
    Responded:false,
    HomeStyle:null,
    Dictionary:null,
    LAN:null
};
export const VisualData=(state=DefaultData,action)=>{
    switch(action.type){
        case 'ToggleAccountWindow':
            return {
                ...state,
                showAccountWindow:!state.showAccountWindow
            };
        case 'ToggleErrorWindow':
            return{
                ...state,
                error:{
                    visibility:action.params.visibility,
                    content:action.params.content
                }

            };
        case 'SwitchAccountWindow':
            return {
                ...state,
                registerMode:action.mode
            };
        case 'VERIFICATION':
            return {
                ...state,
                Verification: action.mode
            };
        case 'TOGGLE_ACCOUNT_WINDOWS':
            return {
                ...state,
                showAccountWindow:!state.showAccountWindow
            };
        case 'HANDLE_ERROR':
            return {
                ...state,
                error:{
                    content:action.content,
                    visibility: action.visibility
                }
            };
        case Reducers.MESSAGE:
            return {
                ...state,
                Responded:action.responded
            };
        case 'SETHOMEPAGECSS':
            return {
                ...state,
                HomeStyle: action.status
            };
        case 'SETLAN':
            return{
                ...state,
                Dictionary:action.data.Dictionary,
                LAN:action.data.Lan
            };
        default :
            return state;
    }
};