import axios from "axios";
import {ServerAddress,Payment as PaymentURL,Header,Hotel as HotelURL} from "../Enum/Urls";
import {showError,editMessage} from "./VisualDataAction";
//import actions
import {getReserveByIdFlight} from '../Actions/FlightAction';
import {getReserveByID} from '../Actions/HotelAction';
export const setBankList=(data)=>({type:'SetBanks',banksInfo:data});
export const setTokenId=(tokenId)=>({type:'SetTokenID',tokenId});
export const gatewayReportAsync=(data)=>({type:'VoucherDetailPayment',result:{...data}});
//Sync methods
export const getBankList=(params)=>{

    return (dispatch)=>{
        let token=localStorage.getItem("userToken");
        let HeaderAthu=null;
        if(token){
            HeaderAthu={Authorization:`Bearer ${token}`,...Header};
        }else{
            HeaderAthu={...Header};
        }
        axios({
        method:'get',
        url:`${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.GetBanksInformation}`,
        params:params,
        headers:HeaderAthu
    })
        .then((response)=>{
            dispatch(setBankList(response.data.result));
        })
        .catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
}
};
export const  setBankID=(param)=>{
    return (dispatch)=>{
        let token=localStorage.getItem("userToken");
        let HeaderAthu=null;
        if(token){
            HeaderAthu={Authorization:`Bearer ${token}`,...Header};
        }else{
            HeaderAthu={...Header};
        }
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.MakeToken}`,
            data:param,
            headers:HeaderAthu
        })
            .then((response)=>{
                dispatch(setTokenId(response.data.result.tokenId));
                dispatch(editMessage(true));

            })
            .catch((error)=>{
                try{
                    dispatch(showError({visibility:true,content:error.response.data.error.message}))
                }catch (e) {
                    dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
                }
            });
    };

};
export const gatewayReport=(params)=>{

    return(dispatch)=>{
        axios({
            method:'get',
            url:`${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.GatewayReport}`,
            params,
            headers:{...Header}
        })
        .then((response)=>{

                dispatch(gatewayReportAsync(response.data.result))
        })
        .catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
    }
};
export const GetReserveById=(params)=>{
    return(dispatch)=>{
        axios({
            method:'get',
            url:`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GETType}`,
            params,
            headers:{...Header}
        }).then((response)=>{
            if(response.data.result.type==="FlightGlobal"){
                dispatch(getReserveByIdFlight(params))

            }else if(response.data.result.type==="HotelGlobal"){
                dispatch(getReserveByID(params));


            }
        })

    }
}