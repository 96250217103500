import React from 'react';
import Rate from '../Common/Rate';
import HotelNoImage from '../../image/default-hotel-image.svg';
import {Currency} from "../../Enum/Models"
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Price from '../Common/Price';
class ShowAccomodation extends React.Component{
    static defaultProps={
        model:{
            phone:[],
            emails:[],
            geolocation:{latitude:0,longitude:0},
            features:[],
            address:"",
            id:0,
            rating: 0,
            image:null,
            regularPrice:0,
            salePrice: 0,
            discountPercent:0,
            name:''
        },
        forBook:true
    };
    currecySign=null;
    render(){
    const {features,address,id,rating,mainPhoto,regularPrice,salePrice,discountPercent,name}=this.props.model;
    const {params}=this.props;
    const {Dictionary} = this.props;
    if(this.props.currencyCode!==undefined){
        let currency=this.props.currencyCode.toString();
        this.currecySign=Currency[currency];
    }else{
        this.currecySign="???"
    }
    return(
        <div className="hotel-result-item">
            {(mainPhoto==null)?
                <div className="hotel-image default-image" style ={ { backgroundImage: 'url('+HotelNoImage+')' } }></div>
                :
                <div className="hotel-image" style ={ { backgroundImage: `url(${mainPhoto.substring(0,mainPhoto.length-4)}-320x240.jpg)` } }></div>
            }
            <div className="hotel-content">
                <div className="hotel-details" dir="ltr">
                    <div className="clearfix">
                        <Link className="inline-block hotel-name margin-right-light" target="_blank" to={`/hotel/details?Id=${id}&Adults=${params.adults}&Children=${params.children}&Ages=${params.ages}&Checkin=${params.checkin}&Checkout=${params.checkout}`}>{name}</Link>
                        <div className="rating inline-block">
                            <Rate number={rating} />
                        </div>
                    </div>
                    <div className="hotel-address">
                        <span className="en-font gray font-12">{address}</span>
                    </div>
                    <div className="feature-list">
                        <div className="feature-items" dir="ltr">{(features && features.map((item,index)=><div key={index} className="en-font">{item.name}</div>))}</div>
                    </div>
                </div>
                <div className="hotel-footer">
                    <div className="result-footer-title full-width">

                        {(discountPercent===0)?
                            <div className={"text-end"}>
                                <b className="purple small-margin-bottom text-center" dir="ltr">
                                    <Price currency={this.props.currencyCode}>{salePrice}</Price>
                                </b>
                            </div>
                            :
                            <div dir="ltr" className={"text-end"}>

                                <span className="margin-end-light old-price"><Price currency={this.props.currencyCode}>{regularPrice}</Price></span>
                                <b className="purple small-margin-bottom text-center">
                                    <Price currency={this.props.currencyCode}>{salePrice}</Price>
                                </b>
                            </div>
                        }
                        <div className={"text-end font-12 gray price-for-night"}>
                            <span>{Dictionary.startingPrice}</span>
                            <span className="nowrap"> ({this.props.night} {Dictionary.nights}) </span>
                        </div>

                        <Link className="button full-width red-btn has-arrow nowrap" target="_blank" to={`/hotel/details?Id=${id}&Adults=${params.adults}&Children=${params.children}&Ages=${params.ages}&Checkin=${params.checkin}&Checkout=${params.checkout}`}>{Dictionary.seeRooms}</Link>
                    </div>
                </div>
            </div>
        </div>


        )
    }

}
const stateMapProps=(state)=>{
    return {
        currencyCode:state.UserData.Currency,
        params:state.HotelSearch.parameters,
        night:state.HotelSearch.date.duration,
        Dictionary:state.VisualData.Dictionary,
        LAN:state.VisualData.LAN
    }
};
export default connect(stateMapProps)(ShowAccomodation)