import React from 'react';
import HotelNoImage from '../../image/default-hotel-image.svg';
import Rate from '../../components/Common/Rate';
import Price from '../../components/Common/Price';
import {connect} from 'react-redux';
import moment from 'moment-jalaali';
class SummaryHotel extends React.PureComponent{
    componentDidMount() {
        function getOffsetTop( elem )
        {
            let offsetTop = 0;
            do {
              if ( !isNaN( elem.offsetTop ) )
              {
                  offsetTop += elem.offsetTop;
              }
            } while( elem === elem.offsetParent );
            return offsetTop;
        }
        function getOffsetLeft( elem )
        {
            let offsetLeft = 0;
            do {
              if ( !isNaN( elem.offsetLeft ) )
              {
                  offsetLeft += elem.offsetLeft;
              }
            } while( elem === elem.offsetParent );
            return offsetLeft;
        }
        function sidesEffect() {
            if ( document.getElementsByClassName("sides-holder").length > 0 ){
                let docTop = document.documentElement.scrollTop,
                elmntsTop =getOffsetTop(document.getElementsByClassName("sides-holder")[0]) ,
                elmnt1 = document.getElementsByClassName("side-item")[0],
                elmnt2 = document.getElementsByClassName("side-item")[1],
                h1 = elmnt1.offsetHeight,
                h2 = elmnt2.offsetHeight,
                h,
                H,
                smallSide,
                bigSide,
                smallSideParent,
                smallSideWidth,
                smallSidePosLeft;
            
                
                if ( h1 < h2){
                    smallSide =  document.getElementsByClassName("side-item")[0];
                    bigSide =  document.getElementsByClassName("side-item")[1];
                } else{
                    smallSide =  document.getElementsByClassName("side-item")[1];
                    bigSide =  document.getElementsByClassName("side-item")[0];
                }
                smallSideParent = smallSide.parentElement;
                smallSideParent.style.position = "relative";
                smallSideParent.style.minHeight = "1px";
                h = smallSide.offsetHeight;
                H = bigSide.offsetHeight;
                smallSideWidth = smallSideParent.offsetWidth ;
                smallSidePosLeft = getOffsetLeft(smallSideParent) -1;
                if ( window.innerWidth < 850 ){
                    smallSide.style.position = "static";
                    smallSide.style.top = "";
                    smallSide.style.bottom = "";
                    smallSide.style.width = "";
                    smallSide.style.left = "";
                } else{
                    if ( docTop < elmntsTop ) {
            
                        smallSide.style.position = "static";
                        smallSide.style.top = "";
                        smallSide.style.bottom = "";
                        smallSide.style.width = "";
                        smallSide.style.left = "";		
                        
                    } else {
                        if( docTop < ( elmntsTop + H - h )) {
                            smallSide.style.position = "fixed";
                            smallSide.style.top = 0;
                            smallSide.style.bottom = "";
                            smallSide.style.width = smallSideWidth + "px";
                            smallSide.style.left = smallSidePosLeft + "px";
                
                        } else {
                            smallSide.style.position = "absolute";
                            smallSide.style.top = "";
                            smallSide.style.bottom = 0;
                            smallSide.style.width = "";
                            smallSide.style.left = 0;			
                        }
                    }
                }
            }

        }
        document.onscroll = sidesEffect;
        window.onresize = sidesEffect;

    }    
    render(){
        const {Dictionary,LAN} = this.props;
        return(
            <div className="hotel-summary-card box-border bg-white">
            <h5 className="card-title">
                <div className="hr_padding_half">
                    {Dictionary.bookingSummary}
                </div>
            </h5>
            <div className="hr_padding_half">
                <div className="hotel-head-info" dir="ltr">
                    {(this.props.model.accommodation.mainPhoto == null) ?
                        <div className="hotel-image default-hotel-image" style={{ backgroundImage: 'url(' + HotelNoImage + ')' }}></div>
                        :
                        <div className="hotel-image  main-hotel-image" style={{ backgroundImage:`url(${this.props.model.accommodation.mainPhoto.substring(0,this.props.model.accommodation.mainPhoto.length-4)}-320x240.jpg)`}}></div>
                    }
                    <div className="full-width">
                        <h5 className="hotel-title en-font">
                            {this.props.model.accommodation.name}
                        </h5>
                        <div className="hotelRange">
                            {(this.props.model.accommodation.rating) && <Rate number={this.props.model.accommodation.rating} />}
                        </div>
                        <div className="gray font-12">
                            {this.props.model.accommodation.address}
                        </div>

                    </div>

                </div>
                <div className="dates font-14 margin-bottom-light">
                
                    <div className="inline-block-top itours-icon calendar-dark margin-end-light icon-small"/>
                    <div className="inline-block-top">
                        {this.props.model.date.checkIn.split("T")[0]}
                        {(LAN==="IR")&&
                            <div className="font-12">({moment(this.props.model.date.checkIn.split("T")[0]).format("jYYYY-jMM-jDD")})</div>
                        }                        
                    </div>
                    <div className="inline-block-top itours-icon right-arrow-dark margin-end-light icon-small rotate-rtl"/>                    
                    <div className="inline-block-top margin-end-light">
                        {this.props.model.date.checkOut.split("T")[0]} 
                        {(LAN==="IR")&&
                            <div className="font-12">({moment(this.props.model.date.checkOut.split("T")[0]).format("jYYYY-jMM-jDD")})</div>
                        }                        
                    </div>
                    <div className="inline-block-top">  {this.props.night} {Dictionary.nights} </div>

                </div>

                {
                    this.props.model.accommodation.rooms.map((item,index)=>{
                        return(
                        <div className="clearfix room-item font-14 margin-bottom-light margin-end-light" dir="ltr" key={index}>
                            <div className="clearfix margin-bottom-light" >
                                <div className="pull-left">
                                    <span className="itours-icon hotel-dark margin-right-light icon-small"/>
                                    <span> {item.name} </span>
                                </div>
                                <div className="semi-bold pull-right body-direction">
                                    (<span className="inline-block">{item.adults} {( item.adults > 1 )?Dictionary.adults:Dictionary.adult}</span>
                                    {(item.children>0)?
                                        <span className="inline-block">, {item.children} {( item.children > 1 )?Dictionary.children:Dictionary.child} </span>
                                        :
                                        null
                                    })                                                                                    
                                </div>
                            </div>
                            <div className="green semi-bold margin-bottom-light">
                                {item.board.name}
                            </div>
                            <div className="green"><span className="semi-bold"><Price currency={this.props.currencyCode}>{item.salePrice}</Price></span></div>
                        </div>
                        )
                    })
                }


                <div className="card-footer">                                              
                    <div className="clearfix">
                        <span className="pull-start font-14">{Dictionary.totalPrice}</span>
                        <b className="pull-end semi-bold" dir="ltr">
                            <Price currency={this.props.currencyCode}>{this.props.totalPrice}</Price>
                        </b>
                    </div>
                    {this.props.showButton &&
                        <div className="confirmButton">
                            <button className="button red-btn full-width" type="submit" >{Dictionary.continueToPayment}</button>
                        </div>
                    }

                </div>                                                        
            </div>

        </div>
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(SummaryHotel);