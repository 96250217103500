import React from "react";
import {Link} from "react-router-dom";
import Autocomplete from "../Common/Autocomplete";
import {Header, Flight, ServerAddress} from "../../Enum/Urls";
import moment from 'moment-jalaali';
import PDatePicker from '../Common/PDatePicker';
import {DatePicker}  from 'antd';
import {connect} from 'react-redux';
//import moment from 'moment';
import {withRouter} from 'react-router-dom';
import queryString from "query-string";
import returnway from '../../image/returnway.svg';
import oneway from '../../image/oneway.svg';
import {showError} from "../../Actions/VisualDataAction";
class SearchForm extends React.Component{
    state={
        numberOfTrip:[1],
        validation:false,
        // range:[{min:moment().subtract(0,'day'),max:null},{min:moment().subtract(0,'day'),max:null}],
        searchParam: {
            departureDateTimes:[0,0],
            arrivalDateTimes: [],
            originCodes:[0],
            originNames:[0],
            destinationCodes:[0],
            destinationNames:[0],
            adult:1,
            child:0,
            infant:0,
            cabinClassCode:0,
            airTripType:'RoundTrip',
            nonStop:false,
            airlineCode:"all",
            airlineText:"all",
        },
        defaultValue:{
            departureDateTimes:[0,0],
            arrivalDateTimes: [],
            originCodes:[0],
            originNames:[0],
            destinationCodes:[0],
            destinationNames:[0],
            adult:1,
            child:0,
            infant:0,
            cabinClassCode:0,
            airTripType:'RoundTrip',
            nonStop:false,
            airlineCode:"all",
            airlineText:" ",
        },
        showMenu:true,
        calendarIsGregorian:false
    };
    changeCalendarType=()=>{
        this.setState((prevState)=>{
            return{calendarIsGregorian:!prevState.calendarIsGregorian}
        })
    }
    componentDidMount() {
        if(this.props.location.search){
            this.setState({
                calendarIsGregorian:true
            })
            this.getParamForFill();
            let parameters = queryString.parse(this.props.location.search);
            let route=[];
            for (let counter=0;counter<parameters.originCodes.split("|").length;counter++){
                route.push(1);
            }
            this.setState({
                numberOfTrip:route
            })
        }else{
            // this.setDates(0,new Date(moment(new Date().setHours(0,0)).add(1,'d')) );
            // this.setDates(1,new Date(moment(new Date().setHours(0,0)).add(2,'d')) );
        }
        if(this.props.inline){
            this.setState({
                showMenu:false
            })
        }
    }
    getParamForFill=()=>{
        let parameters = queryString.parse(this.props.location.search);
        if(parameters.adult!==undefined){
            let  modelLoad={};
            modelLoad["adult"]=parseInt(parameters.adult);
            modelLoad["child"]=parseInt(parameters.child);
            modelLoad["infant"]=parseInt(parameters.infant);
            modelLoad["airTripType"]=parameters.airTripType;
            modelLoad["airlineCode"]=parameters.airlineCode;
            modelLoad["cabinClassCode"]=parseInt(parameters.cabinClassCode);
            modelLoad["departureDateTimes"]=parameters.departureDateTimes.split("|");
            modelLoad["destinationCodes"]=parameters.destinationCodes.split("|");
            modelLoad["destinationNames"]=parameters.destinationNames.split("|");
            modelLoad["nonStop"]=parameters.nonStop;
            modelLoad["originCodes"]=parameters.originCodes.split("|");;
            modelLoad["originNames"]=parameters.originNames.split("|");
            modelLoad["airlineText"]=parameters.airlineText;
            let freezeURL=JSON.stringify(modelLoad);
            this.setState({
                defaultValue:JSON.parse(freezeURL),
                searchParam:modelLoad
            })
        }


    };
    disabledDate=(index,current)=> {
       // Can not select days before today and today
        if(index){
            return  current && current <= moment(this.state.searchParam.departureDateTimes[index-1])

        }else{
            return  current && current <= moment().endOf('day').subtract(1,'day');

        }
    };
    setDates=(index,value)=>{
        debugger;
        let dates=this.state.searchParam.departureDateTimes;
        dates[index]=moment(value);
        for(let counter=index+1;counter<(dates.length);counter++){
            if(dates[index].isAfter(dates[counter])){
                dates[counter]=dates[index];
            }
        }

        this.setState({
                searchParam:{
                    ...this.state.searchParam,
                    departureDateTimes:dates,
                },
        })
        // let cashDepartureDateTimes=this.state.searchParam.departureDateTimes;
        //
        // let cashMaxMin=this.state.range;
        // cashDepartureDateTimes[index]=value;
        // if(index<Object.keys(cashDepartureDateTimes).length -1){
        //     cashDepartureDateTimes[index+1]=value;
        // }
        // //if(index<cashMaxMin.length-1){
        //     cashMaxMin[index+1]={min:0,max:0};
        //     cashMaxMin[index+1].min=moment(value);
        // //}
        // this.setState({
        //         searchParam:{
        //             ...this.state.searchParam,
        //             departureDateTimes:cashDepartureDateTimes,
        //         },
        //         range:cashMaxMin
        //     })
    };
    getCallback=(value,text,name,id)=>{
        let searchParamCatch=this.state.searchParam;
        searchParamCatch[name + "Names"][id]=text;
        searchParamCatch[name + "Codes"][id]=value;
        this.setState({
            searchParam:{...this.state.searchParam,...searchParamCatch}
        })
    };
    getAirline=(value,text)=>{
        this.setState({
            searchParam:{...this.state.searchParam,airlineCode:(value)?value:"all",airlineText:(text)?text:"all"}
        })
    }
    handleSearchParam=(type,value)=>{
        let searchParamCash=this.state.searchParam;
        searchParamCash[type]=value;
        this.setState({
            searchParam:{...this.state.searchParam,...searchParamCash}
        })
    };
    handleSearchButton=()=>{
        debugger;
        this.setState({
            validation:true
        });
        let statusForm=true;
        for(let obj in this.state.searchParam.departureDateTimes){
            if(!this.state.searchParam.departureDateTimes[obj]){
                statusForm=false;
            }
        }
        this.state.searchParam.originCodes.forEach((item)=>{
            if(!item){
                statusForm=false;
            }
        })
        this.state.searchParam.destinationCodes.forEach((item)=>{
            if(!item){
                statusForm=false;
            }
        });
        if(statusForm){
            //preper URL search
            let dateArray="";
            for(let date in this.state.searchParam.departureDateTimes){
                let item=moment(this.state.searchParam.departureDateTimes[date]).format("YYYY-MM-DD");
                (parseInt(date))  ? dateArray+="|"+item : dateArray+=item;
            };
            let originCodes="";
            this.state.searchParam.originCodes.forEach((item,index)=>{
                (index)  ? originCodes+="|"+item : originCodes+=item
            });
            let originNames="";
            this.state.searchParam.originNames.forEach((item,index)=>{
                (index)  ? originNames+="|"+item : originNames+=item
            });
            let destinationCodes="";
            this.state.searchParam.destinationCodes.forEach((item,index)=>{
                (index)  ? destinationCodes+="|"+item : destinationCodes+=item
            });
            let destinationNames="";
            this.state.searchParam.destinationNames.forEach((item,index)=>{
                (index)  ? destinationNames+="|"+item : destinationNames+=item
            });
            //validate passenger
            //sum of passenger greater than 9
            if((parseInt(this.state.searchParam.adult)+parseInt(this.state.searchParam.child))>9){
                this.props.showError({visibility: true,content:this.props.Dictionary.TheNumberOfPassengersShouldNotBeMoreThan9person});
            }
            else if(parseInt(this.state.searchParam.adult)<parseInt(this.state.searchParam.infant))
            {
            this.props.showError({visibility: true, content:this.props.Dictionary.infantsMustBeLessThanAdults});
            }
            else{
                this.props.history.push(`/flight/result?originCodes=${originCodes}`+
                    `&originNames=${originNames}`+
                    `&destinationCodes=${destinationCodes}`+
                    `&destinationNames=${destinationNames}`+
                    `&departureDateTimes=${dateArray}`+
                    `&adult=${this.state.searchParam.adult}`+
                    `&child=${this.state.searchParam.child}`+
                    `&infant=${this.state.searchParam.infant}`+
                    `&cabinClassCode=${this.state.searchParam.cabinClassCode}`+
                    `&airTripType=${this.state.searchParam.airTripType}`+
                    `&nonStop=${this.state.searchParam.nonStop}`+
                    `&airlineCode=${this.state.searchParam.airlineCode}`+
                    `&airlineText=${this.state.searchParam.airlineText}`);
            }

        }
    };
    changeTypeOfTrip=(param)=>{
        if(param!=='OpenJaw'){
            this.setState({
                searchParam:{
                    ...this.state.searchParam,
                    airTripType:param
                },
                numberOfTrip:[1]
            },()=>{
                let searchParamCatch=this.state.searchParam;
                if(this.state.searchParam.airTripType==="OneWay"){
                    searchParamCatch.departureDateTimes=[searchParamCatch.departureDateTimes[0]]
                }else{
                    searchParamCatch.departureDateTimes=[searchParamCatch.departureDateTimes[0],searchParamCatch.departureDateTimes[1]];
                }
                if(this.state.searchParam.originCodes.length>1){
                    searchParamCatch.originCodes=[searchParamCatch.originCodes[0]];
                    searchParamCatch.originNames=[searchParamCatch.originNames[0]];
                    searchParamCatch.destinationCodes=[searchParamCatch.destinationCodes[0]];
                    searchParamCatch.destinationNames=[searchParamCatch.destinationNames[0]];
                }
                this.setState({
                    searchParam:{...this.state.searchParam,...searchParamCatch}
                })
            });

        }else{
            this.setState({
                searchParam:{
                    ...this.state.searchParam,
                    airTripType:param
                },
                numberOfTrip:[1,2]
            },()=>{
                let searchParamCatch=this.state.searchParam;
                searchParamCatch.originCodes.push(0);
                searchParamCatch.originNames.push(0);
                searchParamCatch.destinationCodes.push(0);
                searchParamCatch.destinationNames.push(0);
                this.setState({
                    searchParam:{...this.state.searchParam,...searchParamCatch}
                })
            });
        }

    };
    addFlight=()=>{
        let numberOfTripCash=this.state.numberOfTrip;
        if(numberOfTripCash.length<4){
            debugger;
            numberOfTripCash.push(numberOfTripCash[numberOfTripCash.length-1]+1);
            let searchParamCatch=this.state.searchParam;
            searchParamCatch.originCodes.push(0);
            searchParamCatch.originNames.push(0);
            searchParamCatch.destinationCodes.push(0);
            searchParamCatch.destinationNames.push(0);
            searchParamCatch.departureDateTimes.push(0);
            // let range=this.state.range;
            // range.push({min:this.state.searchParam.departureDateTimes[Object.keys(this.state.searchParam.departureDateTimes).length-1],max:null});
            this.setState({
                numberOfTrip:numberOfTripCash,
                searchParam:{...this.state.searchParam,...searchParamCatch}
            });


        }
    }
    removeFlight=(index)=>{
        let numberOfTripCash=this.state.numberOfTrip;
        numberOfTripCash.splice(index,1);
        let searchParamCatch=this.state.searchParam;
        searchParamCatch.originCodes.splice(index,1);
        searchParamCatch.originNames.splice(index,1);
        searchParamCatch.destinationCodes.splice(index,1);
        searchParamCatch.destinationNames.splice(index,1);
        searchParamCatch.departureDateTimes.splice(index,1);
        this.setState({
            numberOfTrip:numberOfTripCash,
            searchParam:{...this.state.searchParam,...searchParamCatch}
        })
    };
    render=()=>{
        const {Dictionary,LAN}=this.props;
        return(                    
            (!this.state.showMenu)?
            <div className={"searchShow"}>
                <div className="originShow">
                    <b>{this.state.defaultValue.originCodes[0]}</b>
                    <div>{this.props.originCity}</div>
                </div>
                <div className="typeShow">
                    {(this.state.searchParam.airTripType==='RoundTrip')?
                        <img src={returnway}  alt='return way tip' title="return way trip" className="rotate-rtl"/>
                            :
                        <img src={oneway} alt={'one way trip'} title="one way trip"  className="rotate-rtl"/>
                    }
                </div>
                <div className="destinationShow text-end">
                    <b>{this.state.defaultValue.destinationCodes[this.state.defaultValue.destinationCodes.length-1]}</b>
                    <div>{this.props.destinationCity}</div>
                </div>
                <div className="seperator"></div>
                <div className="depShow text-start">
                    <div className="gray-label">{Dictionary.departureDate}</div>
                    {
                        (LAN === "IR")?
                        <>
                            <div className="searched-value shamsi-date"> {moment(this.state.defaultValue.departureDateTimes[0]).format("jYYYY-jMM-jDD")}</div>
                            <small>({moment(this.state.defaultValue.departureDateTimes[0]).format("YYYY-MM-DD")}) </small>
                        </>
                        :
                        <div className="searched-value">{moment(this.state.defaultValue.departureDateTimes[0]).format("YYYY-MM-DD")}</div>
                    }

                </div>
                {
                    (this.state.searchParam.airTripType==='OpenJaw')&&
                    <div className="retShow text-start">
                        <div className="gray-label">{Dictionary.ArrivalDate}</div>
                        {
                            (LAN === "IR")?
                            <>
                                <div className="searched-value shamsi-date"> {moment(this.state.defaultValue.departureDateTimes[this.state.defaultValue.departureDateTimes.length-1]).format("jYYYY-jMM-jDD")}</div>
                                <small>({moment(this.state.defaultValue.departureDateTimes[this.state.defaultValue.departureDateTimes.length-1]).format("YYYY-MM-DD")}) </small>
                            </>
                            :
                            <div className="searched-value">{moment(this.state.defaultValue.departureDateTimes[this.state.defaultValue.departureDateTimes.length-1]).format("YYYY-MM-DD")}</div>
                        }
                    </div>
                }

                {(this.state.searchParam.airTripType==='RoundTrip')&&
                    <div className="retShow text-start">
                        <div className="gray-label">{Dictionary.returnDate}</div>
                        {
                            (LAN === "IR")?
                            <>
                                <div className="searched-value shamsi-date"> {moment(this.state.defaultValue.departureDateTimes[this.state.defaultValue.departureDateTimes.length-1]).format("jYYYY-jMM-jDD")}</div>
                                <small>({moment(this.state.defaultValue.departureDateTimes[this.state.defaultValue.departureDateTimes.length-1]).format("YYYY-MM-DD")}) </small>
                            </>
                            :
                            <div className="searched-value">{moment(this.state.defaultValue.departureDateTimes[this.state.defaultValue.departureDateTimes.length-1]).format("YYYY-MM-DD")}</div>
                        }
                    </div>
                }

                <div className="passShow text-start">
                    <div className="gray-label">{Dictionary.passengersQuantity}</div>
                    <strong className="searched-value"> {this.state.defaultValue.adult + this.state.defaultValue.child + this.state.defaultValue.infant} {Dictionary.people}</strong>
                </div>
                <div className="showSearch">
                    <button type="button" className="button blue-btn" onClick={()=>this.setState({showMenu:true})}>{Dictionary.modifySearch}</button>
                </div>

            </div>
            :
            <div className={this.props.inline && "full-container"}>
                <div className={this.props.inline && "search-container"}>
                    <div className= "search-box-holder">
                        {
                            this.props.inline ||
                            <div className="clearfix tab-holder text-center">
                                <button type="button" className="tab-s active-tab-s">
                                    {Dictionary.flight}
                                    <span className="itours-icon top-plane-white" />
                                </button>
                                <Link to="/hotel" className="tab-s">
                                    {Dictionary.hotel}
                                    <span className="itours-icon hotel-gray" />
                                </Link>
                                <button type="button" className="tab-s " disabled>
                                    {Dictionary.hotel} + {Dictionary.flight}
                                    <span className="itours-icon top-plane-gray" />
                                    <span className="itours-icon hotel-gray" />
                                </button>
                            </div>
                        }
                        <div className="searchbox-inner">
                            <div className="triptype-radio form-group inline-radio">
                                <div className="radio-item">
                                    <input className="custom-radio" value={"OneWay"}  type="radio" name="triptype" defaultChecked={this.state.defaultValue.airTripType==="OneWay"}   onClick={()=>this.changeTypeOfTrip('OneWay')}/>
                                    <label className="radio-label">
                                        <span className="radio-mark" />
                                        {Dictionary.oneway}
                                    </label>
                                </div>
                                <div className="radio-item">
                                    <input className="custom-radio" value={"RoundTrip"}  type="radio" name="triptype" defaultChecked={this.state.defaultValue.airTripType==="RoundTrip"}   onClick={()=>this.changeTypeOfTrip('RoundTrip')}/>
                                    <label className="radio-label">
                                        <span className="radio-mark" />
                                        {Dictionary.returnway}
                                    </label>
                                </div>
                                <div className="radio-item">
                                    <input className="custom-radio" value={"OpenJaw"}  type="radio" name="triptype" defaultChecked={this.state.defaultValue.airTripType==="OpenJaw"}   onClick={()=>this.changeTypeOfTrip('OpenJaw')}/>
                                    <label className="radio-label">
                                        <span className="radio-mark" />
                                        {Dictionary.openjawway}
                                    </label>
                                </div>
                                {
                                    (this.props.inline) && <div className="cancelShow">
                                        <button type={"button"} className="button show-close-btn" onClick={()=>{this.setState({showMenu:false})}}>
                                            <span className="itours-icon gray-close-icon icon-large" />
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="searchBar">
                                {
                                    this.state.numberOfTrip.map((item,index)=>{
                                        return(
                                            <div className={(this.state.searchParam.airTripType==='OpenJaw')?"avail-row-grid multy-dest-route":"avail-row-grid"} key={index}>
                                                <div className={(index>1)?"deleteable-route":""}>
                                                    <div className="float-row avail-row-grid">
                                                        <div className={(this.state.searchParam.airTripType==='RoundTrip')?"col-xsmall-12 col-small-6":"col-xsmall-12 col-small-6 col-large-4"} >
                                                            <div className="form-group flight-origin-holder">
                                                                <label className="form-label no-margin-top">{Dictionary.from}</label>
                                                                <Autocomplete defaultValue={(this.state.defaultValue.originCodes[index])?this.state.defaultValue.originCodes[index]:null} defaultText={(this.state.defaultValue.originNames[index])?this.state.defaultValue.originNames[index]:null} getValue={this.getCallback.bind(this)} name={'origin'} index={index} type={"flight"}  validate={true}   url={ServerAddress.Type + ServerAddress.Flight +Flight.GetLocation } min={3}   param="value" header={Header} />
                                                                {this.state.validation && !(this.state.searchParam.destinationCodes[index]) && <b className="validator-label">{Dictionary.pleaseEnterOrigin}</b>}
                                                            </div>
                                                        </div>
                                                        <div className={(this.state.searchParam.airTripType==='RoundTrip')?"col-xsmall-12 col-small-6":"col-xsmall-12 col-small-6 col-large-4"} >
                                                            <div className="form-group flight-destination-holder">
                                                                <label className="form-label no-margin-top">{Dictionary.to}</label>
                                                                <Autocomplete defaultValue={this.state.defaultValue.destinationCodes[index]&&this.state.defaultValue.destinationCodes[index]} defaultText={this.state.defaultValue.destinationNames[index]} getValue={this.getCallback.bind(this)} name={'destination'} index={index}  type={"flight"}  validate={true}   url={ServerAddress.Type + ServerAddress.Flight +Flight.GetLocation } min={3}   param="value" header={Header} />
                                                                {this.state.validation && !(this.state.searchParam.destinationCodes[index]) && <b className="validator-label">{Dictionary.pleaseEnterDestination}</b>}
                                                            </div>
                                                        </div>

                                                        <div className={(this.state.searchParam.airTripType==='RoundTrip')?"col-xsmall-12 col-small-6":"col-xsmall-12 col-small-12 col-large-4"} >
                                                            <div className="form-group full-width">
                                                                <label className="form-label"> {(this.state.searchParam.airTripType==='RoundTrip')? Dictionary.departureDate : Dictionary.date} {}</label>
                                                                <div className="relative">
                                                                    {(LAN ==="IR")?
                                                                        <PDatePicker changeType={this.changeCalendarType} gregorianMode={this.state.calendarIsGregorian}  className="persian-datepicker" min={(index)?(this.state.searchParam.departureDateTimes[index-1])?this.state.searchParam.departureDateTimes[index-1]:moment():moment()}    value={this.state.searchParam.departureDateTimes[index]}   onChange={(value)=>{this.setDates(index,value)}}/>
                                                                        :
                                                                        <DatePicker value={(this.state.searchParam.departureDateTimes[index]!==0)?moment(this.state.searchParam.departureDateTimes[index]):null}  placeholder="" format={'YYYY-MM-DD'}  showTime={false} disabledDate={this.disabledDate.bind(this,index)} onChange={(e)=>{this.setDates(index,e?new Date(e._d):0)}} />
                                                                    }
                                                                    {this.state.validation && !(this.state.searchParam.departureDateTimes[index]) && <b className="validator-label">{Dictionary.pleaseEnterDepartureDate}</b>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.searchParam.airTripType==='RoundTrip' &&
                                                        <div className="col-xsmall-12 col-small-6">
                                                            <div className="form-group">
                                                                <label className="form-label">{Dictionary.returnDate}</label>
                                                                <div className="relative">
                                                                    {(LAN ==="IR")?
                                                                        <PDatePicker changeType={this.changeCalendarType} gregorianMode={this.state.calendarIsGregorian} className="persian-datepicker" min={moment(this.state.searchParam.departureDateTimes[0])}   value={this.state.searchParam.departureDateTimes[index+1]}   onChange={(value)=>{this.setDates(index+1,value)}}/>
                                                                        :
                                                                        <DatePicker value={(this.state.searchParam.departureDateTimes[index+1]!==0)?moment(this.state.searchParam.departureDateTimes[index+1]):null}  format={"YYYY-MM-DD"} placeholder=""  showTime={false}  disabledDate={this.disabledDate.bind(this,index+1)} onChange={(e)=>{this.setDates(index+1,e?new Date(e._d):0)}} />
                                                                    }
                                                                    {this.state.validation && !(this.state.searchParam.departureDateTimes[index+1]) && <b className="validator-label">{Dictionary.pleaseEnterReturnDate}</b>}

                                                                </div>
                                                            </div>

                                                        </div>

                                                        }
                                                    </div>
                                                    {(index>1)&&<button type="button" className="button grey-btn delete-route margin-bottom" onClick={()=>{this.removeFlight(index)}}>
                                                        {Dictionary.remove}
                                                    </button>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    (this.state.searchParam.airTripType==='OpenJaw' && this.state.numberOfTrip.length<4)&&
                                    <div className="form-group">
                                        <button className="button blue-btn" type={"button"} onClick={()=>this.addFlight()}>
                                            {Dictionary.addNewDestination}
                                        </button>
                                    </div>
                                }
                                <div className="float-row">
                                    <div className={(this.props.inline)?"col-xsmall-4 col-small-2":"col-small-4"}>
                                        <div className="form-group">
                                            <label className="form-label" title={Dictionary.adult +" (12+)"}  >{Dictionary.adult} (12+)</label>
                                            <select defaultValue={this.state.defaultValue.adult} onChange={(e)=>this.handleSearchParam("adult",parseInt(e.target.value))} className="form-select">
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                                <option value={6}>6</option>
                                                <option value={7}>7</option>
                                                <option value={8}>8</option>
                                                <option value={9}>9</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={(this.props.inline)?"col-xsmall-4 col-small-2":"col-small-4"}>
                                        <div className="form-group">
                                            <label className="form-label" title={Dictionary.children + " (2-11)"} >{Dictionary.children} (2-11)</label>
                                            <select defaultValue={this.state.defaultValue.child} className="form-select" onChange={(e)=>this.handleSearchParam("child",parseInt(e.target.value))}>
                                                <option value={0}>0</option>
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                                <option value={6}>6</option>
                                                <option value={7}>7</option>
                                                <option value={8}>8</option>
                                                <option value={9}>9</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={(this.props.inline)?"col-xsmall-4 col-small-2":"col-small-4"}>
                                        <div className="form-group">
                                            <label className="form-label" title={Dictionary.infant +" (0-1)"} >{Dictionary.infant} (0-1)</label>
                                            <select defaultValue={this.state.defaultValue.infant}  className="form-select" onChange={(e)=>this.handleSearchParam("infant",parseInt(e.target.value))}>
                                                <option value={0}>0</option>
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                                <option value={6}>6</option>
                                                <option value={7}>7</option>
                                                <option value={8}>8</option>
                                                <option value={9}>9</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={(this.props.inline)?"col-xsmall-4 col-small-2":"col-small-4"}>
                                        <div className="form-group airline-autocomplete-holder">
                                            <label className="form-label">{Dictionary.airline}</label>
                                            <Autocomplete getValue={this.getAirline.bind(this)} name={'airlineCode'}  type={"flight"} defaultText={(this.state.defaultValue.airlineText)?this.state.defaultValue.airlineText:null} defaultValue={(this.state.defaultValue.airlineCode)?this.state.defaultValue.airlineCode:null} validate={true}   url={ServerAddress.Type + ServerAddress.Flight +Flight.GetAirline } min={2}   param="value" header={Header} />
                                        </div>
                                    </div>
                                    <div className={(this.props.inline)?"col-xsmall-4 col-small-2":"col-small-4"}>
                                        <div className="form-group">
                                            <label className="form-label">{Dictionary.stop}</label>
                                            <select defaultValue={this.state.defaultValue.nonStop} className="form-select" onChange={(e)=>this.handleSearchParam("nonStop",(e.target.value==="true"))}>
                                                <option value={false}>{Dictionary.all}</option>
                                                <option value={true}>{Dictionary.direct}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={(this.props.inline)?"col-xsmall-4 col-small-2":"col-small-4"}>
                                        <div className="form-group">
                                            <label className="form-label">{Dictionary.classCabin}</label>
                                            <select defaultValue={this.state.defaultValue.cabinClassCode} className="form-select" onChange={(e)=>this.handleSearchParam("cabinClassCode",parseInt(e.target.value))}>
                                                <option value={0} >{Dictionary.allClasses}</option>
                                                <option value={1}>{Dictionary.economy}</option>
                                                <option value={2}>{Dictionary.economyStandard}</option>
                                                <option value={3}>{Dictionary.economyPremium}</option>
                                                <option value={4}>{Dictionary.business}</option>
                                                <option value={5}>{Dictionary.firstClass}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="clearfix search-btn-holder form-group">
                                        <button type="button" className="searchBtn button red-btn pull-end full-width" onClick={()=>this.handleSearchButton()}> {Dictionary.search}</button>
                                    </div>
                                </div>                               

                            </div>

                        </div>
                    </div>
                </div>
            </div> 
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
const mapDispatchToProps=(dispatch)=>({
    showError:(param)=>dispatch(showError(param))
})
const RouterPath=withRouter(SearchForm);
export default connect(mapStateToProps,mapDispatchToProps)(RouterPath);
