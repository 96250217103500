//load  libraries
import React from 'react';
import {Route,Switch} from 'react-router-dom';
import {connect} from 'react-redux';
//load Layout Part Components
import Header from '../components/Views/Header';
import Footer from '../components/Views/Footer';
import Home from './Home';
import AccountWindow from '../components/Views/Account';
import ErrorShow from '../components/Views/Error';
import ReserveDetails from '../page/profile/ReserveDetails';
import FlightResult from '../page/flight/FlightResult';
import AboutUS from '../page/US/AboutUS';
import Terms from '../page/US/Terms';
import ContactUS from '../page/US/ContactUS';
import GetPassengerFlight from "../page/flight/GetPassengerFilght";
import {createUser,getUser} from '../Actions/UserAction';
import {toggleVarification,toggleErrorWindow,loadLanguage} from '../Actions/VisualDataAction';
import GaurdRouter from "../components/Common/GaurdRouter";
import Index from "../page/profile/Index";
import HotelResult from '../page/hotel/HotelResult';
import Confrim from '../page/profile/Confrim';
import ForgotPassword from '../page/profile/ForgotPassword';
import HotelDetails from '../page/hotel/HotelDetails';
import NotFound from '../page/NotFound';
import GetPassengers from './hotel/GetPassengers';
import Reserve from '../page/payment/Reserve';
import Result from '../page/payment/Result';
import HotelIndex from '../page/hotel/HotelIndex';
import MessageAccept from '../components/Common/MessageAccept';
import siteLogo from '../image/logo.svg';
import 'antd/dist/antd.css';
import "../scss/layout.scss";
// Import React material
class Layout extends React.Component{
    componentDidMount(){
        this.props.getUser();
        let domain=process.env.REACT_APP_SERVER_DOMAIN;
        this.props.loadLanguage(domain);
    }
    componentDidUpdate(prevProps){
        if(this.props.LAN && this.props.LAN === "IR"){
            document.body.classList.remove('ltr');
            document.body.classList.remove('arabic');
        }else if(this.props.LAN &&  this.props.LAN === "AR"){
            document.body.classList.remove('ltr');
            document.body.classList.add('arabic');
        }else{
            document.body.classList.add('ltr');
            document.body.classList.remove('arabic');
        }
    }
    render(){
        const {showAccountWindow,showErrorWindow,errorContent,registerMode,Verification}=this.props;
         return(
            (!Verification && !this.props.LAN)?
                <div className={"start-loading"}>
                    <div className="loading-outer-container">
                        <div>
                            <img src={siteLogo} className="loading-logo" alt="itours"/>
                            <div className="loading-inner-container">
                                <div className="item-1"></div>
                                <div className="item-2"></div>
                                <div className="item-3"></div>
                                <div className="item-4"></div>
                                <div className="item-5"></div>
                            </div>
                        </div>                                            
                    </div>
                </div>
                :
                <div className={"container full"}>
                    {(showAccountWindow)?<AccountWindow mode={registerMode} />:null}
                    {(showErrorWindow)?<ErrorShow>{errorContent}</ErrorShow>:null}
                    <Header/>
                    <main className="main-content">
                        {(!showAccountWindow)?

                            <Switch>
                                <Route path="/" exact={true} component={Home} />
                                <Route path="/flight" exact={true} component={Home}/>
                                <Route path="/contact"   component={ContactUS} />
                                <Route path="/terms" component={Terms} />
                                <Route path="/about" component={AboutUS} />
                                <Route path="/hotel" exact={true} component={HotelIndex} />
                                <Route path="/hotel/result" exact={true} component={HotelResult} />
                                <Route path="/hotel/details" exact={true} component={HotelDetails} />
                                <Route path="/hotel/getPassengers" exact={true} component={GetPassengers} />
                                <GaurdRouter component={Index} exact={true} path="/profile"  auth={Verification} />
                                <Route path="/profile/details" component={ReserveDetails} />
                                <GaurdRouter component={Confrim} path="/confrim" auth={Verification} />
                                <Route path="/ForgotPassword" component={ForgotPassword} />
                                <Route component={Reserve} exact={true} path="/payment/reserve" />
                                <Route component={Result} exact={true}  path="/payment/result"/>
                                <Route component={FlightResult} exact={true} path="/flight/result"/>
                                <Route component={GetPassengerFlight} path="/flight/getpassenger" exact={true} />

                                <Route component={NotFound}/>
                            </Switch>
                            :
                            null
                        }
                    </main>
                    <Footer />
                    <MessageAccept/>
                </div>

        )
    }
}
const MapStateToProps=(state)=>{
    return{showAccountWindow:state.VisualData.showAccountWindow,
           showErrorWindow:state.VisualData.error.visibility,
           errorContent:state.VisualData.error.content,
           registerMode:state.VisualData.registerMode,
           userToken:state.UserData.token,
           Verification: state.VisualData.Verification,
            Dictionary:state.VisualData.Dictionary,
            LAN:state.VisualData.LAN

        }
}
const MapDispatchToProps=(dispatch)=>({
    createUser:(data)=>dispatch(createUser(data)),
    toggleVarification:(data)=>dispatch(toggleVarification(data)),
    toggleErrorWindow:(params)=>dispatch(toggleErrorWindow(params)),
    getUser:(data)=>dispatch(getUser(data)),
    loadLanguage:(data)=>dispatch(loadLanguage(data))
});
export default connect(MapStateToProps,MapDispatchToProps)(Layout)
