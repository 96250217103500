import React, { Component } from 'react';
//React Redux Data
import {createStore,applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension'
import {Provider} from 'react-redux';
import combineReducers from '../Reducers/Reducer';
import thunk from "redux-thunk";
//Load Layout Page
import Layout from './Layout';
//Declare App for start Programing
class App extends Component {
  store=createStore(combineReducers,composeWithDevTools(applyMiddleware(thunk)));
  //store=createStore(combineReducers,applyMiddleware(thunk));
  render() {
    return (
      <Provider store={this.store}>
            <Layout />
      </Provider>
    );
  }
}

export default App;
