import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import axios from 'axios';
import '../../scss/autocomplete.scss';
import subset from '../../image/autocomplete/subset.svg';
import hotel from '../../image/autocomplete/hotel.svg';
import location from '../../image/autocomplete/location.svg';

class Autocomplete extends React.Component{

    state={
        selectedValue:(this.props.defaultValue)?this.props.defaultValue:null,
        selectedText:(this.props.defaultText)?this.props.defaultText:null,
        showlist:false,
        result:null,
        typingValue:'',
        loading:false
    };
    ref=React.createRef();
    clearValue(){
        this.setState({
            selectedValue:null,
            selectedText:null,
            typingValue:'',
        },()=>{
            if(this.props.type==="flight"){
                this.props.getValue(this.state.selectedValue,this.state.selectedText,this.props.name,this.props.index);
            }else{
                this.props.getValue({selectedValue:this.state.selectedValue,selectedText:this.state.selectedText,typingValue:this.state.selectedtype});
            }
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.defaultValue!==prevProps.defaultValue){
            this.setState({
                selectedValue:(this.props.defaultValue)?this.props.defaultValue:null,
                selectedText:(this.props.defaultText)?this.props.defaultText:null,
            })
        }
    }
    static  propTypes={
        url:PropTypes.string.isRequired,
        min:PropTypes.number.isRequired,
        name:PropTypes.string.isRequired,
        header:PropTypes.object.isRequired,
        param:PropTypes.string.isRequired,
        placeholder:PropTypes.string,
        validate:PropTypes.bool,
        type:PropTypes.any
    }
    fetchData(e){
        this.hideError=true;
        this.setState({
            selectedValue:null,
            selectedText:null
        },()=>{
            if(this.props.type==="flight"){
                this.props.getValue(this.state.selectedValue,this.state.selectedText,this.props.name,this.props.index);
            }
        });
        if(e.target.value.length>=this.props.min){
            this.setState({loading:true});
            axios({
                method:'get',
                url:`${this.props.url}?${this.props.param}=${e.target.value}`,
                headers:{...this.props.header}
            }).then((responsive)=>{
                this.setState({loading:false});
                let result=responsive.data.result;
                if(result.length!==0){
                    this.setState({
                        showlist:true,
                        result
                    })
                }
            }).catch((e)=>{
                console.log(e);
            })
        }else{
            this.setState({
                loading:false,
                showlist:false,
                result:null
            })
        }
        this.setState({typingValue:e.target.value});


    }
    selectItem(item){
        if(this.props.type==="flight"){
            this.setState({
                selectedValue:item.Code,
                selectedText:item.Name,
                selectedtype:item.Type,
                showlist:false,
                result:null
            },()=>{
                this.props.getValue(this.state.selectedValue,this.state.selectedText,this.props.name,this.props.index);
            })
        }else{
            this.setState({
                selectedValue:item.id,
                selectedText:item.name,
                selectedtype:item.typeId,
                showlist:false,
                result:null
            },()=>{
                this.props.getValue({selectedValue:this.state.selectedValue,selectedText:this.state.selectedText,typingValue:this.state.selectedtype});
            })
        }
    }

    render() {
        const {Dictionary}=this.props;
        return(
            <div className="autocompleteFeild">
                <div className="autocompleteText">
                    {this.state.loading && <div className={"loading-circle small"}></div>}
                    <input className="form-input placeholder-right" placeholder={this.props.placeholder} type="text" autoComplete="disabled" onChange={this.fetchData.bind(this)}  value={(this.state.selectedText)?this.state.selectedText:this.state.typingValue} />
                    {(this.state.selectedValue)?
                    <span className="itours-icon gray-close-icon clear-autocomplete" onClick={()=>{this.clearValue()}}/>
                    :null}
                    {
                    (this.state.showlist)&&
                        <div className="suggestList">
                            <ul>
                                {
                                    (this.props.type==="flight")?
                                        <>
                                            {this.state.result.map((item,index)=>{
                                                return(
                                                    <li  key={index}>
                                                    <div className="link" onClick={this.selectItem.bind(this,item)}>
                                                        <div><span><img src={location} alt="airport"/></span><span className="name">{item.Name}</span></div>
                                                        <div className="location">{item.ParentName}</div>
                                                    </div>
                                                    </li>
                                                )
                                            })}
                                        </>
                                        :
                                        <>
                                            {this.state.result.map((item,index)=>{
                                                return(
                                                    <li  key={index}>
                                                        {(item.children==null)?
                                                            <div className="link" onClick={this.selectItem.bind(this,item)}>
                                                                <div><span>{(item.typeId===3)?<img src={location} alt="city"/>:<img src={hotel} alt="hotel"/>}</span><span className="name">{item.name}</span></div>
                                                                <div className="location">{item.parentName}</div>
                                                            </div>
                                                            :
                                                            <div className="parentLink">
                                                                <div className="link" onClick={this.selectItem.bind(this,item)}>
                                                                    <div><span>{(item.typeId===3)?<img src={location} alt="city"/>:<img src={hotel} alt="hotel"/>}</span><span className="name">{item.name}</span></div>
                                                                    <div className="location">{item.parentName}</div>
                                                                </div>
                                                                <ul>
                                                                    {(item.children).map((itemIN,indexIN)=><li onClick={this.selectItem.bind(this,itemIN)} key={indexIN}>
                                                                        <span className="icon"><img src={subset} alt="hotel"/></span><span className="name">{itemIN.name}</span>
                                                                    </li>)}
                                                                </ul>
                                                            </div>
                                                        }

                                                    </li>
                                                )
                                            })}
                                        </>
                                }

                            </ul>
                        </div>
                    }
                    {
                        (this.props.validate)?
                            null
                            :
                            <div className="validator-label">
                                {Dictionary.enterYourDestination}
                            </div>
                    }
                </div>


            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(Autocomplete);