import React from 'react';
import {connect} from 'react-redux'
import moment from "moment";
import {setHomeCSS} from './../Actions/VisualDataAction';
import axios from "axios";
import {ServerAddress,Hotel,Header} from '../Enum/Urls';
import backgroundIMG from '../image/adv-bnk(7).jpg';
import advFa from '../image/adv-fa.jpg';
import SearchForm from "../components/Flight/SearchForm";
import Currency from "../Enum/Currency";
class Home extends React.Component{

    componentDidMount() {
        window.scrollTo(0,0);
        this.props.setHomeCSS(true);
        axios({
            method:'get',
            url:`${ServerAddress.Type + ServerAddress.Hotel + Hotel.GetLocation}?value=ist`,
            headers:{...Header}
        })
    }
    handleLink(idLocation,locationName){
        let date=new Date();
        let checkIn=moment(date).add(15,'d').format("YYYY-MM-DD");
        let checkout=moment(date).add(18,"d").format("YYYY-MM-DD");
        this.props.history.push(`/hotel/result?locationId=${idLocation}&location=${locationName}&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`);
    }
    handleFlightLink(destCode,destName){
        let culture=Currency.getNationality();
        let originCode = null;
        let originName = null;
        switch(culture){
            case "IR":
                originCode="THR";
                originName="Tehran,%20Iran(THR-All%20Airports)";
                break;
            case "NO":
                originCode="OSL";
                originName="Oslo,%20Norway(OSL-All%20Airports)";
                break;
            case "SE":
                originCode="STO";
                originName="Stockholm,%20Sweden(STO-All%20Airports)";
                break;
            case "DK":
                originCode="HEL";
                originName="Helsinki,%20Finland(HEL-All%20Airports)";
                break;
            case "US":
                originCode="OSL";
                originName="Oslo,%20Norway(OSL-All%20Airports)";
                break;
            default:
                originCode="HEL";
                originName="Helsinki,%20Finland(HEL-All%20Airports)";
        }
        let date=new Date();
        let depart=moment(date).add(15,'d').format("YYYY-MM-DD");

        this.props.history.push(`/flight/result?originCodes=${originCode}&originNames=${originName}&destinationCodes=${destCode}&destinationNames=${destName}&departureDateTimes=${depart}&adult=1&child=0&infant=0&cabinClassCode=0&airTripType=OneWay&nonStop=false&airlineCode=all&airlineText=all`);
    }
    componentWillUnmount() {
        this.props.setHomeCSS(false);
    }
    render(){
        const {Dictionary,LAN} = this.props;
        return(
            <div className="Home flight">
                <div className="search-section">
                    <div className="page-container">
                        <div className="float-row reverse">
                            <div className="col-small-12">
                                <div className="search-box">
                                    <SearchForm/>
{/*                                     <div onClick={()=>this.handleLink(74963,'Phuket')}  className="adv-banner hidden-small hidden-xsmall hidden-medium margin-start-medium">
                                        {
                                            (LAN === "IR")?
                                            <img src={advFa} alt="phuket" />
                                            :
                                            <img src={backgroundIMG} alt="phuket" />
                                        }
                                    </div> */}
                                    <div onClick={()=>this.handleFlightLink('HKT','Phuket%20International')}  className="adv-banner hidden-small hidden-xsmall hidden-medium margin-start-medium">
                                        {
                                            (LAN === "IR")?
                                            <img src={advFa} alt="phuket" />
                                            :
                                            <img src={backgroundIMG} alt="phuket" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="newletter-section">
                    <div className="page-container">
                        <div className="newsletter-holder">
                            <div className="float-row">
                                <div className="col-small-12 col-medium-6">
                                    <div className="newsleter-inner">
                                        <h4 className="normal-title">{Dictionary.newsletterSubscription} </h4>
                                        <p>{Dictionary.newsLeterText}</p>
                                    </div>
                                </div>
                                <div className="col-small-12 col-medium-6 text-end">
                                    <form className="newsletter-form">
                                        <div className="form-group inline-newsletter-form">
                                            <input type="email" placeholder={Dictionary.EnterYourEmailAddress} className="form-input placeholder-right" disabled />
                                            <button type="button" className="button blue-btn" disabled>{Dictionary.subscribe}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="landmarks-section">
                    <div className="page-container-fluid">
                        <div className="float-row text-center">
                            <h3 className="purple section-title">
{/*                                 {(LAN === "IR")?
                                <>
                                <span className="inline-block-middle margin-end-half">
                                    {Dictionary.with}
                                </span>
                                <img src={logo} alt="itours" className="inline-itours-logo inline-block-middle " />
                                <span className="inline-block-middle margin-end-half">
                                    {Dictionary.BookCheapInternationalFlights}
                                </span>                                
                                </>                                
                                :
                                <>
                                <span className="inline-block-middle margin-end-half">
                                    {Dictionary.BookCheapInternationalFlightsWith}
                                </span>
                                <img src={logo} alt="itours" className="inline-itours-logo inline-block-middle " />                                
                                </>
                                } */}

                                <span className="inline-block-middle margin-end-half">
                                    {Dictionary.topDestinations}
                                </span>

                            </h3>
                            <div className="col-small-4 col-medium-2">
                                <div className="landmark-link" onClick={()=>this.handleFlightLink('DXB','Dubai,%20United%20Arab%20Emirates(DXB-All%20Airports)')}>
                                    <div className="landmarg-icon landmark-dubai"></div>
                                    <span className="title">{Dictionary.Dubai}</span>
                                </div>
                            </div>
                            <div className="col-small-4 col-medium-2">
                                <div className="landmark-link" onClick={()=>this.handleFlightLink('PAR','Paris,%20France(PAR-All%20Airports)')}>
                                    <div className="landmarg-icon landmark-paris"></div>
                                    <span className="title">{Dictionary.Paris}</span>
                                </div>
                            </div>
                            <div className="col-small-4 col-medium-2">
                                <div className="landmark-link" onClick={()=>this.handleFlightLink('LON','London,%20United%20Kingdom(LON-All%20Airports)')}>
                                    <div className="landmarg-icon landmark-london"></div>
                                    <span className="title">{Dictionary.London}</span>
                                </div>
                            </div>
                            <div className="col-small-4 col-medium-2">
                                <div className="landmark-link" onClick={()=>this.handleFlightLink('MOW','Moscow,%20Russia(MOW-All%20Airports)')}>
                                    <div className="landmarg-icon landmark-moscow"></div>
                                    <span className="title">{Dictionary.Moscow}</span>
                                </div>
                            </div>
                            <div className="col-small-4 col-medium-2">
                                <div className="landmark-link" onClick={()=>this.handleFlightLink('SYD','Sydney,%20Australia(SYD-All%20Airports)')}>
                                    <div className="landmarg-icon landmark-sydney"></div>
                                    <span className="title">{Dictionary.Sydney}</span>
                                </div>
                            </div>
                            <div className="col-small-4 col-medium-2">
                                <div className="landmark-link" onClick={()=>this.handleFlightLink('DEL','Indira%20Gandhi%20Intl')}>
                                    <div className="landmarg-icon landmark-delhi"></div>
                                    <span className="title">{Dictionary.Delhi}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cities-section">
                    <div className="page-container">
                        <h3 className="blue section-title text-center">
                            {Dictionary.incrediblePlaces}
                        </h3>

                        <div className="float-row">
                            <div className="col-xsmall-12 col-small-6 margin-bottom">
                                <div className="thumbnail-link" >
                                    <div className="city-link city-ist large"></div>
                                    <div className="bg-on-hover">
                                        <h5 className="title center">
                                            <span className="cityName">{Dictionary.Istanbul}</span>
                                            <span className="show-on-hover">
                                                <span className="itours-icon hotel-white hotel-flight-search pointer" onClick={()=>this.handleLink(75286,'Istanbul')} />
                                                <span className="itours-icon top-plane-white hotel-flight-search pointer" onClick={()=>this.handleFlightLink('IST','Istanbul,%20Turkey(IST-All%20Airports)')} />
                                            </span>
                                        </h5>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xsmall-12 col-small-6 margin-bottom">
                                <div className="thumbnail-link">
                                    <div className="city-link city-dubai large"></div>
                                    <div className="bg-on-hover">
                                        <h5 className="title center">
                                            <span className="cityName">{Dictionary.Dubai}</span>
                                            <span className="show-on-hover">
                                                <span className="itours-icon hotel-white hotel-flight-search pointer" onClick={()=>this.handleLink(267,'Dubai')} />
                                                <span className="itours-icon top-plane-white hotel-flight-search pointer" onClick={()=>this.handleFlightLink('DXB','Dubai,%20United%20Arab%20Emirates(DXB-All%20Airports)')} />
                                            </span>
                                        </h5>                                        
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-xsmall-12 col-small-4 margin-bottom">
                                <div className="thumbnail-link">
                                    <div className="city-link city-beijing medium"></div>
                                    <div className="bg-on-hover">
                                        <h5 className="title center">
                                            <span className="cityName">{Dictionary.Beijing}</span>
                                            <span className="show-on-hover">
                                                <span className="itours-icon hotel-white hotel-flight-search pointer" onClick={()=>this.handleLink(12827,'Beijing')} />
                                                <span className="itours-icon top-plane-white hotel-flight-search pointer" onClick={()=>this.handleFlightLink('BJS','Beijing,%20China(BJS-All%20Airports)')} />
                                            </span>
                                        </h5>  
                                    </div>
                                </div>
                            </div>
                            <div className="col-xsmall-12 col-small-4 margin-bottom">
                                <div className="thumbnail-link">
                                    <span className="city-link city-rome medium"></span>
                                    <div className="bg-on-hover">
                                        <h5 className="title center">
                                            <span className="cityName">{Dictionary.Rome}</span>
                                            <span className="show-on-hover">
                                                <span className="itours-icon hotel-white hotel-flight-search pointer" onClick={()=>this.handleLink(54323,'Rome')} />
                                                <span className="itours-icon top-plane-white hotel-flight-search pointer" onClick={()=>this.handleFlightLink('ROM','Rome,%20Italy(ROM-All%20Airports)')} />
                                            </span>
                                        </h5>  
                                    </div>
                                </div>
                            </div>
                            <div className="col-xsmall-12 col-small-4 margin-bottom">
                                <div className="thumbnail-link" >
                                    <span className="city-link city-phuket medium"></span>
                                    <div className="bg-on-hover">
                                        <h5 className="title center">
                                            <span className="cityName">{Dictionary.Phuket}</span>
                                            <span className="show-on-hover">
                                                <span className="itours-icon hotel-white hotel-flight-search pointer" onClick={()=>this.handleLink(74963,'Phuket')} />
                                                <span className="itours-icon top-plane-white hotel-flight-search pointer" onClick={()=>this.handleFlightLink('HKT','Phuket%20International')} />
                                            </span>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-xsmall-12 col-small-3 margin-bottom">
                                <div className="thumbnail-link">
                                    <span className="city-link city-baku small"></span>
                                    <div className="bg-on-hover">
                                        <h5 className="title center">
                                            <span className="cityName">{Dictionary.Baku}</span>
                                            <span className="show-on-hover">
                                                <span className="itours-icon hotel-white hotel-flight-search pointer" onClick={()=>this.handleLink(5120,'Baku')} />
                                                <span className="itours-icon top-plane-white hotel-flight-search pointer" onClick={()=>this.handleFlightLink('BAK','Baku,%20Azerbaijan(BAK-All%20Airports)')} />
                                            </span>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-xsmall-12 col-small-3 margin-bottom">
                                <div className="thumbnail-link">
                                    <span className="city-link city-delhi small"></span>
                                    <div className="bg-on-hover">
                                        <h5 className="title center">
                                            <span className="cityName">{Dictionary.Delhi}</span>
                                            <span className="show-on-hover">
                                                <span className="itours-icon hotel-white hotel-flight-search pointer" onClick={()=>this.handleLink(49497,'Delhi')} />
                                                <span className="itours-icon top-plane-white hotel-flight-search pointer" onClick={()=>this.handleFlightLink('DEL','Indira%20Gandhi%20Intl')} />
                                            </span>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-xsmall-12 col-small-3 margin-bottom">
                                <div className="thumbnail-link">
                                    <span className="city-link city-bali small"></span>
                                    <div className="bg-on-hover">
                                        <h5 className="title center">
                                            <span className="cityName">{Dictionary.Bali}</span>
                                            <span className="show-on-hover">
                                                <span className="itours-icon hotel-white hotel-flight-search pointer" onClick={()=>this.handleLink(47947,'Kuta')} />
                                                <span className="itours-icon top-plane-white hotel-flight-search pointer" onClick={()=>this.handleFlightLink('DPS','Ngurah%20Rai')} />
                                            </span>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-xsmall-12 col-small-3 margin-bottom">
                                <div className="thumbnail-link">
                                    <span className="city-link city-copenhagen small"></span>
                                    <div className="bg-on-hover">
                                        <h5 className="title center">
                                            <span className="cityName">{Dictionary.Copenhagen}</span>
                                            <span className="show-on-hover">
                                                <span className="itours-icon hotel-white hotel-flight-search pointer" onClick={()=>this.handleLink(22664,'Copenhagen')} />
                                                <span className="itours-icon top-plane-white hotel-flight-search pointer" onClick={()=>this.handleFlightLink('CPH','Copenhagen,%20Denmark(CPH-All%20Airports)')} />
                                            </span>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        )
    }
}
const mapDispatchToProps=(dispatch)=>({
    setHomeCSS:(data)=>{dispatch(setHomeCSS((data)))}

});
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps,mapDispatchToProps)(Home)
