import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment-jalaali";
import airplane from "../../image/airplane.svg";
import Price from "../Common/Price";
import { Collapse,Button } from 'antd';
import {connect} from 'react-redux';
import _ from "lodash";
import Itineries from "./FlightItinery"
class FlighSummary extends React.PureComponent{
    state = {
        showItinerariModal : false
    }
    openItinerariModal = () =>{
        this.setState({
            showItinerariModal :true
        })
    }
    closeItinerariModal = () => {
        this.setState({
            showItinerariModal :false
        })
    }
    static propTypes={
        model:PropTypes.any.isRequired,
        fareInfo:PropTypes.any.isRequired,
        totalPrice:PropTypes.any.isRequired,
        currencyCode:PropTypes.any.isRequired,
        showButton:PropTypes.any.isRequired
    };
    componentDidMount(){
        function getOffsetTop( elem )
        {
            var offsetTop = 0;
            do {
              if ( !isNaN( elem.offsetTop ) )
              {
                  offsetTop += elem.offsetTop;
              }
            } while( elem === elem.offsetParent );
            return offsetTop;
        }  
        function getOffsetLeft( elem )
        {
            var offsetLeft = 0;
            do {
              if ( !isNaN( elem.offsetLeft ) )
              {
                  offsetLeft += elem.offsetLeft;
              }
            } while( elem === elem.offsetParent );
            return offsetLeft;
        }              
        function sidesEffect() {
            if ( document.getElementsByClassName("sides-holder").length > 0 ){
                var docTop = document.documentElement.scrollTop,
                elmntsTop =getOffsetTop(document.getElementsByClassName("sides-holder")[0]) ,
                elmnt1 = document.getElementsByClassName("side-item")[0],
                elmnt2 = document.getElementsByClassName("side-item")[1],
                h1 = elmnt1.offsetHeight,
                h2 = elmnt2.offsetHeight,
                h,
                H,
                smallSide,
                bigSide,
                smallSideParent,
                smallSideWidth,
                smallSidePosLeft;
            
                
                if ( h1 < h2){
                    smallSide =  document.getElementsByClassName("side-item")[0];
                    bigSide =  document.getElementsByClassName("side-item")[1];
                } else{
                    smallSide =  document.getElementsByClassName("side-item")[1];
                    bigSide =  document.getElementsByClassName("side-item")[0];
                }
                smallSideParent = smallSide.parentElement;
                smallSideParent.style.position = "relative";
                smallSideParent.style.minHeight = "1px";
                h = smallSide.offsetHeight;
                H = bigSide.offsetHeight;
                smallSideWidth = smallSideParent.offsetWidth ;
                smallSidePosLeft = getOffsetLeft(smallSideParent) - 1;
                if ( window.innerWidth < 850 ){
                    smallSide.style.position = "static";
                    smallSide.style.top = "";
                    smallSide.style.bottom = "";
                    smallSide.style.width = "";
                    smallSide.style.left = "";
                } else{
                    if ( docTop < elmntsTop ) {
            
                        smallSide.style.position = "static";
                        smallSide.style.top = "";
                        smallSide.style.bottom = "";
                        smallSide.style.width = "";
                        smallSide.style.left = "";		
                        
                    } else {
                        if( docTop < ( elmntsTop + H - h )) {
                            smallSide.style.position = "fixed";
                            smallSide.style.top = 0;
                            smallSide.style.bottom = "";
                            smallSide.style.width = smallSideWidth + "px";
                            smallSide.style.left = smallSidePosLeft + "px";
                
                        } else {
                            smallSide.style.position = "absolute";
                            smallSide.style.top = "";
                            smallSide.style.bottom = 0;
                            smallSide.style.width = "";
                            smallSide.style.left = 0;			
                        }
                    }
                }
            }

        }
            document.onscroll = sidesEffect;
            window.onresize = sidesEffect;
    };
    showPassenger(cnt,type,price){
        const {Dictionary}=this.props;
        return(
            <div className={"pair-row"}>
                <div className="passenger-type-title">{cnt} <span> x </span>
                        {(type==="ADT")&& Dictionary.adult}
                        {(type==="CHD")&& Dictionary.child}
                        {(type==="INF")&& Dictionary.infant}
                </div>
                <div dir="ltr">
                <Price currency={this.props.currencyCode}>{price}</Price>
                </div>
            </div>


        )
    }
    render() {
        const {Dictionary,LAN}=this.props;
        const { Panel } = Collapse;
        return(

                <div className="ticket-style-holder">
                    <div className="ticket-style-section">
                            <div className="hr_padding clearfix"><Button className="pull-end no-padding" type="link" htmlType="button" onClick={this.openItinerariModal}>{Dictionary.flightDetails}</Button>
                            <h5 className="normal-title">
                                {Dictionary.selectedFlight}
                            </h5>
                            <div className="selected-flights-info">
                                {this.props.model.map((item)=>{
                                    return(
                                        <div className="margin-bottom-light" key={item.flightSegments[0].flightNumber}>
                                            <div>
                                                <strong className="font-14 margin-end-light">{moment(item.flightSegments[0].departureDateTime).format("YYYY-MM-DD")}</strong>
                                                {(LAN === "IR")?
                                                <span className="font-12">({moment(item.flightSegments[0].departureDateTime).format("jYYYY-jMM-jDD")})</span>
                                                :null
                                                }
                                            </div>
                                            <div className="route-item">
                                                <div className="route-item-flex">
                                                    <div className="airline-logo-holder summery-airline-logo"><img src={item.flightSegments[0].marketingAirline.photoUrl} alt={item.flightSegments[0].marketingAirline.name} className="airline-logo" /></div>
                                                    <div className="router">
                                                        <div className="originInfo">
                                                            <div className="time"><strong>{moment(item.flightSegments[0].departureDateTime).format("HH:mm")}</strong></div>
                                                            <div className="codeCity">{item.flightSegments[0].departureAirport.locationCode}</div>
                                                        </div>
                                                        <div className="stop-info text-center">
                                                            <div className="stop">{(item.flightSegments.length-1===0)? <span className="green">{Dictionary.direct}</span> : <span>{item.flightSegments.length-1} {Dictionary.stops}</span>}</div>
                                                            <div className="dashed-line"> <img src={airplane} alt="airplane" title={"airplane"}/></div>
                                                            <div className="duration">{Dictionary.flightDuration} {item.journeyDuration}</div>
                                                        </div>
                                                        <div className="destinationInfo text-end">
                                                            <div className="time"><strong>{moment(item.flightSegments[item.flightSegments.length-1].arrivalDateTime).format("HH:mm")}</strong></div>
                                                            <div className="codeCity">{item.flightSegments[item.flightSegments.length-1].arrivalAirport.locationCode}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    )

                                })}
                            </div>
                        </div>
                    </div>
                    <div className={(this.state.showItinerariModal)? "itours-modal visible": "itours-modal"} >
                        <div className="modal-overlay" onClick={this.closeItinerariModal}/>
                        <div className="modal-inner">
                            <div className="modal-header">
                                {Dictionary.flightDetails}
                            </div>
                            <div className="modal-main">
                                <div className="flightDetails">
                                    <div className="detail-flight">
                                        <Itineries model={this.props.model}/>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <Button htmlType="button" type={"primary"} onClick={this.closeItinerariModal}>{Dictionary.ok}</Button>
                            </div>

                        </div>
                    </div>
                    <div className="ticket-style-section">
                        <div className="hr_padding">
                            <h5 className="normal-title">
                                {Dictionary.priceDetails}
                            </h5>
                            <div className="prices-info">
                                {
                                    this.props.fareInfo!==null
                                    &&
                                    <Collapse bordered={false} defaultActiveKey={[null]}>
                                        {this.props.fareInfo.map((item,index)=>{
                                            return(
                                                <Panel className="PassengerType" header={this.showPassenger(item.passengerTypeQuantity.quantity,item.passengerTypeQuantity.code,item[this.props.feild].totalFare)} key={index}>
                                                    <div className="pair-row baseFare gray">
                                                        <div>{Dictionary.baseFare}: </div>
                                                        <div dir="ltr"><Price currency={this.props.currencyCode}>{item[this.props.feild].baseFare}</Price></div>
                                                    </div>
                                                    <div className="pair-row taxes gray">
                                                        <div>{Dictionary.taxes}:</div>
                                                        <div dir="ltr"><Price currency={this.props.currencyCode}>{(item[this.props.feild].totalTaxes)?item[this.props.feild].totalTaxes:_.sumBy(item[this.props.feild].taxes,(item)=>item.amount)}</Price></div>
                                                    </div>
                                                </Panel>
                                            )
                                        })}
                                    </Collapse>
                                }


                            </div>
                            <div className="totalPrice inli">
                                <div className={"pair-row priceCur"}>
                                    <strong className="font-14">{Dictionary.totalPrice}:</strong>
                                    <div><b><Price currency={this.props.currencyCode}>{this.props.totalPrice}</Price></b></div>
                                </div>
                                {
                                    this.props.showButton &&
                                    <div><button  className="button red-btn full-width has-arrow thick-btn">{Dictionary.continueToPayment}</button></div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            )

    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(FlighSummary)