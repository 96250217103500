import React from 'react';
import Gallery from '../../components/Common/Gallery';
import {connect} from 'react-redux';
import queryString from "query-string";
import Price from '../../components/Common/Price';
import {getHotelDetails,saveParameters,getPreReserve,getRoom,setCancelationRoom} from "../../Actions/HotelAction";
import {editMessage} from "../../Actions/VisualDataAction";
import HotelInformation from '../../components/Accomodation/HotelInformation';
import Room from '../../components/Accomodation/Room';
import {Currency} from "../../Enum/Models";
import SearchRoom from "../../components/Accomodation/RoomSearch";
import ShowMap from '../../components/Accomodation/showMap';
import siteLogo from '../../image/logo.svg';
class HotelDetails extends React.Component{
    state={
        photos:[],
        Facilities:[],
        RoomsFacilities:[],
        RoomsInfo:[],
        RoomShow:[],
        roomSelect:[],
        isSelectAllRooms:false,
        doneSelectRoom:false,
        roomsAvailable:[],
        roomsSelected:[],
        roomsSelectedIndex:0,
        showSearch:true
    };
    currecySign=null;
    toggleSearch=()=>{
        this.setState((preState)=>{
            return{
                
                showSearch:!preState.showSearch
            }
        })
    }
    componentDidMount() {
        window.scrollTo(0,0);
        this.props.editMessage(false);
        this.getHotelDetails();

    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.respond && this.state.doneSelectRoom){
            this.props.history.push(`/hotel/getPassengers?preReserveKey=${this.props.params.preReserveKey}`);
        }
        if(prevProps.hotelDetails!==this.props.hotelDetails){
            let facilities=null;
            let roomFacilities=null;
            let roomSelectShow=[];
            if(this.props.hotelDetails!==null){
                //fill roomsAvailable and roomsSelected
                let roomsSelectedCash=[];
                this.props.hotelDetails.segments.forEach((roomItem,roomIndex)=>{
                   roomsSelectedCash.push({index:roomIndex,selected:false,bookingToken:null,visibility:(!roomIndex),name:null,price:null})
                });
                this.setState({
                    roomsAvailable:this.props.hotelDetails.segments,
                    roomsSelected:roomsSelectedCash
                });
                //
                //get Hotel facilites
                facilities=this.props.hotelDetails.features.filter((item)=>{
                    return item.keyword==="facilities"
                });
                if(facilities!==null){
                    if(facilities.length>0){
                        facilities=facilities[0].items;
                    }
                }
                //get room facilities
                roomFacilities=this.props.hotelDetails.features.filter((item)=>{
                    return item.keyword==="room_facilities"
                });
                if((roomFacilities!==null) && (roomFacilities.length>0)){

                    roomFacilities=roomFacilities[0].items;
                }
                //create array for show room select
                if(this.state.RoomShow.length===0){
                    for(let cnt=0;cnt<this.props.hotelDetails.segments.length;cnt++){
                        if(cnt===0){
                            roomSelectShow.push(true);

                        }else{
                            roomSelectShow.push(false);

                        }
                    }
                }else{
                    roomSelectShow=this.state.RoomShow;
                }

                //call setRooms action
                let roomSelect=[];
                for(let cnt=0;cnt<this.props.hotelDetails.segments.length;cnt++){
                    roomSelect.push({name:'',price:'',bookingToken:0});
                }
                //set gallery
                let imagesArray=this.props.hotelDetails.images;
                let imageGallery=imagesArray.map((item)=>{
                    let imageAddress=item.sizes[item.sizes.length-1].path;
                   return {src:imageAddress,width:1,height:1}
                });
                this.setState({
                    photos:imageGallery,
                    roomSelect:roomSelect,
                    Facilities:facilities,
                    RoomsFacilities:roomFacilities,
                    RoomsInfo:this.props.hotelDetails.segments,
                    RoomShow:roomSelectShow
                });

            }


        }
    };
    getHotelDetails=()=>{
        this.props.editMessage(false);
        let parameters=queryString.parse(this.props.location.search);
        this.props.saveParameters(parameters);
        let params=new URLSearchParams();
        params.append("Id",parameters.Id);
        parameters.Adults.split(",").forEach((item)=> params.append("Adults",item));
        parameters.Children.split(",").forEach((item)=>params.append("Children",item));
        if(parameters.Ages.length!==0){
            parameters.Ages.split(",").forEach((item)=>params.append("Ages",item));
        }
        params.append("Checkin",parameters.Checkin);
        params.append("Checkout",parameters.Checkout);
        this.props.getHotelDetails(params);

    };
    setSelectRooms=(index,name,price,bookingToken,supplier)=>{
        //newCode
        if(index===0){
            let cash=this.state.roomsAvailable.map((roomItem,roomindex)=>roomItem.rooms.filter((item)=>item.supplier===supplier));
            let roomsAvailableCash=this.state.roomsAvailable;
            roomsAvailableCash.forEach((item,index)=>{
                item.rooms=cash[index]
            });
            this.setState({
                roomsAvailable:roomsAvailableCash
            })
        }
        let roomSelectedCash=this.state.roomsSelected;
        roomSelectedCash[index].selected=true;
        roomSelectedCash[index].bookingToken=bookingToken;
        roomSelectedCash[index].name=name;
        roomSelectedCash[index].price=price;
        let pointer=index+1;
        if(pointer<roomSelectedCash.length){
            roomSelectedCash[pointer].visibility=true;
        }
        this.setState({
            roomsSelected:roomSelectedCash
        },()=>{
            ((pointer===this.state.roomsSelected.length)
                &&
                this.handleSendData()
            )
        });
        //
        // let roomSelect=this.state.roomSelect;
        // roomSelect[index].name=name;
        // roomSelect[index].price=price;
        // roomSelect[index].bookingToken=bookingToken;
        // roomSelect[index].supplier=supplier;
        // this.setState({
        //     roomSelect:roomSelect
        // },()=>{
        //   if(this.state.roomSelect[0].bookingToken!==0){
        //       
        //       let roomInfo=this.state.RoomsInfo.map((item,index)=>{
        //           let roomItem=item.rooms.filter((inItem,inIndex)=>{
        //               if(inItem.supplier===this.state.roomSelect[0].supplier){
        //                   return inItem
        //               }
        //           });
        //           return roomItem
        //       });
        //
        //   }
        // });
        // let roomShow=this.state.RoomShow;
        // let isSelectRooms=true;
        // for(let roomIndex in roomSelect){
        //     if(roomSelect[roomIndex].name===""){
        //         roomShow[roomIndex]=true;
        //         isSelectRooms=false;
        //         break;
        //     }
        //     else{
        //         roomShow[roomIndex]=false;
        //     }
        // }
        // this.setState({
        //     isSelectAllRooms:isSelectRooms,
        //     RoomShow:roomShow
        // },()=>{
        //     if((roomShow.length===1)&&(this.state.isSelectAllRooms)){
        //         this.handleSendData();
        //     }
        // });
    };
    handleRoomSelect=(index)=>{
        let roomShow=this.state.RoomShow;
        for(let obj in roomShow){
            roomShow[obj]=false;
        }
        roomShow[index]=!roomShow[index];
        this.setState({
            RoomShow:roomShow
        })
    };
    handleSendData=()=>{
      //create Parameters'
        this.props.editMessage(false);
        this.setState({
            doneSelectRoom:true
        });
        let params={};
        let bookingToken=this.state.roomsSelected.map((item)=>item.bookingToken);
        let adult=[];
        this.props.params.Adults.split(",").forEach((item)=> adult.push(parseInt(item)));
        params["adults"]=adult;
        let children=[];
        this.props.params.Children.split(",").forEach((item)=> children.push(parseInt(item)));
        params["children"]=children;
        if(this.props.params.Ages.length!==0){
            let ages=[];
            this.props.params.Ages.split(",").forEach((item)=>ages.push(parseInt(item)));
            params["ages"]=ages;
        }
        params["checkin"]=this.props.params.Checkin;
        params["checkout"]=this.props.params.Checkout;
        params["bookingToken"]=bookingToken;

        this.props.getPreReserve(params);

        //this.props.history.push(`/hotel/getPassengers?adults=${this.props.params.Adults}&children=${this.props.params.Children}&ages=${this.props.params.Ages}&checkin=${this.props.params.Checkin}&checkout=${this.props.params.Checkout}&bookingToken=${bookingToken}`);

    };
    showCancelation=(item,itemIN)=>{
        this.props.setCancelationRoom({cancelationInfo:'waiting',bookingID:itemIN.bookingToken});
        let parameters=queryString.parse(this.props.location.search);
        let params=new URLSearchParams();
        parameters.Adults.split(",").forEach((item)=> params.append("Adults",item));
        parameters.Children.split(",").forEach((item)=>params.append("Children",item));
        if(parameters.Ages.length!==0){
            parameters.Ages.split(",").forEach((item)=>params.append("Ages",item));
        }
        params.append("Checkin",parameters.Checkin);
        params.append("Checkout",parameters.Checkout);
        params.append("BookingToken",itemIN.bookingToken);
        this.props.getRoom(params);

    };
    render() {
        const {Dictionary} = this.props;
        let loadingCell=[];
        if(this.state.RoomsInfo){
            for(let cnt=0;cnt<this.state.RoomsInfo.length;cnt++){
                let cashArr=[];
                for(let icnt=0;icnt<this.state.RoomsInfo[cnt].rooms.length;icnt++){
                    cashArr.push(false);
                }
                loadingCell[cnt]=cashArr;
            }
        }
        if(this.props.currencyCode!==null){
            let currency=this.props.currencyCode.toString();
            this.currecySign=Currency[currency];
        }else{
            this.currecySign="???"
        }
        const {respond,hotelDetails} = this.props;
        return(
            
            <div className="roomDetailsContainer">
                <div className="page-container no-padding-mobile">
                {
                    (!respond)?
                        <div className="loading-outer-container">
                            <div>
                                <img src={siteLogo} className="loading-logo" alt="itours"/>
                                <div className="loading-inner-container">
                                    <div className="item-1"></div>
                                    <div className="item-2"></div>
                                    <div className="item-3"></div>
                                    <div className="item-4"></div>
                                    <div className="item-5"></div>
                                </div>
                                <div className="text-center">
                                    {
                                        (this.state.doneSelectRoom)?
                                        <span>{Dictionary.SendsSelectedRoomInformation} ... </span>
                                        :
                                        <span> {Dictionary.getHotelInformation} ... </span>
                                    }
                                </div>
                            </div>                                            
                        </div>                        
                        //loading Section
                        :
                        <div className="section-vertical-padding hotel-details">
                            <div className="hotel-head-info" dir="ltr">
                                <HotelInformation model={hotelDetails} salePrice={(hotelDetails.segments[0])?hotelDetails.segments[0].rooms[0].salePrice:0} currencyCode={this.props.currencyCode}/>
                            </div>

                            <div className="box-border bg-white margin-bottom detail-section">
                                <div className="float-row no-gap ltr">
                                    <div className="col-small-12 col-large-9" dir="ltr">
                                    {(this.state.photos.length>=1) ?
                                        <Gallery images={this.state.photos} />
                                        :
                                        <div className="card hotel-no-picture box-border text-center body-direction">
                                            <div className="no-image-hint " >
                                                <div className="margin-bottom">
                                                    {Dictionary.noImageHeadingText}
                                                </div>                                                
                                                <small>
                                                    {Dictionary.noImageDetailText}
                                                </small>
                                            </div>
                                        </div>
                                    }
                                    </div>
                                    <div className="col-small-12 col-large-3">
                                        <div className="card-padding">
                                        <h5 className="normal-title">{Dictionary.specialFacilities}</h5>
                                        {
                                        hotelDetails.features.map((item,index)=>{
                                            return (
                                                <div key={index} className="facilitie-item">                                            
                                                    {(item.name === "Facilities") &&
                                                        <div className="margin-bottom en-font" id="hotel_facilities">
                                                            <div dir="ltr"  className="feature-list">
                                                                <div className="feature-items">
                                                                    {item.items.map((detailsItem, detailsIndex) => <div className={(detailsItem.isImportant)?"is-important":null} key={detailsIndex}>{detailsItem.name} </div>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>                            

                             {/*photo Gallery and hotel info*/}

{/*                             <div className="hotel-details-nav hidden-xsmall hidden-small">
                                <a href="#hotel_allery" className="nav-btn active">
                                    Bilder
                                </a>
                                <a href="#roomSelect" className="nav-btn">
                                    Velg rommet
                                </a>
                                <a href="#hotel_details" className="nav-btn">
                                    Hotelldetaljer
                                </a>
                                <a href="#hotel_facilities" className="nav-btn">
                                    Hotellfasiliteter
                                </a>
                            </div> */}

                            <div className="card card-padding bg-white box-border margin-bottom detail-section">
                                {
                                    hotelDetails.features.map((item,index)=>{
                                        return (
                                            <div key={index} className="facilitie-item">                                            
                                                {item.name === "Object Information" &&
                                                    <div className="margin-bottom">
                                                        <h5 className="normal-title">{Dictionary.hotelContactInformation}</h5>  
                                                        <div dir="ltr">
                                                            <ul className="float-row no-list-style ltr">
                                                                {item.items.map((detailsItem, detailsIndex) => <li  className="col-small-12 col-medium-6 col-large-3 item" key={detailsIndex}>{detailsItem.name}:{detailsItem.value} </li>)}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }
                                                {item.name === "Building Information" &&
                                                    <div className="margin-bottom">
                                                        <h5 className="normal-title">{Dictionary.SpecificationsForHotelBuildings}</h5>
                                                        <div dir="ltr" >
                                                            <ul className=" float-row  no-list-style ltr">
                                                                {item.items.map((detailsItem, detailsIndex) => <li  className="col-small-12 col-medium-6 col-large-3 item" key={detailsIndex}>{detailsItem.name}:{detailsItem.value} </li>)}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }
                                                {(item.name === "Payment") &&
                                                    <div className="margin-bottom">
                                                        <h5 className="normal-title">{Dictionary.ValidFinancialCreditsForHotelUse}</h5>
                                                        <div dir="ltr" >
                                                            <ul className=" float-row  no-list-style ltr">
                                                                {item.items.map((detailsItem, detailsIndex) => <li  className="col-small-12 col-medium-6 col-large-3 item" key={detailsIndex}>{detailsItem.name} </li>)}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }
                                                {(item.name === "Facilities") &&
                                                    <div className="margin-bottom" id="hotel_facilities">
                                                        <h5 className="normal-title">{Dictionary.hotelFacilities}</h5>
                                                        <div dir="ltr" >
                                                            <ul className=" float-row  no-list-style ltr">
                                                                {item.items.map((detailsItem, detailsIndex) => <li  className="col-small-12 col-medium-6 col-large-3 item" key={detailsIndex}>{detailsItem.name} </li>)}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }
                                                {(item.name === "Room Facilities") &&
                                                    <div className="margin-bottom">
                                                        <h5 className="normal-title">{Dictionary.roomFacilities}</h5>
                                                        <div dir="ltr" >
                                                            <ul className=" float-row  no-list-style ltr">
                                                                {item.items.map((detailsItem, detailsIndex) => <li  className="col-small-12 col-medium-6 col-large-3 item" key={detailsIndex}>{detailsItem.name} </li>)}
                                                            </ul>
                                                        </div>
                                                    </div>                                            
                                                }
                                                {item.name === "Distances" &&
                                                    <div className="margin-bottom">
                                                        <h5 className="normal-title">{Dictionary.DistanceFromHotelToPlaces}</h5>
                                                        <div dir="ltr" >
                                                            <ul className="float-row  no-list-style ltr" >
                                                                {item.items.map((detailsItem, detailsIndex) => <li  className="col-small-12 col-medium-6 col-large-3 item" key={detailsIndex}>{detailsItem.name}:{detailsItem.value} m </li>)}
                                                            </ul>
                                                        </div>
                                                    </div>                                            
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                hotelDetails.paragraphs && (hotelDetails.paragraphs.length>0) &&
                                <div id="hotel_details">
                                    
                                    <div className="card card-padding bg-white box-border margin-bottom" dir="ltr">
                                        <h5 className="normal-title body-direction"> {Dictionary.hotelDetails}</h5>
                                        <div className="en-font">
                                            <div className="textContent">
                                                {hotelDetails.paragraphs.map((item,index)=>{
                                                    return (
                                                        <div key={index}>
                                                            <div><b className="title">{item.title}</b></div>
                                                            <div>
                                                                <p>{item.para}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>                                                
                                        </div>
                                    </div>
                                </div>
                                // hotel paragraph
                            }                      
                            <div>
                                <ShowMap data={[this.props.hotelDetails]}/>
                            </div>    
                            <div className="result-page-search-bar hotel-detail-searchholder margin-bottom">
                                <div className="search-form-header">
                                    {Dictionary.selectedHotelAndDates}
                                    <button type="button" className="button dark-btn margin-start full-width-mobile"onClick={this.toggleSearch}>                                        
                                        {
                                             (this.state.showSearch)?
                                             Dictionary.close
                                             :
                                             Dictionary.modifySearch
                                        } 
                                    </button>
                                </div>
                                {
                                    this.state.showSearch && <SearchRoom/>
                                }     
                                
                            </div>                                  
                            <div id={'roomSelect'}>
                                <div className="room-select-card" id="roomSelectSection">
                                    <h5 className="normal-title">{Dictionary.selectRoom} </h5>
                                    <div>
                                        {
                                            (hotelDetails.segments && hotelDetails.segments.length>0)?

                                                this.state.roomsAvailable.map((segment,segmentIndex)=>{
                                                    return(
                                                        <div className="margin-bottom select-room-item" key={segmentIndex}>
                                                            {
                                                                (this.state.roomsSelected[segmentIndex].visibility) &&
                                                                <div className="roomItem">
                                                                    <div className="itemSelect clearfix">
                                                                        <div className="selected-room clearfix bg-white">
                                                                            <div className="pull-start">
                                                                                <span className="inline-block margin-end-light"> {Dictionary.roomNr}. </span> 
                                                                                <span className="inline-block margin-end-light"> {segment.segment} </span> 
                                                                                <span className="inline-block margin-end-light"> - {Dictionary.for} </span>
                                                                                <span className="inline-block margin-end-light"> {segment.adults} </span>
                                                                                <span className="inline-block margin-end-light">  {( segment.adults > 1 )?Dictionary.adults:Dictionary.adult} </span>
                                                                                    {(segment.children !== 0) &&
                                                                                <span className="inline-block">  
                                                                                    <span className="inline-block margin-end-light"> {Dictionary.and} </span>
                                                                                    <span className="inline-block margin-end-light"> {segment.children} </span>
                                                                                    <span className="inline-block"> {( segment.children > 1 )?Dictionary.children:Dictionary.child} </span>
                                                                                </span>}                                                                                
                                                                            </div>
                                                                        {/*     <div className="pull-end">
                                                                                    <span>selected room: </span>
                                                                                    <span className="purple"> not selected </span>                                                                           
                                                                            </div> */}
                                                                            
                                                                        </div>
                                                                        {
                                                                            (this.state.roomsSelected[segmentIndex].selected)?
                                                                                <div className="selectedRoom card-padding bg-white clearfix box-border">
                                                                                    <div className="inline-block">
                                                                                        <div className="checkmark-holder">
                                                                                            <div className="success-checkmark">
                                                                                                <div className="check-icon">
                                                                                                    <span className="icon-line line-tip" />
                                                                                                    <span className="icon-line line-long" />
                                                                                                    <div className="icon-circle" />
                                                                                                    <div className="icon-fix" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="selectedPrice inline-block margin-start">
                                                                                        <b>{Dictionary.selectedRoom}:</b>
                                                                                        <span>({this.state.roomsSelected[segmentIndex].name})</span>
                                                                                        <span> -  {Dictionary.price} </span>
                                                                                        <b>                                                                                        
                                                                                        <Price currency={this.props.currencyCode}>{this.state.roomsSelected[segmentIndex].price}</Price>
                                                                                        </b>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                <div className="rooms-holder">
                                                                                    {
                                                                                        segment.rooms.map((room, roomIndex) => (
                                                                                            <Room key={roomIndex} model={room}
                                                                                                    currency={this.props.currencyCode}
                                                                                                    selectRoom={this.setSelectRooms}
                                                                                                    number={segmentIndex}
                                                                                                    urlParam={this.props.location.search}
                                                                                            />
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                </>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            :
                                            <div className="box-border card-padding bg-white text-center">
                                                <div className="margin-bottom">
                                                    <span className="itours-icon error-icon icon-xlarge" />
                                                </div>
                                                <div className="text-center font-18">
                                                    <div className="margin-bottom">
                                                        <strong>{Dictionary.SorryNoRoomFoundRightNow}!</strong>
                                                    </div>
                                                    <p>
                                                        {Dictionary.pleaseTryAnotherHotel}
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                }
                </div>
            </div>
            )

    }
}
const dispatchMapProp=(dispatch)=>({
    getHotelDetails:(params)=>{dispatch(getHotelDetails(params))},
    editMessage:(param)=>{dispatch(editMessage(param))},
    saveParameters:(param)=>{dispatch(saveParameters(param))},
    getPreReserve:(param)=>{dispatch(getPreReserve(param))},
    getRoom:(param)=>{dispatch(getRoom(param))},
    setCancelationRoom:(param)=>{dispatch(setCancelationRoom(param))}
});
const stateMapProp=(state)=>({
    hotelDetails:state.HotelSearch.selectedHotel,
    error:state.VisualData.error,
    respond:state.VisualData.Responded,
    currencyCode:state.UserData.Currency,
    params:state.HotelSearch.parameters,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN

});
export default connect(stateMapProp,dispatchMapProp)(HotelDetails)