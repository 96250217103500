import React from 'react'
import queryString from 'query-string';
import {connect} from 'react-redux'
import {GetReserveById} from '../../Actions/PaymentAction';
import {gatewayReport} from '../../Actions/PaymentAction';
import {setFlightSelected} from '../../Actions/FlightAction';
import {selectedHotel} from "../../Actions/HotelAction";
import {Payment} from '../../Enum/Models';
import Price from "../../components/Common/Price";
import {editMessage} from "../../Actions/VisualDataAction";
import { Alert } from 'antd';
import FlighSummary from '../../components/Flight/FlighSummary';
import SummaryHotel from '../../components/Accomodation/SummaryHotel';
import siteLogo from '../../image/logo.svg';
import renderHtml from "react-render-html";

class ReserveDetails extends React.Component{
    state={
        farePrice:null
    };
    getInfromation=()=>{
        this.props.setFlightSelected(null);
        this.props.selectedHotel(null);
        let parameters=queryString.parse(this.props.location.search);
        let params=new URLSearchParams();
        params.append("reserveId",parameters.reserveId);
        params.append("userName",parameters.userName);
        this.props.GetReserveById(params);
        this.props.gatewayReport({...parameters});
    };
    componentDidMount() {
        this.props.editMessage(false);
        window.scrollTo(0,0);
        this.getInfromation();
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.flightInfo!==null && this.props.flightInfo!==prevProps.flightInfo){

            let perPrice=[];
            this.props.flightInfo.passengersFare.forEach(item=>{
                perPrice[item.passengerTypeQuantity.code]={totalTaxes:item.fare.totalTaxes,totalFare:item.fare.totalFare}
            });
            let passengerInfo=this.props.flightInfo.passengers;
            passengerInfo.forEach(itemPass=>{
                itemPass["totalTaxes"]=perPrice[itemPass.code].totalTaxes;
                itemPass["totalFare"]=perPrice[itemPass.code].totalFare;

            });
            this.setState({
                farePrice:passengerInfo
            })
        }
    }

    render(){
        // let checkIn=null;
        // let checkOut=null;
        const {Dictionary} = this.props;
        let night=0;
        let totalPrice=0;
        const {respond,flightInfo,voucherDetails}=this.props;
        if(voucherDetails!=null) {
            night = voucherDetails.date.duration;
            // let cashDateIN = new Date(voucherDetails.date.checkIn);
            // checkIn = cashDateIN.getFullYear() + "/" + (cashDateIN.getMonth() + 1) + "/" + cashDateIN.getDate();
            // let cashDateOUT = new Date(voucherDetails.date.checkOut);
            // checkOut = cashDateOUT.getFullYear() + "/" + (cashDateOUT.getMonth() + 1) + "/" + cashDateOUT.getDate();
            if (voucherDetails.accommodation) {
                voucherDetails.accommodation.rooms.forEach((item) => {
                    totalPrice += parseFloat(item.salePrice)
                })
            }
        }
        let paymentArray=[];
        if(this.props.paymentInformation){
            for(let objIndex in this.props.paymentInformation){
                paymentArray.push(this.props.paymentInformation[objIndex]);
            }
        }
        return (
            (!respond)?
                <div className="loading-outer-container">
                    <div>
                        <img src={siteLogo} className="loading-logo" alt="itours"/>
                        <div className="loading-inner-container">
                            <div className="item-1"></div>
                            <div className="item-2"></div>
                            <div className="item-3"></div>
                            <div className="item-4"></div>
                            <div className="item-5"></div>
                        </div>
                        <div className="text-center">
                            {Dictionary.loadingReservationInformation}
                        </div>
                    </div>                                            
                </div>
                :
                <div className="section-vertical-padding">                      
                    <div className="page-container ">
                        <h2 className="page-title">
                            {Dictionary.reservationDetails}
                        </h2>
                            {(this.props.flightError && this.props.flightError==="2")&&
                            <Alert
                                message={Dictionary.error}
                                description= {Dictionary.SoryThereIsAProblemInTheReservationCallUs}
                                type="error"
                                showIcon
                            />
                            }
                            <div className="sides-outer-holder">
                                <div className="sides-holder reverse">
                                    <div className="small-side flightdetails">
                                        <div className="side-item full-width">
                                            <div className="side-item-inner">
                                                {
                                                    (flightInfo != null) &&                                                
                                                    <FlighSummary model={this.props.flightInfo.airItinerary.originDestinationOptions} fareInfo={this.props.flightInfo.passengersFare} totalPrice={this.props.flightInfo.flightFare.totalFare} currencyCode={this.props.currencyCode} showButton={false} feild={'fare'} />                                                
                                                }
                                                {
                                                    (voucherDetails!=null)&&
                                                    <SummaryHotel model={voucherDetails} currencyCode={this.props.currencyCode} showButton={false} totalPrice={totalPrice} night={night} />                                                       
                                                }
                                                <div className="box-border top-color-border orange margin-top bg-white">
                                                    <div className="colored">{Dictionary.pricesToRiseSoon}!</div>
                                                    <div className="font-12"> {Dictionary.bookNowToGetTheBestRateForYourCheckinDate}. </div>
                                                </div>                                                  
                                            </div>
                                        </div>                                        
                                    </div>                                    
                                    <div className="large-side">
                                        <div className="side-item full-width">
                                            <div className="side-item-inner">
                                                {
                                                    (flightInfo != null) && 
                                                    <>                                               
                                                    <div className={"bg-white box-border card-padding Reserver information margin-bottom"}>
                                                        <h5 className="normal-title">{Dictionary.ReserverInformartion}</h5>
                                                        <div className="float-row">
                                                            <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.gender}: </span> {flightInfo.reserver.gender?Dictionary.male:Dictionary.female}</div>
                                                            <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.firstName}: </span> {flightInfo.reserver.firstName}</div>
                                                            <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.lastName}: </span> {flightInfo.reserver.lastName}</div>
                                                            <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.email}: </span> {flightInfo.reserver.email}</div>
                                                            <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.phoneNumber}: </span> {flightInfo.reserver.phoneNumber}</div>
                                                        </div>
                                                    </div>
                                                    <div className={"passengerInformation margin-bottom"}>
                                                        <h5 className="normal-title">Prices</h5>
                                                        <div className="itours-responsive-table medium-table">
                                                            <div className="table-row-group">
                                                                <div className="table-row table-head">
                                                                    <div>{Dictionary.row}</div>
                                                                    <div>{Dictionary.firstName}</div>
                                                                    <div>{Dictionary.lastName}</div>
                                                                    <div>{Dictionary.taxes}</div>
                                                                    <div>{Dictionary.fare}</div>
                                                                </div>
                                                                {
                                                                    this.state.farePrice && this.state.farePrice.map((itemPass, indexPass) => {
                                                                        return (
                                                                            <div className="table-row" key={indexPass}>
                                                                                <div><span className="responsive-label">{Dictionary.row}</span><span> {indexPass+1}</span></div>
                                                                                <div><span className="responsive-label">{Dictionary.firstName}</span><span>{itemPass.firstName}</span></div>
                                                                                <div><span className="responsive-label">{Dictionary.lastName}</span><span>{itemPass.lastName} </span></div>
                                                                                <div><span className="responsive-label">{Dictionary.taxes}</span><span className="nowrap"> {itemPass.totalTaxes}</span></div>
                                                                                <div><span className="responsive-label">{Dictionary.fare}</span><span className="nowrap">{itemPass.totalFare}</span></div>                                                                                
                                                                            </div>                                                                            
                                                                        )
                                                                    })
                                                                } 
                                                                <div className="table-row total-row">
                                                                    <div><b>{Dictionary.totalTaxes}</b></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div> <b>{flightInfo.flightFare.totalTaxes}</b> </div>
                                                                </div>
                                                                <div className="table-row total-row">
                                                                    <div><b className="purple">{Dictionary.totalFare}</b></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div><b className="purple">{flightInfo.flightFare.totalFare}</b></div>
                                                                </div>                                                             
                                                            </div>
                                                        </div>                                                        
                                                    </div>                                                    
                                                    </>
                                                }
                                                {
                                                (voucherDetails!=null)&&
                                                    <>                     
                                                        <div className="card-padding bg-white box-border margin-bottom">
                                                            <div className="clearfix">
                                                                <div className="pull-start">
                                                                    <h3 className="normal-title">
                                                                        {voucherDetails.status===Payment.Pending && <div>
                                                                            <span className="itours-icon icon-xlarge peyment-warning margin-end-half" />
                                                                            <span>
                                                                                {Dictionary.paymentPending}
                                                                            </span>
                                                                        </div>}
                                                                        {voucherDetails.status===Payment.Canceled && <div>
                                                                            <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                                                            <span>
                                                                                {Dictionary.canceled}
                                                                            </span>
                                                                        </div>}
                                                                        {voucherDetails.status===Payment.Failed && <div>
                                                                            <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                                                            <span>
                                                                                {Dictionary.Failed}
                                                                            </span>
                                                                        </div>}
                                                                        {voucherDetails.status===Payment.Issued && <div>
                                                                            <span className="itours-icon icon-xlarge peyment-ok margin-end-half" />
                                                                            <span>
                                                                                {Dictionary.Issued}
                                                                            </span>
                                                                        </div>}
                                                                        {voucherDetails.status===Payment.PaymentSuccessful && <div>
                                                                            <span className="itours-icon icon-xlarge peyment-exchange margin-end-half" />
                                                                            <span>
                                                                                {Dictionary.Processing}
                                                                            </span>
                                                                        </div>}
                                                                        {voucherDetails.status===Payment.Refunded && <div>
                                                                            <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                                                            <span>
                                                                                {Dictionary.Refunded}
                                                                            </span>
                                                                        </div>}
                                                                        {voucherDetails.status===Payment.WebServiceUnsuccessful && <div>
                                                                            <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                                                            <span>
                                                                                {Dictionary.WebServiceUnsuccessful}
                                                                            </span>
                                                                        </div>}
                                                                    </h3>
                                                                    <div className="margin-bottom">
                                                                        <span className="inline-block">
                                                                        {Dictionary.trackingCode}
                                                                        :
                                                                        </span>
                                                                        <span className="inline-block margin-end"> {voucherDetails.reserveId} </span>
                                                                    </div>
                                                                </div>
                                                                <a href={`http://voucher.imt.as/en/Hotel/Voucher?reserveId=${voucherDetails.reserveId}&username=${voucherDetails.reserver.username}`} download className="button blue-btn pull-end">
                                                                    {Dictionary.GetVoucher }
                                                                </a>
                                                            </div>
                                                            <br/>
                                                            <h3 className="normal-title">
                                                                {Dictionary.ReserverInformartion}
                                                            </h3>
                                                            <div className="float-row">
                                                                <div className="col-small-12 col-large-4 margin-bottom">
                                                                    <b className="inline-block margin-end-light">
                                                                        {Dictionary.fullName}:
                                                                    </b>
                                                                    <span className="inline-block"> {voucherDetails.reserver.firstName} {voucherDetails.reserver.lastName}</span>
                                                                </div>
                                                                <div className="col-small-12 col-large-4 margin-bottom">
                                                                    <b className="inline-block margin-end-light">
                                                                        {Dictionary.email}:
                                                                    </b>
                                                                    <span className="en-font inline-block" dir="ltr"> {voucherDetails.reserver.username}</span>
                                                                </div>
                                                                <div className="col-small-12 col-large-4 margin-bottom">
                                                                    <b className="inline-block margin-end-light">
                                                                        {Dictionary.mobileNumber}:
                                                                    </b>
                                                                    <span className="inline-block" dir="ltr"> {voucherDetails.reserver.phoneNumber}</span>
                                                                </div>
                                                            </div>
                                                        </div>{/*reserver information*/}
  
                                                        {voucherDetails.accommodation.rooms[0].remarks &&
                                                        <div className=" margin-bottom bg-white box-border card-padding" >
                                                            <h5 className="normal-title">
                                                                {Dictionary.remarks}:
                                                            </h5>
                                                            <div className="justified en-font" dir="ltr">
                                                                {renderHtml(voucherDetails.accommodation.rooms[0].remarks)}
                                                                
                                                            </div>
                                                        </div>
                                                        }
                                                    </>                                                    
                                                }
                                                {
                                                    (paymentArray)?
                                                    <div>
                                                        <h3>
                                                            {Dictionary.peymentDetails}
                                                        </h3>
                                                        <div className="itours-responsive-table large-table text-center">
                                                            <div className="table-row-group">
                                                                <div className="table-row table-head">
                                                                    <div>{Dictionary.row}</div>
                                                                    <div>{Dictionary.port} </div>
                                                                    <div>{Dictionary.trackingNumber} </div>
                                                                    <div>{Dictionary.paymentDate} </div>
                                                                    <div>{Dictionary.paymentTime} </div>
                                                                    <div>{Dictionary.missionary} </div>
                                                                    <div>{Dictionary.status} </div>
                                                                </div>
                                                                {paymentArray && paymentArray.map((item,index)=>{
                                                                    return (
                                                                        <div className="table-row" key={index}>
                                                                            <div><span className="responsive-label">{Dictionary.row}</span><span>{index +1 }</span></div>
                                                                            <div><span className="responsive-label">{Dictionary.port}</span><span>{item.gatewayName}</span></div>
                                                                            <div><span className="responsive-label">{Dictionary.trackingNumber} </span><span>{item.id}</span></div>
                                                                            <div><span className="responsive-label">{Dictionary.paymentDate} </span><span className="nowrap">{item.transferDateTime.split("T")[0]}</span></div>
                                                                            <div><span className="responsive-label">{Dictionary.paymentTime}</span><span className="nowrap">{item.transferDateTime.split("T")[1]}</span></div>
                                                                            <div><span className="responsive-label">{Dictionary.missionary}</span><span dir="ltr" className="nowrap"><Price currency={this.props.currencyCode}>{item.amount}</Price></span></div>
                                                                            <div><span className="responsive-label">{Dictionary.status}</span><span >{(item.isConfirmed)?Dictionary.paid:Dictionary.unpaid}</span></div>                                                                           
                                                                        </div>
                                                                    )
                                                                })}                                                                
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="card-padding bg-white box-border clearfix">
                                                            <span className="inline-block">{Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly} :</span>
                                                            <span dir="ltr" className="pull-end">
                                                                <span className="margin-end-half itours-icon gray-phon-icon icon-small" />
                                                                <span className="inline-block">
                                                                    {Dictionary.supportNumber}
                                                                </span>
                                                            </span>
                                                        </div> 
                                                    </div>
                                                        :
                                                    <div>{Dictionary.paymentDataIsLoading}</div>
                                                }                                                                                                
                                            </div>
                                        </div>
                                    </div>

                                </div>                                

                            </div>                           

                        </div>
                        
                    
                    

                </div>

        )
    }

}
//Map
const mapStateToProps=(state)=>({
    voucherDetails:state.HotelSearch.selectedHotel,
    currencyCode:state.UserData.Currency,
    paymentInformation:state.PaymentData.voucherDetailsPayment,
    flightInfo:state.FlightSearch.flightSelected,
    respond:state.VisualData.Responded,
    flightError:state.FlightSearch.flighterror,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
const mapDispatchToProps=(dispatch)=>({
    GetReserveById:(param)=>{dispatch(GetReserveById(param))},
    gatewayReport:(param)=>{dispatch(gatewayReport(param))},
    editMessage:(params)=>{dispatch(editMessage(params))},
    setFlightSelected:(params)=>{dispatch(setFlightSelected(params))},
    selectedHotel:(params)=>{dispatch(selectedHotel(params))}
});
export default connect(mapStateToProps,mapDispatchToProps)(ReserveDetails)