import React from "react";
import queryString from "query-string";
import {connect} from "react-redux";
import {ServerAddress} from "../../Enum/Urls";
import {getBankList,setBankID} from '../../Actions/PaymentAction';
import {editMessage} from '../../Actions/VisualDataAction';
import {getReserveByID,bookhotel} from '../../Actions/HotelAction';
import {getReserveByIdFlight,getDirectTicketByID} from '../../Actions/FlightAction';
import {GetReserveById} from '../../Actions/PaymentAction';
import paymentError from "../../image/payment-error.svg"
import siteLogo from '../../image/logo.svg';
import { Steps } from 'antd';
import {Payment} from '../../Enum/Models'
import FlighSummary from '../../components/Flight/FlighSummary';
import SummaryHotel from '../../components/Accomodation/SummaryHotel';
import PaymentList  from "../../components/Common/PaymentList";
class Reserve extends React.Component{
    state={
        selectedBank:false,
        showError:false,
        isFlight:false,
        farePrice:null

    };
    componentDidMount() {
        window.scrollTo(0,0);
        this.props.editMessage(false);
        //
        let parameters=queryString.parse(this.props.location.search);
        //get payment
        this.props.getBankList(parameters);
        //get information
        let params=new URLSearchParams();
        params.append("reserveId",parameters.reserveId);
        (parameters.username)&&params.append("username",parameters.username);
        this.props.GetReserveById(params,parameters);
        let status=parseInt(parameters.status);
        if(status>0){
            if(this.props.hotelInfo!==null){
                this.props.bookhotel({reserveId:parameters.reserveId,userName:parameters.username});
            }else{
                let param=new URLSearchParams();
                param.append("reserveId",parameters.reserveId);
                this.props.getDirectTicketByID(param)
            }
            this.props.history.push(`/profile/details?userName=${parameters.username}&reserveId=${parameters.reserveId}`);

        }else if(status===0){
            this.setState({showError:true})
        }else{
            this.setState({showError:false})

        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.props.respond)&&(this.state.selectedBank)){
            this.props.editMessage(false);
            window.location.href=`${ServerAddress.Type}${ServerAddress.Payment}/en/Reserves/Payment/PaymentRequest?tokenId=${this.props.tokenId}`

        }
        if(this.props.flightInfo!==null && this.props.flightInfo!==prevProps.flightInfo){
            let perPrice=[];
            this.props.flightInfo.passengersFare.forEach(item=>{
                perPrice[item.passengerTypeQuantity.code]={totalTaxes:item.fare.totalTaxes,totalFare:item.fare.totalFare,baseFare:item.fare.baseFare}
            });
            let passengerInfo=this.props.flightInfo.passengers;
            passengerInfo.forEach(itemPass=>{
                itemPass["baseFare"]=perPrice[itemPass.code].baseFare;
                itemPass["totalTaxes"]=perPrice[itemPass.code].totalTaxes;
                itemPass["totalFare"]=perPrice[itemPass.code].totalFare;

            });
            this.setState({
                farePrice:passengerInfo
            })
        }
    }
    bankSelect=(id,param)=>{
        
        this.props.editMessage(false);
        this.setState({
            selectedBank:true
        });
        let reserveID=queryString.parse(this.props.location.search);
        let params={...reserveID,gatewayId:id,callbackUrl:`${ServerAddress.Type}${ServerAddress.localServer}/payment/reserve`,ipAddress:ServerAddress.localIP,formParams:{...param}};
        this.props.setBankID(params);
    };
    render() {
        const {Dictionary} = this.props;
        // let checkIn=null;
        // let checkOut=null;
        let night=0;
        let totalPrice=0;
        const {respond,banks,hotelInfo,flightInfo}=this.props;
        const { Step } = Steps;
        if(respond) {
            if (hotelInfo != null) {
                night=hotelInfo.date.duration;
                // let cashDateIN=new Date(hotelInfo.date.checkIn);
                // checkIn=cashDateIN.getFullYear()+"/"+(cashDateIN.getMonth()+1)+"/"+cashDateIN.getDate();
                // let cashDateOUT=new Date(hotelInfo.date.checkOut);
                // checkOut=cashDateOUT.getFullYear()+"/"+(cashDateOUT.getMonth()+1)+"/"+cashDateOUT.getDate();
                if(hotelInfo.accommodation){
                    hotelInfo.accommodation.rooms.forEach((item)=>{
                        totalPrice+=parseFloat(item.salePrice);
                    });
                }
            }
        }
        return(
            (!respond)?
                <div className="loading-outer-container">
                    <div>
                        <img src={siteLogo} className="loading-logo" alt="itours"/>
                        <div className="loading-inner-container">
                            <div className="item-1"></div>
                            <div className="item-2"></div>
                            <div className="item-3"></div>
                            <div className="item-4"></div>
                            <div className="item-5"></div>
                        </div>
                        <div className="text-center">
                            {Dictionary.ChooseYourPaymentMethod} ...
                        </div>
                    </div>                                            
                </div>                
                // loading
                :
                <main className="main-content">

                    <div className="page-padding">
                        <div className="page-container">
                            {(hotelInfo ||  flightInfo) &&
                                <>  
                                    <div>
                                        <Steps current={2}>
                                            <Step title={Dictionary.chooseFlightsOrHotels} />
                                            <Step title={Dictionary.enterThePassengersInformation} />
                                            <Step title={Dictionary.paymentTypeOptions} />
                                        </Steps>                                        
                                    </div>                                                                      
                                    <div className="flightdetails">
                                        <div className="sides-outer-holder">
                                            <div className="sides-holder reverse"> 
                                                <div className="small-side">
                                                    <div className="side-item full-width">
                                                        <div className="side-item-inner">
                                                            {flightInfo &&
                                                                <FlighSummary model={this.props.flightInfo.airItinerary.originDestinationOptions} fareInfo={this.props.flightInfo.passengersFare} totalPrice={this.props.flightInfo.flightFare.totalFare} currencyCode={this.props.currencyCode} showButton={false} feild={'fare'} />
                                                            }
                                                            {hotelInfo && (hotelInfo.accommodation !== undefined) &&
                                                                <SummaryHotel model={hotelInfo} currencyCode={this.props.currencyCode} showButton={false} totalPrice={totalPrice} night={night} />                                                       
                                                            }
                                                            <div className="box-border top-color-border orange margin-top bg-white">
                                                                <div className="colored">{Dictionary.pricesToRiseSoon}!</div>
                                                                <div className="font-12"> {Dictionary.bookNowToGetTheBestRateForYourCheckinDate}. </div>
                                                            </div>                                                                                                                      
                                                        </div>
                                                    </div>
                                                </div>                                                                                           
                                                <div className="large-side">
                                                    <div className="side-item full-width">
                                                        <div className="side-item-inner">
                                                            {this.state.showError &&
                                                            <div className="card card-padding bg-white box-border margin-bottom text-center">
                                                                <div className="margin-bottom">
                                                                    <img src={paymentError} alt="" className="payment-icon" />
                                                                    <br/>
                                                                    <strong className="purple margin-bottom">
                                                                        {Dictionary.paymentOperationFailed}.
                                                                    </strong>
                                                                </div>
                                                                <div className="margin-bottom">
                                                                    <strong>
                                                                        {Dictionary.pleaseTryAgain}
                                                                    </strong>
                                                                </div>
                                                                <p>
                                                                    {Dictionary.YouCanCallsupportNumberForFurtherTracking}.
                                                                </p>
                                                            </div>
                                                            }
                                                            {((hotelInfo && (hotelInfo.status===Payment.Pending)) || (flightInfo && ((flightInfo.reserveStatus===Payment.Pending)))) &&
                                                                <div className="card card-padding message-block success-message box-border margin-bottom">
                                                                    <h3 className="normal-title">
                                                                        <span className="itours-icon icon-large check-green margin-end-half" />
                                                                        {Dictionary.YourReservationIsReadyToBePaid}
                                                                    </h3>
                                                                    <p>
                                                                        {Dictionary.pleaseMakeAReservationAtTheSpecifiedTimeAfterWhichYourReservationWillBeSuspended}.
                                                                        {Dictionary.ifSoContactYourBackupToCompleteTheProcess}
                                                                    </p>
                                                                </div>
                                                            }

                                                            {flightInfo &&
                                                            <div className={"bg-white box-border card-padding Reserver information margin-bottom"}>
                                                                <h5 className="normal-title"> {Dictionary.ReserverInformartion}</h5>
                                                                <div className="float-row">
                                                                    <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.gender}: </span> {flightInfo.reserver.gender?Dictionary.male:Dictionary.female}</div>
                                                                    <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.firstName}: </span> {flightInfo.reserver.firstName}</div>
                                                                    <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.lastName}: </span> {flightInfo.reserver.lastName}</div>
                                                                    <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.email}: </span> {flightInfo.reserver.email}</div>
                                                                    <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.phoneNumber}: </span> {flightInfo.reserver.phoneNumber}</div>
                                                                </div>
                                                            </div>                                                  
                                                            }

                                                            {hotelInfo && (hotelInfo.accommodation !== undefined) && (hotelInfo.reservers) &&
                                                            <div className={"bg-white box-border card-padding Reserver information margin-bottom"}>
                                                                <h5 className="normal-title">{Dictionary.ReserverInformartion}</h5>
                                                                <div className="float-row">
                                                                    <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.gender}: </span> {hotelInfo.reserver.gender?Dictionary.male:Dictionary.female}</div>
                                                                    <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.firstName}: </span> {hotelInfo.reserver.firstName}</div>
                                                                    <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.lastName}: </span> {hotelInfo.reserver.lastName}</div>
                                                                    <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.email}: </span> {hotelInfo.reserver.email}</div>
                                                                    <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.phoneNumber}: </span> {hotelInfo.reserver.phoneNumber}</div>
                                                                </div>
                                                            </div>                                                    
                                                            }

                                                            <div className="choicePayment margin-bottom">
                                                                {((hotelInfo && (hotelInfo.status===Payment.Pending)) || (flightInfo && ((flightInfo.reserveStatus===Payment.Pending)))) &&
                                                                <div className="bankSelect">
                                                                    <h5 className="normal-title">{Dictionary.ChooseYourPaymentMethod}</h5>
                                                                    <div>
                                                                    {
                                                                    (!banks)?
                                                                    <div className="loading-outer-container">
                                                                        <div>
                                                                            <img src={siteLogo} className="loading-logo" alt="itours"/>
                                                                            <div className="loading-inner-container">
                                                                                <div className="item-1"></div>
                                                                                <div className="item-2"></div>
                                                                                <div className="item-3"></div>
                                                                                <div className="item-4"></div>
                                                                                <div className="item-5"></div>
                                                                            </div>
                                                                            <div className="text-center">
                                                                                  {Dictionary.loadingPaymentInformation}
                                                                                ...
                                                                            </div>
                                                                        </div>                                            
                                                                    </div>                                                                     
                                                                    :
                                                                    <PaymentList banks={banks} selectedBankthis={this.bankSelect}/>
                                                                    }
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div> 
                                                            <div className="card-padding bg-white box-border clearfix">
                                                                <span className="inline-block">{Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly} :</span>
                                                                <span dir="ltr" className="pull-end">
                                                                    <span className="margin-end-half itours-icon gray-phon-icon icon-small" />
                                                                    <span className="inline-block">
                                                                        {Dictionary.supportNumber}
                                                                    </span>
                                                                </span>
                                                            </div>                                    
                                                        </div>
                                                    </div>                                                
                                                </div>                                                                                            
                                            </div>
                                        </div> 
                                    </div>                                        
                                </>
                            }
                        </div>
                    </div>
                </main>
        )
    }
}
const mapStateToProps=(state)=>({
    reserveId:state.HotelSearch.reserveId,
    respond:state.VisualData.Responded,
    banks:state.PaymentData.banks,
    tokenId:state.PaymentData.tokenId,
    ///
    flightInfo:state.FlightSearch.flightSelected,
    hotelInfo:state.HotelSearch.selectedHotel,
    currencyCode:state.UserData.Currency,
    searchURL:state.HotelSearch.searchURL,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN


});
const mapActionToProps=(dispatch)=>({
    getBankList:(params)=>{dispatch(getBankList(params))},
    editMessage:(params)=>{dispatch(editMessage(params))},
    setBankID:(params)=>{dispatch(setBankID(params))},
    getReserveByID: (params)=>dispatch(getReserveByID(params)),
    getReserveByIdFlight:(params)=>dispatch(getReserveByIdFlight(params)),
    bookhotel:(params)=>dispatch(bookhotel((params))),
    GetReserveById:(params)=>dispatch(GetReserveById(params)),
    getDirectTicketByID:(params)=>dispatch(getDirectTicketByID(params))
});
export default connect(mapStateToProps,mapActionToProps)(Reserve)