import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {withRouter,Link} from 'react-router-dom';
import {validateFlight,getPassengerFlight} from "../../Actions/FlightAction";
import Form from "../../components/Common/Form";
import queryString from "query-string";
import {editMessage} from "../../Actions/VisualDataAction";
import Validation from "../../components/Common/Validation";
import DatePickerDrop from '../../components/Common/datePickerDrop';
import formIcon from "../../image/form-icon.svg";
import siteLogo from '../../image/logo.svg';
import { Steps } from 'antd';
import FlighSummary from '../../components/Flight/FlighSummary';
import {CountryPostalCode,CountryCode} from '../../Enum/Models';
import {toggleAccountWindow,SwitchAccountWindow} from "../../Actions/VisualDataAction";
class GetPassengerFilght extends Form{
    state={
        ...this.state,
        phoneNumber:null,
        countryCallingCode:null,
        passengerInformation:[],
        rangeAge:{
            ADT:{min:null,max:null},
            CHD:{min:null,max:null},
            INF:{min:null,max:null}
        },
        expDate:null

    };
    getPhoneNumber=(phonenumber)=>{
        if(phonenumber===undefined){
            phonenumber=null
        }else{
            if(phonenumber.length>11){
                this.setState({
                    countryCallingCode:phonenumber.substr(0,phonenumber.length-10),
                    phoneNumber:phonenumber.substr(phonenumber.length-10)
                })
            }
        }

    }
    getPassengers=()=>{
        this.props.editMessage(false);
        let parameters = queryString.parse(this.props.location.search);
        this.props.validateFlight({key:parameters.key} );
    };
    componentDidMount() {
        window.scrollTo(0,0);
        this.getPassengers();
        super.componentDidMount(this.FormDOM);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let passengerInfo=[];
        if(this.props.flightSelected!==prevProps.flightSelected){
            this.props.flightSelected.pricedItinerary.airItineraryPricingInfo.ptC_FareBreakdown.forEach((item)=>{
                for(let cnt=1;cnt<=item.passengerTypeQuantity.quantity;cnt++){
                    let passitem={type:item.passengerTypeQuantity.code};
                    passengerInfo.push(passitem);
                }
            });
            //set limit date
            let ADT={
                min:null,max:null
            };
            let CHD={
                min:null,max:null
            };
            let INF={
                min:null,max:null
            };
            let info=this.props.flightSelected.pricedItinerary.airItineraryPricingInfo;
            if(info.ptC_FareBreakdown.length===3){
                INF.min=info.ptC_FareBreakdown[2].passengerBirthDateRange.minBirhDate;
                INF.max=info.ptC_FareBreakdown[2].passengerBirthDateRange.maxBirthDate;
            }
            if(info.ptC_FareBreakdown.length>=2){
                CHD.min=info.ptC_FareBreakdown[1].passengerBirthDateRange.minBirhDate;
                CHD.max=info.ptC_FareBreakdown[1].passengerBirthDateRange.maxBirthDate;
            }
            if(info.ptC_FareBreakdown.length>=1){
                ADT.min=info.ptC_FareBreakdown[0].passengerBirthDateRange.minBirhDate;
                ADT.max=info.ptC_FareBreakdown[0].passengerBirthDateRange.maxBirthDate;
            }
            let dataFlight=this.props.flightSelected.pricedItinerary.airItinerary.originDestinationOptions;
            let expDate=dataFlight[dataFlight.length-1].flightSegments[dataFlight[dataFlight.length-1].flightSegments.length-1].arrivalDateTime;

               //
            this.setState({
                rangeAge:{ADT,CHD,INF},
                passengerInformation:passengerInfo,
                expDate:moment(expDate).add(1,'d')
            })
        }
        if(this.props.location.search!==prevProps.location.search){
            this.getPassengers();
        }
        if(this.props.reserveId!==prevProps.reserveId){
            this.props.history.push(`/payment/reserve?reserveId=${this.props.reserveId}&type=flight`);
        }
    }
    FormDOM=[];
    handleSubmit(event){
        event.preventDefault();
        super.validateForm(event,()=>{
            this.props.editMessage(false);
            let dataModelInput=this.state.DataInput;
            // dataModelInput.passengers.forEach((item)=>{
            //     if(item.gender==="true"){
            //         item.firstName="MR "+item.firstName;
            //     }else{
            //        item.firstName="MRS "+item.firstName;
            //     }
            // });

            if(dataModelInput.reserver.username===""){
                dataModelInput.reserver.username=dataModelInput.reserver.phoneNumber;
            }
            this.props.getPassengerFlight(dataModelInput);
        })
    }
    disabledDate=(type,current)=>{
        let min=this.state.rangeAge[type].min;
        let max=this.state.rangeAge[type].max;
        return  (current.isSameOrBefore(moment(min).add(1,'d'))) || (current.isSameOrAfter(moment(max).subtract(1,'d')))

    };
    disableDatePass=(current)=>{
        return (current.isSameOrBefore(moment(this.state.expDate)));
    };
    render() {
        const {Dictionary,LAN} = this.props;
        const {userData,flightSelected} =this.props;
        const { Step } = Steps;
        let userInfoStatus=((userData.id===undefined) || (userData.id===0));
        return(
            (!this.props.respond)?
            <div className="loading-outer-container">
                <div>
                    <img src={siteLogo} className="loading-logo" alt="itours"/>
                    <div className="loading-inner-container">
                        <div className="item-1"></div>
                        <div className="item-2"></div>
                        <div className="item-3"></div>
                        <div className="item-4"></div>
                        <div className="item-5"></div>
                    </div>
                    <div className="text-center">
                        {Dictionary.GettingPassengerInformation}
                        ...
                    </div>
                </div>                                            
            </div> 
            :
            <div className="flight getpassengers page-padding no-padding-mobile">
                {flightSelected &&
                <div className="page-container no-padding-mobile flightdetails ">
                    <div>
                        <Steps current={1}>
                            <Step title={Dictionary.chooseFlightsOrHotels} />
                            <Step title={Dictionary.enterThePassengersInformation} />
                            <Step title={Dictionary.paymentTypeOptions}  />
                        </Steps>
                    </div>
                    <div className="sides-outer-holder">
                        <form className="sides-holder" id={'reserveForm'}  onSubmit={this.handleSubmit.bind(this)} ref={(node) => this.FormDOM = node}  noValidate>
                            <div className="large-side">
                                <div className="side-item full-width">
                                    <div className="side-item-inner">
                                        <div className="box-border bg-white passengers-informations">
                                            {(!this.props.userData.id) &&                                       
                                                <div className="login-invite">
                                                <strong>
                                                    {Dictionary.alreadyAnItoursMember}
                                                </strong>
                                                {(LAN === "IR")?
                                                <div className="content">
                                                    <span> برای رزرو سریعتر </span>
                                                    <span className="content-link" onClick={()=>{this.props.SwitchAccountWindow(false);this.props.toggleAccountWindow()}}>وارد شوید</span>.
                                                </div>
                                                :(LAN === "AR")?
                                                <div className="content">
                                                    <span className="content-link" onClick={()=>{this.props.SwitchAccountWindow(false);this.props.toggleAccountWindow()}}>تسجيل الدخول </span>
                                                    <span> للحجز أسرع</span>.
                                                </div>                                                
                                                :
                                                <div className="content">
                                                    <span className="content-link margin-end-half" onClick={()=>{this.props.SwitchAccountWindow(false);this.props.toggleAccountWindow()}}>
                                                        {Dictionary.signIn}
                                                    </span>
                                                    <span> {Dictionary.now} </span>
                                                    <span> {Dictionary.and} </span>
                                                    <span> {Dictionary.speedUpYourBooking}!</span>                                                        
                                                </div>
                                                }

                                            </div>
                                            }
                                            <div className="get-passenger-form-holder padding-20">
                                                <h5 className="title">
                                                    {Dictionary.enterTravellerDetails}
                                                </h5>
                                                <div className="icon-hint-holder">
                                                    <img src={formIcon} className="icon" alt="" />
                                                    <div className="hint-inner">
                                                        <span>{Dictionary.EnterYourDetailsAsTheyAppearOnYourPassport}. </span>
                                                        <span> {Dictionary.useEnglishOnly}.</span>
                                                        
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div className="passengers-information">
                                                {this.state.passengerInformation.length && this.state.passengerInformation.map((item,index)=>{
                                                    return(
                                                        <div className="passenger-item" key={index}>
                                                            <div className="passenger-title">
                                                                
                                                                {(item.type==="ADT")&&<span className="itours-icon icon-large adult margin-end-half" />}
                                                                {(item.type==="CHD")&&<span className="itours-icon icon-large child margin-end-half" />}
                                                                {(item.type==="INF")&&<span className="itours-icon icon-large infant margin-end-half" />}
                                                                <span>{Dictionary.passenger} </span>
                                                                <span> {index+1} </span>
                                                                <span className="font-12">
                                                                    {(item.type==="ADT")&&<>({Dictionary.adult})</> }
                                                                    {(item.type==="CHD")&& <>({Dictionary.child})</>}
                                                                    {(item.type==="INF")&&<>({Dictionary.infant})</>}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <div className="float-row">
                                                                    <div className="col-small-12 col-medium-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">{Dictionary.firstName}</label>
                                                                            <div className="select-input-pair-holder">
                                                                                <select  className="form-select" name={`passengers[${index}].gender`}>
                                                                                    <option value="true">{Dictionary.male}</option>
                                                                                    <option value="false">{Dictionary.female}</option>
                                                                                </select>
                                                                                <input data-validator={true} type="text" className="form-input" name={`passengers[${index}].firstName`} />
                                                                            </div>
                                                                            <Validation model={this.state} feild="ValidatorList" name={`passengers[${index}].firstName`} />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-small-12 col-medium-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">{Dictionary.lastName}</label>
                                                                            <input type="text" className="form-input" data-validator={true}  name={`passengers[${index}].lastName`}/>
                                                                            <Validation model={this.state} feild="ValidatorList" name={`passengers[${index}].lastName`} />
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                                <div className="float-row">
                                                                    <div className="col-small-12 col-medium-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="" className="form-label">
                                                                                {Dictionary.birthdayMiladi}
                                                                            </label>
                                                                            {/*<DatePicker format={"YYYY-MM-DD"} defaultValue={moment(this.state.rangeAge[item.type].min)}   showTime={false}  data-validator={true} name={`passengers[${index}].birthDate`}  disabledDate={this.disabledDate.bind(this,item.type)}/>*/}
                                                                            {/*<Validation model={this.state} feild="ValidatorList" name={`passengers[${index}].birthDate`} />*/}
                                                                            <DatePickerDrop defaultValue={this.state.rangeAge[item.type].min} format={"YYYY-MM-DD"} name={`passengers[${index}].birthDate`} min={this.state.rangeAge[item.type].min} max={this.state.rangeAge[item.type].max} />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        (LAN==="IR")&&
                                                                        <div className="col-small-12 col-medium-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="" className="form-label">{Dictionary.nationality} </label>
                                                                                <select className="form-select" defaultValue={"IR"} name={`passengers[${index}].nationalityCode`} dir="ltr">
                                                                                    {CountryCode.map(item=><option value={item.value} key={item.value}>{item.code}</option>)}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    }


                                                                </div>
                                                                {(LAN === "IR") &&
                                                                <>
                                                                <div className="float-row">
                                                                    <div className="col-xsmall-12 col-small-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor=""
                                                                                   className="form-label">{Dictionary.passportNumber} </label>
                                                                            <input type="text" className="form-input"
                                                                                   data-validator={true}
                                                                                   name={`passengers[${index}].passportNumber`}/>
                                                                            <Validation model={this.state}
                                                                                        feild="ValidatorList"
                                                                                        name={`passengers[${index}].passportNumber`}/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xsmall-12 col-small-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="" className="form-label">{Dictionary.passportExpDate}</label>
                                                                            <DatePickerDrop
                                                                                defaultValue={this.state.expDate}
                                                                                format={"YYYY-MM-DD"}
                                                                                name={`passengers[${index}].passportExpireDate`}
                                                                                min={this.state.expDate}
                                                                                max={moment(this.state.expDate).add(10, 'years')}/>
                                                                            <Validation model={this.state}
                                                                                        feild="ValidatorList"
                                                                                        name={`passengers[${index}].passportExpireDate`}/>
                                                                        </div>
                                                                    </div>
                                                          
                                                                </div>
                                                                <div className="float-row">
                                                                    <div className="col-small-12 col-medium-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="nationalID" className="form-label">{Dictionary.nationalCode}</label>
                                                                            <input id="nationalID" type="text" className="form-input"
                                                                                   data-validator={true}
                                                                                   name={`passengers[${index}].nationalId`}/>
                                                                            <Validation model={this.state}
                                                                                feild="ValidatorList"
                                                                                name={`passengers[${index}].nationalId`}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                }
                                                            </div>
                                                            <input type="hidden" name={`passengers[${index}].code`} value={item.type}/>
                                                        </div>
                                                    )

                                                })}

                                                </div>

                                            </div>

                                        </div>
                                        <div className="get-passenger-form-holder padding-20 box-border bg-white">
                                            <h5 className="title">
                                                {Dictionary.enterContactDetails}
                                            </h5>
                                            <div className="icon-hint-holder">
                                                <img src={formIcon} className="icon" alt="" />
                                                <div className="hint-inner"> {Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail}. </div>
                                            </div>  
                                            <input  value={flightSelected.pricedItinerary.key} type="hidden" name={"key"}/>
                                            <div className="float-row">
                                                <div className="col-small-12 col-medium-6">
                                                    <div className="form-group">
                                                        <label className="form-label">{Dictionary.firstName}</label>
                                                        <div className="select-input-pair-holder">
                                                            <select  className="form-select" name={"reserver.gender"} defaultValue={(userInfoStatus)?userData.gender:true}>
                                                                <option value={true}>{Dictionary.male}</option>
                                                                <option value={false}>{Dictionary.female}</option>
                                                            </select>
                                                            <input className="form-input" data-validator={true} placeholder={Dictionary.firstName} name="reserver.firstName" type="text" defaultValue={(userInfoStatus) ? "" : userData.firstName} />
                                                        </div>
                                                        <Validation model={this.state} feild="ValidatorList" name='reserver.firstName' />

                                                    </div>
                                                </div>
                                                <div className="col-small-12 col-medium-6">
                                                    <div className="form-group">
                                                        <label className="form-label">{Dictionary.lastName}</label>
                                                        <input className="form-input" data-validator={true} placeholder={Dictionary.lastName} name="reserver.lastName" type="text" defaultValue={(userInfoStatus) ? "" : userData.lastName} />
                                                        <Validation model={this.state} feild="ValidatorList" name='reserver.lastName' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="float-row">
                                                <div className="col-small-12 col-medium-6">
                                                    <div className="form-group">
                                                        {
                                                            (LAN==="IR")?
                                                                <label htmlFor="" className="form-label">{Dictionary.email}({Dictionary.optional})</label>
                                                                :
                                                                <label htmlFor="" className="form-label">{Dictionary.email}</label>
                                                        }
                                                        <input data-validator={LAN!=="IR"}  className="form-input placeholder-right"  placeholder={Dictionary.enterYourEmail} name="reserver.email" type="email" defaultValue={(userInfoStatus) ? "" : userData.emailAddress}/>
                                                        <Validation model={this.state} feild="ValidatorList" name='reserver.email' />
                                                    </div>                                            
                                                </div>
                                                <div className="col-small-12 col-medium-6">
                                                    <div className="form-group">
                                                        {
                                                            (LAN!=="IR")?
                                                                <label htmlFor="" className="form-label">{Dictionary.phoneNumber}({Dictionary.optional})</label>
                                                                :
                                                                <label htmlFor="" className="form-label">{Dictionary.phoneNumber}</label>
                                                        }

                                                        <div className="select-input-pair-holder " dir="ltr">
                                                            <select className="form-select" name={"reserver.countryCallingCode"} defaultValue={(LAN==="IR")?"0098":"0047"} dir="ltr">
                                                                {CountryPostalCode.map(item=><option key={item.code} value={item.value}>{item.code}</option>)}
                                                            </select>

                                                            <input  className="form-input placeholder-right"  data-validator={LAN==="IR"}     placeholder="9XXXXXXXXX" name="reserver.phoneNumber" type="tel"/>

                                                        </div>
                                                        <Validation model={this.state} feild="ValidatorList" name='reserver.phoneNumber' />
                                                    </div>
                                                    {
                                                        (userInfoStatus) ?
                                                            <>
                                                                <input type="hidden" name="reserver.userId" value={userData.id}/>
                                                                <input type="hidden" name="reserver.username" defaultValue={this.state.username}/>
                                                            </>
                                                            :
                                                            <>
                                                                <input type="hidden" name="reserver.userId" value={null} />
                                                                <input type="hidden" name="reserver.username" defaultValue={null} />
                                                            </>
                                                    }                                            
                                                </div>                                                
                                            </div>

                                        

                                            <div className="margin-bottom">
                                                <div className="checkbox-holder has-link">
                                                    <input className="custom-checkbox" type="checkbox" data-validator={true} name={`acceptRuls`}/>


                                                    <label className="checkbox-label font-14">
                                                        {(LAN === "IR")?
                                                        <>
                                                            <a href={"/terms"} className="content-link" >قوانین و مقررات آیتورز </a>                                                        
                                                            را خوانده ام و با آن موافق هستم.
                                                        </>
                                                        :
                                                        <>
                                                            {Dictionary.iHaveReadAndAgreeToThe}
                                                            <Link to={"/terms"} className="content-link">
                                                                {Dictionary.termsAndConditions}
                                                            </Link>
                                                        </>
                                                        }
                                                    </label>
                                                    <Validation model={this.state} feild="ValidatorList" name={`acceptRuls`} />
                                                </div>
                                            </div>                                     

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="small-side">
                                <aside className={"side-item full-width"}>
                                    <div className="side-item-inner">
                                        <FlighSummary showButton={true} model={flightSelected.pricedItinerary.airItinerary.originDestinationOptions}
                                            currencyCode={this.props.currencyCode}
                                            fareInfo={flightSelected.pricedItinerary.airItineraryPricingInfo.ptC_FareBreakdown}
                                            totalPrice={flightSelected.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare}
                                            feild={"passengerFare"}
                                        />
                                    </div>
                                </aside>
                            </div>
                        </form>
                    </div>
                </div>
                }
            </div>

        )
    }
}
const dispatchToProps=(dispatch)=>({
    validateFlight:(key)=>dispatch(validateFlight(key)),
    editMessage:(param)=>{dispatch(editMessage(param))},
    getPassengerFlight:(param)=>{dispatch(getPassengerFlight(param))},
    toggleAccountWindow:()=>{dispatch(toggleAccountWindow())},
    SwitchAccountWindow:(mode)=>{dispatch(SwitchAccountWindow(mode))}
});
const stateToProps=(state)=>({
    flightSelected:state.FlightSearch.flightSelected,
    respond:state.VisualData.Responded,
    userData:state.UserData.userData,
    currencyCode:state.UserData.Currency,
    reserveId:state.FlightSearch.reserveId,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
const routerBind=withRouter(GetPassengerFilght);
export default connect(stateToProps,dispatchToProps)(routerBind);