import React from 'react';
import queryString from "query-string";
import {connect} from "react-redux"
import {replaceAll} from "../../Enum/Common";
import Form from '../../components/Common/Form';
import Validation from "../../components/Common/Validation";
import {resetEmail} from "../../Actions/UserAction"
import successIcon from "../../image/check.svg";
import {Link} from "react-router-dom";
import {showError} from "../../Actions/VisualDataAction";
class ForgotPassword extends Form{
    FormDOM=null;
    state={
        ...this.state,
        code:null,
        userId:null,
        sendingLoading:false,
        message:null,
        sendingSuccess:false,
        resultshow:false,
    };
    componentDidMount() {
        window.scrollTo(0,0);
        super.componentDidMount(this.FormDOM);
        let parameters=queryString.parse(this.props.location.search);
        this.setState({
            code:replaceAll(parameters.code,' ','+') ,
            userId:parameters.userId
        });

    }
    handleSubmit(event){
        this.setState({
            sendingLoading:false,
            resultshow:false

        });
        super.validateForm(event,()=>{
            let param=this.state.DataInput;
            param={...param,code:this.state.code,userId:this.state.userId};
            if(param.password===param.passwordConfrim){
                 this.props.resetEmail(param);
                // User.resetPassword(param).then((response)=>{
                //     this.setState({
                //         message:'Your password is changed',
                //         sendingLoading:false,
                //         sendingSuccess:true,
                //         resultshow:true
                //
                //     })
                // }).catch((error)=>{
                //     this.setState({
                //         message:error.validationSummary.message,
                //         sendingLoading:false,
                //         sendingSuccess:false,
                //         resultshow:true
                //     })
                // });
            }else{
                this.props.showError({visibility:true,content:
                    /* 'کلمه عبور ها برابر نیستند' */
                    'Passord er ikke like'
            })
            }

        })
    }
    render() {
        const {Dictionary} = this.props;
        let {error,responded} = this.props;
        return(
            <div className="section-vertical-padding">
                <div className="page-container">
                    <h2 className="page-title">
                    {Dictionary.resetPassword}
                    </h2>

                    {(responded)?
                        <div className="card-padding bg-white box-shadow">
                            <div className="margin-bottom">
                                <img src={successIcon} className="success-icon margin-end" alt=""/>
                                <strong>
                                    {Dictionary.operationDoneSuccessfully}
                                </strong>
                            </div>
                            <div className="buttonContainer clearfix">
                                <Link to={"/"} className="button blue-btn pull-left">{Dictionary.ok}</Link>
                            </div>
                        </div>
                    :
                        <div>
                            {(error.visibility)?
                                <div className="card-padding bg-white box-shadow">
                                    <div className="margin-bottom">
                                        <strong>{error.content}</strong>
                                    </div>
                                    <div className="buttonContainer clearfix">
                                        <button type="button" className="button blue-btn pull-left" onClick={()=>{this.props.hideError(false,null)}}>{Dictionary.ok}</button>
                                    </div>
                                </div>                                
                                :
                                <div className="card-padding bg-white box-shadow">
                                    <p>
                                        {Dictionary.enterNewPassword}
                                     </p>
                                    <form onSubmit={this.handleSubmit.bind(this)} ref={(node) => this.FormDOM=node} noValidate>
                                        <div>
                                            <div className="float-row">
                                                <div className="col-small-12 col-medium-6 col-large-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            {Dictionary.newPassword}
                                                        </label>
                                                        <input className="form-input" data-validator={true}  placeholder="Angi det nye passordet"  name="password" type="Password" />
                                                        <Validation model={this.state} feild="ValidatorList" name='password' />
                                                    </div>
                                                </div>
                                                <div className="col-small-12 col-medium-6 col-large-4">                                            
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            {Dictionary.newPasswordRepeat}:
                                                        </label>
                                                        <input className="form-input" data-validator={true} placeholder="Angi det nye passordet"  name="passwordConfrim" type="Password" />
                                                        <Validation model={this.state} feild="ValidatorList" name='passwordConfrim' />
                                                    </div>                                                    
                                                </div>
                                            </div>

                                            {
                                                (this.state.sendingLoading)?
                                                    <div className="loadingText">
                                                        {Dictionary.pleaseWait}
                                                         ...
                                                    </div>
                                                    :
                                                    <button className="button blue-btn margin-top" type="submit">
                                                        {Dictionary.saveChanges}
                                                    </button>
                                            }
                                        </div>
                                    </form>
                                    {(this.state.resultshow)?
                                        <div className="resultContainer">
                                            <div className={(this.state.sendingSuccess)?"message success":"message faill"}>{this.state.message}</div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}
const stateMapProps=(state)=>({
  responded:state.VisualData.Responded,
  error:state.VisualData.error,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN

});
const dispatchMapProps=(dispatch)=>({
    resetEmail:(param)=>dispatch(resetEmail(param)),
    hideError:(params)=>dispatch(showError(params))
})
export default  connect(stateMapProps,dispatchMapProps)(ForgotPassword)