import React from 'react';
import Form from '../Common/Form';
import PropTypes from 'prop-types';
import Validation from '../Common/Validation';
import {connect} from 'react-redux';
import {
    toggleAccountWindow,
    toggleErrorWindow,
    showError,
    editMessage,
    SwitchAccountWindow
} from '../../Actions/VisualDataAction';
import {createUser,loginUser,registerUser,recoverEmail} from '../../Actions/UserAction';
import {toggleVarification} from '../../Actions/VisualDataAction';
import close from '../../image/close.svg';

class Account extends Form{
    state={
        ...this.state,
        forgetPassword:false,
        MessageContainerShow:false,
        MessageContainerContent:''
    };
    static propTypes={
        mode:PropTypes.bool.isRequired,
    };
    FormDOM=null;
    closeError=()=>{
        this.props.hideError(false,null);
        this.state.Loading=false;
    };
    forgetPassword=()=>{
        this.setState({
            forgetPassword:true
        })
    };
    componentDidMount(){
        this.props.editMessage(false);
        super.componentDidMount(this.FormDOM);
        //set message false
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.error!==prevProps.error){
            this.setState({
                Loading:false
            })
        }
    }

    handleSubmit(event){
        if(this.props.mode){
            super.validateForm(event,()=>{
                this.props.registerUser(this.state.DataInput);
            })
        }
        else{//login
            if(this.state.forgetPassword){
                super.validateForm(event,()=>{
                    this.props.recoverEmail(this.state.DataInput)
                    // Users.recoverPassword(this.state.DataInput).then((data)=>{
                    //     this.setState({
                    //
                    //         MessageContainerShow:true,
                    //         MessageContainerContent:'you password link send to your email'
                    //     })
                    // }).catch((error)=>{
                    //     if(error.code===2){
                    //         this.setState({
                    //
                    //             MessageContainerShow:true,
                    //             MessageContainerContent:'Error in Server request please check your internet connection'
                    //         })
                    //     }else if(error.code===3){
                    //         this.setState({
                    //             Loading:false,
                    //             ValidatorSummary:error.validatorSummary
                    //         })
                    //     }
                    //
                    //
                    // })
                })
            }else{
                super.validateForm(event,()=>{
                    this.props.loginUser(this.state.DataInput);
                })
            }

        }
    }
    render(){
        const {Dictionary,LAN} = this.props;
        const {check,respond,SwitchAccountWindow} = this.props;
        return(
            <div className={"accountWindow"}>
                <div className="page-container">
                    <div className="Form">
                        <div className="login-tabs-holder">
                            <button type="button" className={!this.props.mode?"tab-btn active":"tab-btn"} onClick={() => { SwitchAccountWindow(false) }}>{Dictionary.login}</button>
                            <button type="button" className={this.props.mode?"tab-btn active":"tab-btn"}  onClick={() => { SwitchAccountWindow(true) }}>{Dictionary.registration}</button>
                        </div>
    {/*                     {(error.visibility)?
                            <div className="showMessage">
                                <div  className="content">
                                    {error.content}
                                </div>
                                <div className="buttonContainer">
                                    <button type="button" className="button blue-btn" onClick={()=>{this.props.hideError({visibility:false,content:null});this.setState({Loading:false})}}>بازگشت</button>
                                </div>
                            </div>
                            :
                            null
                        } */}
                        {
                            (respond)?
                                <div className="section-vertical-padding text-center bg-white">
                                    <div className="margin-bottom">
                                        <div>
                                            <span className="check-green itours-icon icon-large" />
                                        </div>
                                        <strong> {Dictionary.operationDoneSuccessfully}</strong>
                                    </div>
                                    <div className="buttonContainer">
                                        <button type="button" className="button blue-btn" onClick={()=>{this.props.check()}}>{Dictionary.ok}</button>
                                    </div>
                                </div>
                                :
                                <div className="register">
                                    <div className="validatorSummary">
                                        {this.state.ValidatorSummary.map((item,key)=><div key={key} className="item">{item}</div>)}
                                    </div>
                                    <div className="close-btn">
                                        <img src={close} alt="close" className="closeBtn"  onClick={()=>check()}/>
                                    </div>
                                    <form className="logon-form" onSubmit={this.handleSubmit.bind(this)} ref={(node) => this.FormDOM=node} noValidate>
                                        <div>
                                            <div className="form-group">
                                                <label className="form-label">{Dictionary.email}</label>
                                                <input className="placeholder-right form-input en-input-text" data-validator={true} placeholder={Dictionary.enterYourEmail}  name="emailOrPhoneNumber" type="email" />
                                                <Validation model={this.state} feild="ValidatorList" name='emailOrPhoneNumber' />
                                            </div>
                                        </div>
                                        {(!this.state.forgetPassword || this.props.mode)?
                                            <div className="form-group">
                                                <label className="form-label">{Dictionary.password} </label>
                                                <input  className="form-input placeholder-right en-input-text" data-validator={true} placeholder={Dictionary.enterYourPassword} name="password" type="Password" />
                                                <Validation model={this.state} feild="ValidatorList" name='password' />
                                            </div>
                                            :
                                            null
                                        }
                                        {(!this.state.Loading)?
                                            <div className="margin-bottom">
                                                {(this.props.mode)?
                                                    <div>
                                                        <button type="submit" className="button blue-btn"> {Dictionary.register} </button>
                                                    </div>
                                                    :
                                                    <div>

                                                        {
                                                            (this.state.forgetPassword)?
                                                            <>
                                                            <button type="submit" className="button blue-btn margin-bottom margin-end" >{Dictionary.sendResetLink}</button>
                                                            <button type="button" className="button grey-btn" onClick={()=>{this.setState({forgetPassword:false})}} >{Dictionary.returnToLogin}</button>
                                                            </>
                                                            :
                                                            <div>
                                                                    
                                                                {(LAN === "IR")?
                                                                <div>
                                                                {Dictionary.ForgetYourPassword}؟
                                                                روی
                                                                <b className="link-style-btn" onClick={this.forgetPassword}> {Dictionary.here} </b>
                                                                    کلیک کنید
                                                                </div>
                                                                :
                                                                <div>
                                                                    {Dictionary.ForgetYourPassword}?
                                                                    {Dictionary.click}
                                                                    <b className="link-style-btn" onClick={this.forgetPassword}> {Dictionary.here} </b>
                                                                </div>
                                                                }

                                                                
                                                                <button type="submit" className="button blue-btn">{Dictionary.login}</button>
                                                            </div>
                                                        }

                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div>
                                                <p>
                                                {Dictionary.signingIn}
                                                <span className="dots-loading">
                                                    <span className="dot">.</span>
                                                    <span className="dot">.</span>
                                                    <span className="dot">.</span>
                                                </span>
                                                {/* Please wait ... */}
                                                </p>
                                            </div>
                                        }

                                    </form>
{/*                                     {(this.props.mode)?

                                        <div className="command">
                                            Allerede registrert?                                            
                                            Klikk <b className="link-style-btn" onClick={() => { SwitchAccountWindow(false) }}> her </b> for Logg inn
                                        </div>
                                        :
                                        <div className="command">
                                            Har du ikke en konto? 
                                            Klikk <b  className="link-style-btn" onClick={() => { SwitchAccountWindow(true) }}> her </b> for registrere
                                        </div>
                                    } */}
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    error:state.VisualData.error,
    respond:state.VisualData.Responded,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
const MapDispatch=(dispatch)=>({
    check:()=>dispatch(toggleAccountWindow()),
    createUser:(userInfo)=>dispatch(createUser(userInfo)),
    showError:(text)=>dispatch(toggleErrorWindow(text)),
    toggleVarification:(data)=>dispatch(toggleVarification(data)),
    loginUser:(params)=>dispatch(loginUser(params)),
    registerUser:(params)=>dispatch(registerUser(params)),
    hideError:(params)=>dispatch(showError(params)),
    recoverEmail:(param)=>dispatch(recoverEmail(param)),
    editMessage:(param)=>dispatch(editMessage(param)),
    SwitchAccountWindow:(mode)=>{dispatch(SwitchAccountWindow(mode))}
});
export default connect(mapStateToProps,MapDispatch)(Account)