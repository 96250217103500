import React from 'react';
import {connect} from 'react-redux';
import queryString from "query-string";
import {searchFlight,searchFlightAsync,sortFlightSearch} from "../../Actions/FlightAction";
import FilterForm from "../../components/Flight/FilterForm";
import ListItem from "../../components/Common/ListItem.js";
import {Flight, Travel} from "../../Enum/Models";
import sortIcon from '../../image/vertical2arrow.svg';
import siteLogo from '../../image/logo.svg';
import _ from 'lodash';
//import image
import SearchForm from "../../components/Flight/SearchForm";
import AirlineFilter from "../../components/Flight/AirlineFilter.js";
import Page from "../../components/Common/Page";
import danger from "../../image/danger.svg";
import {editMessage} from "../../Actions/VisualDataAction";
class FlightResult extends React.Component {
    state={
        result:null,
        error:null,
        loadingShow:true,
        first:0,
        last:9,
        showfilter:false,
        showsearch:false,
        sortPrice:false,
        sortName:0,
        sortRate:0,
        tripType:null

    };
    handleChangeValue(first,last){
        this.setState({first:first,last:last});
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.search!==prevProps.location.search){
            this.setState({
                loadingShow:false,
                result:null
            },()=>{
                this.props.searchFlightAsync(new Flight());
                this.props.editMessage(false);
                this.getFlights();
            });
        }
        if(this.props.respond!==prevProps.respond){
            this.setState({
                loadingShow:!this.props.respond,
                result:this.props.flights
            })
        }
        if(this.props.flights!==prevProps.flights){
            this.setState({
                result:this.props.flights
            })
        }
        if(this.state.result!==prevState.result){
            this.setState({
                first:0,
                last:9
            })
        }
    }
    componentDidMount() {
        window.scrollTo(0,0);
        this.props.searchFlightAsync(new Flight());
        this.props.editMessage(false);
        this.getFlights();
        //this.props.fillHotelSearchURL(window.location.href);
    }
    handleSort=(type)=>{
        if(type==="totalPrice"){
            this.setState((prevState)=>({
                sortPrice:!prevState.sortPrice
            }),()=>{
                this.props.sortFlightSearch({'typeSort':type,isReverse:this.state.sortPrice})
            });
        }
    }
    getFlights = () =>{
       let parameters = queryString.parse(this.props.location.search);
        parameters.adult=parseInt(parameters.adult);
        parameters.child=parseInt(parameters.child);
        parameters.infant=parseInt(parameters.infant);
        parameters.cabinClassCode=parseInt(parameters.cabinClassCode);
        parameters.departureDateTimes=parameters.departureDateTimes.split("|");
        parameters.originCodes=parameters.originCodes.split("|");
        parameters.originNames=parameters.originNames.split("|");
        parameters.destinationCodes=parameters.destinationCodes.split("|");
        parameters.destinationNames=parameters.destinationNames.split("|");
        if(parameters.airTripType==="RoundTrip"){
            parameters.originCodes.push(parameters.destinationCodes[0]);
            parameters.destinationCodes.push(parameters.originCodes[0])
        }
        this.setState({
            tripType:parameters.airTripType
        });
        this.props.searchFlight(parameters);
    };
    render() {
        const {Dictionary} = this.props;
        return(

            (this.state.loadingShow)?
                <div className="loading-outer-container">
                    <div>
                        <img src={siteLogo} className="loading-logo" alt="itours"/>
                        <div className="loading-inner-container">
                            <div className="item-1"></div>
                            <div className="item-2"></div>
                            <div className="item-3"></div>
                            <div className="item-4"></div>
                            <div className="item-5"></div>
                        </div>
                        <div className="text-center">
                            {Dictionary.SearchingBestPrices} ...
                        </div>
                    </div>                                            
                </div>
             : (this.state.result===null)?
             <div className="flight">
                <div className="page-container">
                    <div className="section-vertical-padding">
                        <h2 className="page-title">
                            {Dictionary.noFlightFoundForYourRequest}.
                            <div className="page-subtitle">
                                {Dictionary.pleaseTryAgain}
                            </div>
                        </h2>

                        <div className="clearfix alert-cart card">
                            <img src={danger} className="alert-icon" alt="danger-icon"/>
                            <div className="alert-content">
                                <h6 className="alert-title">
                                    {Dictionary.please}, 
                                    {Dictionary.noFlightFoundForYourRequest}.
                                </h6>
                                <p className="no-margin">
                                    {Dictionary.pleaseTryAgain}.
                                </p>
                            </div>
                        </div>
                    </div>
                 </div>
             </div>

             :
             <div className="flight">
                <div className="page-container">
                    <div className="avails-row float-row flight-avails-row">
                        <button type="button" className="filter-responsive-btn" onClick={()=>{this.setState((prevState)=>({showfilter:!prevState.showfilter}))}}>
                            {Dictionary.filters}
                        </button>
                        {
                            (this.state.result.length>0)?
                                <div className="airline-filter-holder">
                                    <AirlineFilter/>
                                </div>
                            :null
                        }
                        <div className="col-small-12 col-medium-3">
                            <div className="sidebar">
{/*                                         <div className="sidebar-item clearfix">
                                    <span className="results-number-holder">
                                        <b> {<span>{this.state.result && this.state.result.length}</span>} </b> flyreise funnet
                                    </span>
                                    <button type="button" className="button grey-btn pull-end filter-responsive-btn" onClick={()=>{this.setState((prevState)=>({showfilter:!prevState.showfilter}))}}>
                                        Filtre
                                    </button>
                                </div> */}
                                <div className={(this.state.showfilter)?"showfilter filters-holder":"filters-holder"}>
                                    <FilterForm />
                                    <button type="button" className="button red-btn filter-close-btn" onClick={()=>{this.setState((prevState)=>({showfilter:!prevState.showfilter}))}}>{Dictionary.close}</button>
                                </div> 
                            </div>

                        </div>
                        <div className="col-small-12 col-medium-9">
                            <div>
                                <SearchForm inline={true}
                                originCity={this.state.result && this.state.result[0] && this.state.result[0].airItinerary[0].flightSegments[0].departureAirport.cityName}
                                destinationCity={this.state.result && this.state.result[0] &&  _.last(this.state.result[0].airItinerary[0].flightSegments).arrivalAirport.cityName} />
                            </div>
                            {
                                (this.state.result.length>0)?
                                <div className="avails-holder">
                                    <div className="results-sorting-block row">
                                        <div className="sort-container">
                                            <img src={sortIcon} alt={"sort"} title={Dictionary.sortBy} />
                                            <label className="margin-end-light" htmlFor={"sort"}>{Dictionary.sortBy}: </label>
                                            <button id={"sort"} type="button" className={(this.state.sortPrice)?"button results-sort-btn":"button results-sort-btn low"} onClick={()=>{this.handleSort("totalPrice")}}> {(this.state.sortPrice?Dictionary.theMostExpensive:Dictionary.cheapest)} </button>
                                        </div>
                                    </div>
                                    <ListItem data={this.state.result} last={this.state.last} start={this.state.first} type={Travel.flight} tripType={this.state.tripType}/>
                                    {(this.state.result.length>10)&&<Page length={this.state.result.length} itemCurrent={this.state.first} itemPerPage={10}  onChangeValue={this.handleChangeValue.bind(this)}  />}

                                </div>
                                :
                                <div className="section-vertical-padding text-center">
                                    <div>
                                        <span className="itours-icon icon-xlarge error-icon" />
                                    </div>  
                                    <h5 className="normal-title">                            
                                        {Dictionary.noFlightFoundForYourRequest}.
                                        
                                        <div className="page-subtitle">
                                            {Dictionary.pleaseTryAgain}.
                                        </div>
                                    </h5> 
                                </div> 

                            }
                        </div>

                    </div>                     
                 </div>
             </div>            

        )
    }
}
const mapStateToProps=(state)=>({
    error:state.VisualData.error,
    respond:state.VisualData.Responded,
    flights: state.FlightSearch.flight,
    backupFlight: state.FlightSearch.backupFlight,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN,

});
const mapDispatchToProps=(dispatch)=>({
    searchFlight:(param)=>{dispatch(searchFlight(param))},
    searchFlightAsync:(param)=>{dispatch(searchFlightAsync(param))},
    editMessage:(param)=>{dispatch(editMessage(param))},
    sortFlightSearch:(param)=>{dispatch(sortFlightSearch(param))}

});
export default  connect(mapStateToProps,mapDispatchToProps)(FlightResult);