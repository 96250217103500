import React from 'react';
import {connect} from 'react-redux';
import {filterSelect,ApplyFilterFlight} from '../../Actions/FlightAction';
import Price from "../Common/Price";
import {FilterType,FilterName} from '../../Enum/Models';


class AirlineFilter extends React.Component{
    handleSelect=(evt,type,filterType)=>{
        if(evt.target.type==="checkbox"){
            let value=evt.target.value;
            if(evt.target.checked){
                this.props.filterSelect({isAdd:true,type,value,filterType});
            }else{
                this.props.filterSelect({isAdd:false,type,value,filterType});

            }
        }else{
            let textInput=evt.target.value;
            this.props.filterSelect({isAdd:true,type,textInput,filterType});
        }
        this.props.ApplyFilterFlight();

    };
    render() {
        const {Dictionary}=this.props;
        const {airlinePrice}=this.props.filtershow;
        return(
            <>
                {
                    (airlinePrice)?
                        <div className={"clearfix"}>
                            <div className={"airline-table-header pull-start margin-end-light"}>
                                <div className="airline">{Dictionary.airline}</div>
                                <div className="cell">{Dictionary.direct}</div>
                                <div className="cell">1+ {Dictionary.stop}</div>
                            </div>
                            <div className="airline-table-holder">
                                <div className="airline-filter-row">
                                    {
                                        airlinePrice.map((item,index)=>{
                                            return(
                                            <div className="airline-table-cell" key={index}>
                                                <div  className={"airline-filter-item"}>
                                                    <img 
                                                    className="airline-logo" 
                                                    alt={item.noneStop?item.noneStop.airItinerary[0].flightSegments[0].marketingAirline.name:item.multiStop.airItinerary[0].flightSegments[0].marketingAirline.name}
                                                    title={item.noneStop?item.noneStop.airItinerary[0].flightSegments[0].marketingAirline.name:item.multiStop.airItinerary[0].flightSegments[0].marketingAirline.name}
                                                    src={item.noneStop?item.noneStop.airItinerary[0].flightSegments[0].marketingAirline.photoUrl:item.multiStop.airItinerary[0].flightSegments[0].marketingAirline.photoUrl} />
                                                    {
                                                        item.noneStop?
                                                        <div className="cell">
                                                            <div className={"checkbox-holder ltr"}>
                                                                <input className="custom-checkbox" id={"none" + index} type="checkbox" value={item.noneStop.key} onChange={(e)=>{this.handleSelect(e,FilterName.airlinePrice,FilterType.Union)}}/>
                                                                <label className="checkbox-label" htmlFor={"none" + index}><Price currency={this.props.Currency}>{item.noneStop.priceInfo.totalFare}</Price></label>
                                                            </div>
                                                        </div>
                                                            :
                                                        <div className="cell"> - </div>
                                                    }
                                                    {
                                                        item.multiStop?
                                                        <div className="cell">
                                                            <div className={"checkbox-holder ltr"}>
                                                                <input className="custom-checkbox" id={"multi" + index} type="checkbox" value={item.multiStop.key} onChange={(e)=>{this.handleSelect(e,FilterName.airlinePrice,FilterType.Union)}}/>
                                                                <label className="checkbox-label" htmlFor={"multi" + index}><Price currency={this.props.Currency}>{item.multiStop.priceInfo.totalFare}</Price></label>
                                                            </div>
                                                        </div>
                                                            :
                                                        <div className="cell"> - </div>
                                                    }

                                                </div>
                                            </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <span>loading</span>
                }
            </>
        )
    }
}
const dispatchToProps=(dispatch)=>({
    filterSelect:(data)=>dispatch(filterSelect(data)),
    ApplyFilterFlight:()=>dispatch(ApplyFilterFlight())

});
const stateToProps=(state)=>({
    filtershow:state.FlightSearch.filterShow,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN,
    Currency:state.UserData.Currency
});

export default connect(stateToProps,dispatchToProps)(AirlineFilter)