import React from 'react';
import {connect} from "react-redux";
class ContactUS extends React.PureComponent{
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        const {Dictionary,LAN} = this.props;
        return (

            <div className="section-vertical-padding bg-white">
                <div className="page-container">
                    <h2 className="page-title"> {Dictionary.contactUs} </h2>
                    <div className="float-row">
                        <div className="col-small-12 col-medium-7">
                            <div className="margin-bottom">                                
                                <p>
                                    {Dictionary.contactP1}
                                </p>
                                <br/>
    {/*                             <h4 className="normal-title">ایران - تهران</h4>
                                <div className="contact-row">
                                    <span className="margin-end-half itours-icon red-phon-icon icon-small mirror-rtl" />
                                    <span dir="ltr" className="en-font">(+98) 021 88712609</span>
                                </div>
                                <div className="contact-row">
                                    <span className="margin-end-half itours-icon red-email-icon icon-small" />
                                    <a href="mailto:info@sunhotel.ir" dir="ltr" className="en-font content-link">info@sunhotel.ir</a>
                                </div>
                                <div className="margin-bottom contact-row">
                                    <span className="margin-end-half itours-icon red-email-icon icon-small" />
                                    <span>تهران،خالد اسلامبولی،نبش خیابان سوم،ساختمان 222 پلاک 27 طبقه دوم واحد 2</span>
                                </div>
                                <br/> */}
                                {/* <h4 className="normal-title">نروژ - اسلو</h4> */}

                                <div className="contact-row">
                                    <span className="margin-end-half itours-icon red-phon-icon icon-small mirror-rtl" />
                                    <span dir="ltr" className="en-font">
                                    {
                                        (LAN === "IR")?
                                        "(+98) 21 91008091"
                                        :
                                        "(+47)22112744"
                                    }
                                    </span>
                                </div>
                                <div className="contact-row">
                                    <span className="margin-end-half itours-icon red-email-icon icon-small" />
                                    {
                                        (LAN === "IR")?
                                            <a href="mailto:info@itours.ir" dir="ltr" className="en-font content-link">
                                                info@itours.ir
                                            </a>
                                        :
                                        <a href="mailto:info@itours.no" dir="ltr" className="en-font content-link">
                                            info@itours.no
                                        </a>
                                    }
                                </div>
                                <div className="contact-row">
                                <span className="margin-end-half itours-icon red-address-icon icon-small" />
                                    <span>
                                    {
                                        (LAN === "IR")?
                                        "تهران ، خیابان بهشتی ، خ وزرا پلاک ۲۷ ، واحد ۲۲"
                                        :
                                        "(0183) - Torggata 31"
                                    }
                                    </span>
                                </div>

                            </div>
                        </div>                        
                        <div className="col-small-12 col-medium-5">
                            <div className="card margin-bottom">
                                <form>
                                    <div className="float-row">
                                        <div className="col-small-12">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    {Dictionary.fullName}
                                                </label>
                                                <input type="text" className="form-input" />
                                            </div>

                                        </div>
                                        <div className="col-small-12">
                                            <div className="form-group required">
                                                <label className="form-label">
                                                    {Dictionary.email}
                                                </label>
                                                <input type="email" className="form-input placeholder-right en-input-text" />
                                            </div>

                                        </div>
                                        <div className="col-small-12">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    {Dictionary.subject}
                                                </label>
                                                <input type="text" className="form-input" />
                                            </div>

                                        </div>
                                        <div className="col-small-12">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    {Dictionary.messageText}
                                                </label>
                                                <textarea className="form-textarea"></textarea>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="clearfix">
                                        <button type="button" className="button red-btn pull-end">{Dictionary.send}</button>
                                    </div>
                                </form>
                                    
                            </div>
                        </div>

                    </div>                                       
                </div>
            </div>
        )
    }

}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(ContactUS)